import { Component, OnInit, ViewEncapsulation } from "@angular/core";
// import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
// import { Member } from 'src/app/shared';
import { UserService } from "src/app/shared/services/user.service";
import { s3Url } from "src/app/shared";
// import { VodService } from 'src/app/shared/services/vod.service';
// import { SubscriptionService } from 'src/app/shared/services/subscription.service';
// import { AuthService } from 'angularx-social-login';
declare var window: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  public pushRightClass: string;
  public isLogin: boolean;
  public name: string;
  public role: string;
  public profile: number;
  photo;

  constructor(
    // private translate: TranslateService,
    public router: Router,
    public route: ActivatedRoute,
    private userService: UserService
  ) // private vodService: VodService,
  // private subscriptionService: SubscriptionService,
  // private member: Member,
  // private authService: AuthService
  {
    if (localStorage.getItem("token")) {
      this.isLogin = true;
      this.role = JSON.parse(localStorage.getItem("user"))?.role;
    }

    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd && window.innerWidth <= 992 && this.isToggled()) {
        this.toggleSidebar();
      }
    });
  }

  ngOnInit() {
    this.pushRightClass = "push-right";
    this.photo = JSON.parse(localStorage.getItem("profile"))?.photo;
    window.addEventListener('scroll', this.onScroll);
  }

  onScroll() {
    const header = document.querySelector('.kr_header') as HTMLElement;

    if (window.scrollY > 0) {
      header.style.backgroundColor = 'rgba(255, 255, 255, 1)';
      header.style.transform = 'translateY(0px)';
    } else {
      header.style.backgroundColor = 'rgba(255, 255, 255, 0.4';
      header.style.transform = 'translateY(15px)';
    }
  }

  routeTo(to) {
    this.router.navigate([to], { relativeTo: this.route });
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector("body");
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector("body");
    dom.classList.toggle(this.pushRightClass);
  }

  rltAndLtr() {
    const dom: any = document.querySelector("body");
    dom.classList.toggle("rtl");
  }

  logoutUser() {
    // console.log(localStorage.getItem('email'));
    // window.google.accounts.id.revoke('1618033988749895', done => {
    //   console.log(done.error);
    // });

    this.userService.signout().subscribe({
      error: () => {
        localStorage.clear();

        this.router.navigate(["kr/home"]).then(() => {
          location.reload();
        });
      },
      complete: () => {
        localStorage.clear();

        this.router.navigate(["kr/home"]).then(() => {
          location.reload();
        });
      }
    });
  }
  // socialLogoutUser() {
  //   this.authService.signOut();
  // }

  tricon = "../../../assets/images/kr.png";

  changeLang(language: string) {
    // this.translate.use(language);
    if (language === "en") {
      this.tricon = "../../../assets/images/us.png";
      this.router.navigate(["en/home"]).then(() => {
        location.reload();
      });
    } else if (language === "kr") {
      this.tricon = "../../../assets/images/kr.png";
      // this.router.navigate(['kr/home']).then(()=>{
      // window.location.reload();
      // });
    }
  }
}

$(document).ready(function () {
  //side menu close event
  $(".kr_header .close_btn_kr, .kr_header .sidenav-bg, .kr_header .list-group-item").click(function () {
    $(window).scrollTop(0);
    $(".kr_header .sidenav-bg").removeClass("side_tog_bg");
    $(".kr_header .sidenav").removeClass("side_tog");
    $(".kr_header .t_bar").removeClass("t_bar_toggle");
    $(".kr_header .m_bar").removeClass("m_bar_toggle");
    $(".kr_header .b_bar").removeClass("b_bar_toggle");
  });

  //side menu toggle button
  $(".kr_header .side_menu_toggle").click(function () {
    $(".kr_header .sidenav-bg").toggleClass("side_tog_bg");
    $(".kr_header .sidenav").toggleClass("side_tog");
    $(".kr_header .t_bar").toggleClass("t_bar_toggle");
    $(".kr_header .m_bar").toggleClass("m_bar_toggle");
    $(".kr_header .b_bar").toggleClass("b_bar_toggle");
  });
 
}); //end
