<div class="body_bg"></div>
<div class="wrap">
  <app-tos-header></app-tos-header>

  <div class="txt_box">
    <h4>등급 권한</h4>
    <p>일반회원 : 홈 트레이닝 또는 개인으로 사이클링을 즐기는 회원을 뜻합니다.</p>
    <p>비즈회원 : 피트니스클럽. 스포츠센터. 단체 등에서 운영되는 사업자 회원을 뜻합니다.</p>
    <p>교육생 : T사이클링 자격취득 후 센터수업 출강을 희망하는 예비교육생 및 교육을 완료하여 필드 수업을 진행하는 강사진을 뜻합니다.</p>
    <br />

    <h4>이용권 주의사항</h4>
    <p>1. 콘텐츠는 제공사의 사정에 따라 수시 변경, 정정, 삭제, 지원되지 않을 수 있습니다.</p>
    <p>2. 다운로드 이용권은 기간 및 회수를 이월하지 않습니다.</p>
    <p>3. 제휴 이용권은 할인 혜택이 적용되어 있으므로 추가 혜택(페이백, 기타 할인 등)이 적용되지 않습니다.</p>
    <br />

    <h4>서비스 이용 주의사항</h4>
    <p>1. T-BOX MEDIA 서비스는저작권 문제로 해외 서비스 이용 시 이용금액이 상이할 수 있습니다.</p>
    <p>2. 동시접속 상품은 한 계정당 구매하신 회선만큼 동시에 여러 기기에서 시청하실 수 있는 상품입니다.</p>
    <p>3. 일부 콘텐츠는 저작권자의 요청에 따라 LIVE 및 Vod 서비스가 제공되지 않을 수 있습니다.</p>
    <p>4. 일부 콘텐츠는 콘텐츠 제공사의 사정에 따라 FHD화질이 제공되지 않을 수 있습니다.</p>
    <p>5. 콘텐츠는 회원권별로 제공사항이 제한될 수 있습니다.</p>
    <br />

    <h4>이용권 구매 주의사항</h4>
    <p>1. 모든 이용권은 부가세(VAT)포함 가격입니다.</p>
    <p>2. 할인가격은 프로모션 종료 후 변경 될 수 있습니다.</p>
    <p>3. 자동결제는 1개월 단위로 이루어지므로 구매하신 날과 매월 동일한 날짜에 결제가 이루어지며 모든 T-BOX MEDIA 상품은 선불결제 상품으로 구매 및 사용하신 이용권에 대해서는 결제취소 및 환불되지 않습니다.</p>
  </div>
</div>
