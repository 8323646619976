<div class="body_bg"></div>
<div class="wrap">
  
  <app-tos-header></app-tos-header>

  <div class="txt_box">
    <p><b>1. “개인정보” 수집 및 이용 목적</b></p>
    회원의 개인정보는 서비스 이용계약의 성립(본인 식별 및 본인 의사 확인 등), 서비스의 이행(상품배송 및 대금결제), 기타 새로운 서비스 및 신상품이나 이벤트에 대한 정보 안내(제공)의 목적으로 수집되어 이용됩니다.
    또한, 회사는 회원에게 편리하고 다양한 서비스를 제공하기 위하여 회원으로부터 수집한 개인정보를 이용하여 회사가 제공하는 각종 서비스를 전자우편, TM(텔레마케팅), DM(우편), SMS(핸드폰 문자메시지) 등의 방법으로
    광고 또는 마케팅 활동을 수행할 수 있습니다. 이 경우 회원은 언제든지 전자우편 등을 통해 원치 않는 서비스를 거절할 수 있습니다.<br />

    <p><b>2. 수집하는 개인정보 수집 항목</b></p>
    회사는 홈페이지를 통한 회원 가입, 상담게시판, 전화, FAX, 우편을 통해 고객이 제시하는 개인정보수집을 할 수 있습니다. 회사가 수집하는 개인정보는 서비스 제공에 필요한 최소한으로 하되, 부가정보가 필요한
    경우에는 부가정보를 요구할 수 있습니다. 회사는 회원가입 화면에서 다음과 같은 개인정보항목을 회원으로부터 제공받고 있습니다.<br />

    ① 일반회원 가입 시 수집항목<br />

    필수정보 이름(실명), 아이핀CI, 성별, 생년월일, 휴대폰번호(휴대폰 인증 시), 비밀번호<br />

    선택정보 이메일주소, 이메일/SMS마케팅 수신동의<br />

    주민등록번호 처리기준 회사는 실명확인을 통한 회원가입과 관련하여 회원의 주민등록번호를 저장하지 않습니다. 주민등록번호는 신용평가기관을 통한 실명확인용으로만 이용되며 이에 동의하지 않으실 경우, 아이핀
    인증을 통해 회원가입을 하실 수 있습니다.<br />

    ② 만 14세 미만 어린이 회원 가입 시 수집항목<br />

    필수정보 이름(실명), 아이핀CI, 성별, 생년월일, 휴대폰번호(휴대폰 인증 시), 비밀번호, 법정대리인 정보<br />

    선택정보 이메일주소, 이메일/SMS마케팅 수신동의<br />

    주민등록번호 처리기준 회사는 실명확인을 통한 회원가입과 관련하여 회원의 주민등록번호를 저장하지 않습니다. 주민등록번호는 신용평가기관을 통한 실명확인용으로만 이용되며 이에 동의하지 않으실 경우, 아이핀
    인증을 통해 회원가입을 하실 수 있습니다. 만 14세 미만 아동의 개인정보를 수집할 경우, 회사는 정보통신망법 제31조(법정대리인의 권리)에 따라 법정대리인(부모 등 아동의 보호자)의 동의를 받아야 합니다.
    법정대리인은 14세 미만 아동 회원의 개인정보를 조회하거나 수정할 권리, 수집 및 이용 동의를 철회할 권리를 가집니다.<br />

    ③ 간편회원 가입 시 수집항목<br />

    필수정보 이름, 성별, 생년월일, 비밀번호<br />

    선택정보 휴대폰번호, 이메일주소, 이메일/SMS마케팅 수신동의<br />

    ④ 이 외에 회사가 서비스 이용과정이나 사업처리 과정에서의 수집할 수 있는 추가 정보<br />

    추가수집 정보 발/수신번호, 통화시각, 사용도수, 서비스 이용시간 및 이용기록, 접속로그, 쿠키, 접속 IP정보, 결제정보, 사용자 기기정보, 이용정지 기록 등: 고객상담, 이용요금 정산 및 개인맞춤 서비스 제공<br />

    <p><b>3. 개인정보의 보유 및 이용기간</b></p>

    회원의 개인정보는 회사가 서비스를 제공하는 기간(이용기간) 또는 분쟁처리 기간(보유기간) 동안에 한하여 보유하고 이를 활용하며 개인정보의 수집목적 또는 제공받은 목적을 달성한 이후에는 지체없이 재생불가능한
    기술적인 방법을 사용하여 개인정보를 파기하고 있습니다.<br />

    이용기간 서비스 가입일 ~ 해지일 까지<br />

    보유기간 불량회원의 부정한 이용의 재발 방지 및 서비스 혼선 방지를 위해 이용계약 해지일로부터 3개월간 보유<br />

    단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.<br />

    ① 회사 내부 방침에 의한 정보보유 사유<br />

    구분 보존이유 보존기간<br />

    부정이용기록 부정 이용 방지 1년<br />

    ② 관련법령에 의한 정보보유 사유<br />

    상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의
    목적으로만 이용하며 보존기간은 아래와 같습니다.<br />

    구분 보존이유 보존기간<br />

    서비스 이용기록 통신비밀보호법 3개월<br />

    소비자의 불만 또는 분쟁처리에 관한 기록 전자상거래 등에서의 소비자보호에 관한 법률 3년<br />

    계약 또는 청약철회 등에 관한 기록 전자상거래 등에서의 소비자보호에 관한 법률 5년<br />

    대금결제 및 재화 등의 공급에 관한 기록 전자상거래 등에서의 소비자보호에 관한 법률 5년<br />

    <p><b>4. 개인정보의 파기절차 및 방법</b></p>

    고객님이 서비스 가입 등을 위해 제공하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져 내부 방침 및 기타 관련 법령에 의한 보유 사유에 따라 일정기간 저장된 후, 아래 다항에 명시한 방법으로 파기됩니다. 별도 DB에
    옮겨진 개인정보는 법률에 의해 보유되는 경우가 아니고서는 이외의 다른 목적으로 이용되지 않습니다. 이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에 파기되며,
    개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.<br />

    파기대상 보유기간 및 관련법령에 따른 보존기간이 종료된 고객정보<br />

    파기절차 1) 탈퇴 및 휴면계정 개인정보 분리<br />

    2) 별도 DB에 분리저장 후 3개월간 보관<br />

    3) 개인정보 보유기간 종료 후 종료일로부터 5일 이내 개인정보 완전 파기<br />

    파기방법<br />

    1) 서면에 작성, 출력된 개인정보: 분쇄하거나 소각<br />

    2) DB 등 전자적 파일 형태로 저장된 개인정보: 기록을 재생할 수 없는 기술적 방법 으로 삭제<br />

    기타, 휴면계정의 개인정보 파기 정보통신망 이용촉진 및 정보보호 등에 관한 법률 시행령 제29조 및 동법시행령 제16조 제2항에 의거하여 회원 가입 후 1년 동안 서비스를 이용하지 않은 휴면계정은 탈퇴처리하고
    이용자의 개인정보는 파기 또는 분리 저장 할 수 있습니다.<br />

    휴면계정의 개인정보 분리 저장 및 파기는 상기에 명시한 절차와 방법에 따라 진행됩니다.<br />

    회사는 휴면계정 이용자에게 유효기간이 도래하기 30일전까지는 이용자에게 통지하여야 하고 통지내용은 아래와 같습니다.<br />

    - 통지내용 : 개인정보의 파기 또는 분리 저장 관리되는 사실, 파기 일시, 파기되는 개인정보 항목<br />

    - 통지 방법 : 이메일, SMS, 전화, 팩스 등<br />

    <p><b>5. 취급 위탁 안내</b></p>

    회사는 서비스 이행을 위해 아래와 같이 외부 전문업체에 위탁하여 운영하고 있습니다.<br />

    수탁업체 위탁업무 내용<br />

    KG올앳 주식회사 요금결제, 결제 시스템 개발 등<br />

    굿니스 시니어콘텐츠 개발.공유협력<br />

    쿠키건강 TV 콘텐츠 개발.공유협력<br />

    <p><b>6. 이용자 및 법정대리인의 권리와 그 행사방법</b></p>

    ① 이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수 있습니다.<br />

    ② 이용자들의 개인정보 조회/수정 또는 가입 해지(동의철회)를 위해서는 정보수정메뉴를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.<br />

    ③ 혹은 고객센터에게 전화 또는 이메일로 연락하시면 조치하겠습니다.<br />

    ④ 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당사 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게
    지체없이 통지하여 정정이 이루어지도록 하겠습니다.<br />

    ⑤ 회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보는 "회사가 수집하는 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.<br />

    <p><b>7. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</b></p>

    회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트가 이용자의 컴퓨터 웹브라우저로 전송하는 소량의 정보로 PC
    하드디스크에 저장되기도 합니다. 이용자가 웹사이트에 접속을 하면 회사의 서버는 이용자의 웹브라우저에 있는 쿠키의 내용을 읽고 이용자의 추가정보를 이용자의 컴퓨터 상에서 찾아 접속에 따른 성명 등의 추가 입력
    없이 서비스를 제공할 수 있습니다.<br />

    ① 쿠키의 사용 목적<br />

    쿠키는 이용자의 컴퓨터를 식별하지만 이용자 개개인을 개별적으로 식별하지는 않습니다. 회사는 이 쿠키를 이용하여 이용자들이 방문한 각 서비스에 대한 방문 및 이용 형태, 이용자 규모 등을 파악하여 더욱 더 편리한
    서비스를 만들 수 있습니다. 이용자들은 웹브라우저의 옵션을 조정하여 쿠키에 대한 사용 여부를 선택하실 수 있습니다. 즉, 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를
    거부하실 수도 있습니다. 다만, 이용자들은 회사에 접속한 후 로그인(LOG-IN)하여 서비스를 이용하기 위해서는 쿠키를 허용하여야 합니다. 참고로, 회사에 링크 되어 있는 웹사이트들이 개인정보를 수집하는 행위에
    대해서는 회사는 개인정보 보호의무를 지지 않습니다.<br />

    ② 쿠키의 설치/운영 및 거부<br />

    - 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도
    있습니다.<br />

    - 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.<br />

    - 설정방법 예 (인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보<br />

    - 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 회사의 일부 서비스를 이용하는데 어려움이 있을 수 있습니다.<br />

    <p><b>8. 개인정보 보호를 위한 기술 및 관리적 대책</b></p>

    회사는 개인정보 보호를 위해 기술적인 측면과 관리적인 측면에서 다음과 같은 조치를 취하고 있습니다.<br />

    ① 개인정보 취급 직원의 최소화 및 교육<br />

    개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.<br />

    ② 내부관리계획의 수립 및 시행<br />

    개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.<br />

    ③ 해킹 등에 대비한 기술적 대책<br />

    해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고
    있습니다.<br />

    ④ 개인정보의 암호화<br />

    이용자의 개인정보는 비밀번호는 암호화(encryption) 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을
    사용하고 있습니다.<br />

    ⑤ 접속기록의 보관 및 위변조 방지<br />

    개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.<br />

    ⑥ 개인정보에 대한 접근 제한<br />

    개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고
    있습니다.<br />

    개인정보 보호에 대한 정기적인 사내 정신교육 실시 및 회원 계정에 대한 보안 유지는 개인정보의 보호를 위해 매우 중요한 사항이며 회원계정 보안유지 책임은 해당 회원 본인에게 있습니다. 회사에서는 어떠한 경우에도
    회원에게 직접적으로 비밀번호를 질문하지는 않기 때문에 회원 여러분은 타인에게 비밀번호가 유출되지 않도록 각별히 유의하셔야 합니다.<br />

    <p><b>9. 목적 외 사용 및 제3자에 대한 제공(또는 공유)</b></p>

    회사는 이용자 또는 회원이 제공한 개인정보를 이용자 또는 회원의 동의 없이 외부에 공개하거나 제3자와 공유하지 않습니다. 다만, 다음 각 호의 하나에 해당되는 경우 또는 법률의 특별한 규정 등 개인정보 보호법
    제17조 및 18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.<br />

    ① 이용자 또는 회원에게 제3자 제공 및 취급업무의 위탁 등에 대한 동의를 얻은 경우<br />

    ② 이용자 또는 회원이 서비스 내 전자게시판 등에 남긴 TV, DTV(데이터방송), 인터넷방송 프로그램에 대한 시청자 의견, 신청곡, 사연 또는 설문조사 참여결과 등을 해당 방송 프로그램 내용에 직접 반영하거나 서비스
    이용시 본인확인을 위해 관련 프로그램의 제작진 및 진행자에게 이용자 또는 회원이 제공한 개인 정보 중 이름, 성별, 생년월일, 이메일, 아이디(ID), 연락처 등 원활한 방송 프로그램 진행에 필요한 제한된 범위의
    개인정보를 제공하는 경우<br />

    ③ 회원이 회사 및 제휴사의 서비스를 별도의 회원가입 절차 없이 회사가 부여한 하나의 회원ID로 이용할 수 있도록 하기 위하여, 특정 제휴사의 서비스를 이용하고자 하는 회원에 대하여 회사가 보유한 해당 회원의
    개인정보를 관계된 제휴사에 제공할 수 있습니다(회원의 최초 이용신청 후 제휴사가 늘어난 경우에도 동일하게 적용합니다). 이 경우, 회원은 언제든지 전자우편 등을 통해 해당 제휴사에 대한 개인정보 제공을 거절하고
    관련된 서비스 이용을 중단할 수 있습니다.<br />

    ④ 회사의 서비스이용약관이나 유료서비스이용약관, 기타 회원제 서비스 등의 이용약관을 위반한 경우<br />

    ⑤ 서비스를 이용하여 타인에게 정신적, 물질적 피해를 줌으로써 그에 대한 법적인 조치를 취하기 위하여 개인정보를 공개해야 한다고 판단되는 충분한 근거가 있는 경우<br />

    ⑥ 기타 법에 의해 요구된다고 선의로 판단되는 경우<br />

    ⑦ 서비스 제공에 따른 요금 정산 및 상품 배송을 위하여 필요한 경우<br />

    ⑧ 통계작성, 학술연구 또는 시장조사를 위하여 개인을 식별할 수 없는 형태로 제공하는 경우<br />

    <p><b>10. 개인정보 관리 책임자 및 담당자</b></p>

    회사는 개인정보 관리 책임자 및 담당자를 정하여 회원의 개인정보를 보호하고 있습니다.<br />

    [개인정보보호관리 책임자]<br />

    성 명 : 서남현<br />

    소 속 : 경영지원<br />

    전화번호 : 070-8808-8838<br />

    이메일 : tboxtop&#64;tbox.media<br />

    [개인정보보호관리 담당자]<br />

    성 명 : 서남현<br />

    소 속 : 경영지원<br />

    전화번호 : 070-8808-8838<br />

    이메일 : tboxtop&#64;tbox.media<br />

    <p><b>11. 개인정보 관련 불만사항 및 분쟁의 처리</b></p>

    개인정보 침해를 이유로 피해구제를 받기 원하는 이용자께서는 회사개인정보 관리 책임자 또는 담당자에게 연락해 주십시오. 또는 아래의 기관에서 개인정보 침해에 대한 신고 또는 상담, 피해구제, 분쟁조정 등을
    문의하실 수 있습니다.<br />

    [개인정보 침해신고센터 및 개인정보 분쟁조정위원회(한국인터넷진흥원 운영)]<br />

    홈페이지 : privacy.kisa.or.kr<br />

    전화번호 : (국번없이) 118<br />

    [대검찰청 사이버범죄수사단]<br />

    홈페이지 : www.spo.go.kr<br />

    전화번호 : 02-3480-2000<br />

    [경찰청 사이버안전국]<br />

    홈페이지 : www.ctrc.go.kr<br />

    전화번호 : (국번없이) 182<br /><br />

    부칙<br /><br />

    (시행일) 이 약관은 2019년 7월 1일부터 시행합니다.<br />
  </div>
</div>
