<div class="wrap">
  <!--로그인-->
  <form class="example-form">
    <div class="login-signup-header">
      <h2 class="login-signup-title">일반회원 로그인 페이지</h2>
      <div class="login-signup-divider">

      </div>
      <h2 class="login-signup-greeting">지금 바로 T-BOX의 다양한 혜택을 누려보세요</h2>
    </div>

    <mat-form-field class="example-full-width form-field">
    <mat-label>아이디</mat-label>
    <input matInput type="text" id="user" [(ngModel)]="loginInfo.user" name="user" />
</mat-form-field>

<mat-form-field class="example-full-width form-field">
  <mat-label>비밀번호</mat-label>
  <div class="input-group">
    <input [type]="this.passwordVisible ? 'text' : 'password'" matInput type="password" id="password" [(ngModel)]="loginInfo.password" name="password" />
    <div class="input-group-append">
      <button type="button" class="btn btn-outline-secondary" (click)="togglePasswordVisibility()" tabindex="-1">
        <svg *ngIf="!passwordVisible" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM8 13c-3.866 0-7-4-7-5s3.134-5 7-5 7 4 7 5-3.134 5-7 5z"/>
          <path d="M8 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"/>
          <path d="M8 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"/>
        </svg>
        <svg *ngIf="passwordVisible" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
          <path d="M13.359 11.238C12.227 12.196 10.665 13 8 13c-3.866 0-7-4-7-5 0-.636.27-1.453.8-2.297"/>
          <path d="M13.646 12.354a.5.5 0 0 0 .708-.708l-12-12a.5.5 0 0 0-.708.708l2.312 2.312"/>
        </svg>
      </button>
    </div>
  </div>  
  </mat-form-field>

<mat-error class="error" *ngIf="submitted && errorExists">
    아이디와 비밀번호를 확인해 주세요.
</mat-error>

    <ng-container *ngIf="isAccountDeactivated">
      <app-reactivate-account (reactivate)="handleReactivate()"></app-reactivate-account>
    </ng-container>
    <ng-container *ngIf="isAccountDeleted"> <app-account-deleted></app-account-deleted> </ng-container><br />
    <button class="btn_log" mat-flat-button (click)="loginUser()"><!--{{ 'Confirm' | translate }}-->
      <img src="../../../../assets/icons/Icon_64px_col-86.png" class="btn-icon" alt="icon">
      로그인</button>
    <app-login-options [l_state]="l_state" (l_state_changed)="changeStateHandler($event)"></app-login-options>
    <hr />
    
    <!-- <app-login-social></app-login-social> -->
    <!-- <div class="g-signin2" data-onsuccess="onSignin" (click)="signin()"></div> -->
    <!-- <div id="buttonDiv" ></div>
    <ul>
      <li (click)="kakaoLogin()">
          <a href="javascript:void(0)">
              <span>카카오 로그인</span>
          </a>
      </li>
      <li (click)="kakaoLogout()">
          <a href="javascript:void(0)">
              <span>카카오 로그아웃</span>
          </a>
      </li>
    </ul> -->
  </form>
  <br />
</div>
<br /><br />
