<div class="top-banner">
  
</div>
  <div class="wrap">
    <div class="tab_tit" [ngClass]="{'tab_tit-hidden': this.open}">
      <h3 [hidden]="this.open" class="content-type">{{this.isTboxVod ? 'T-BOX' : 'T-CYCLING '}} > </h3>
      <h3 [hidden]="this.open" class="category-name">{{ selectedCategoryName }}</h3>
    </div>

    <mat-tab-group mat-align-tabs="center" #tabs (selectedTabChange)="changeSelectedCategory($event)" dynamicHeight animationDuration="0ms">
      <mat-tab *ngFor="let vodCategory of vodCategoryList" label="{{ vodCategory.name }}"></mat-tab>
    </mat-tab-group>

    <div [hidden]="!this.searchResultsNotFound" class="search-not-found">
      <h2>검색어와 일치하는 영상이 없습니다.</h2>
    </div>

    <div *ngIf=this.open class="container">
      <div class="row">
        <!--VOD view-->
        <ng-template [ngIf]="this.open">
          <div class="col-lg-8" style="padding: 0">
            <div class="view view_vod">
              <div class="view_box">
                <!--loading spinner-->
                <div [hidden]="!loading" class="wrap_spin" [ngStyle]="{'background-image': this.isTboxVod ? 'url(../../../../assets/images/logo_bg.jpg)' : 'url(../../../../assets/images/logo_bg2.jpg)'}">
                  <mat-spinner diameter="50" class="custom-spinner" style="margin: 0 auto; position: absolute; bottom: 10%; left: calc(50% - 25px)"></mat-spinner>
                </div>
                <div [hidden]="loading" class="video-container">
                  <!-- main Vimeo player embed -->
                  <div #myVideo class="main-video"></div>
                </div>
              </div>
              <div class="btn_box">
                <button *ngIf="!continue" mat-flat-button (click)="continue = true">연속재생</button>
                <button *ngIf="continue" mat-flat-button (click)="continue = false">연속재생 해제</button>
              </div>
            </div>
          </div>

          <!--VOD info-->
          <div class="col-lg-4">
            <div class="view view_txt" *ngIf="!loading">
              <div class="header_txt">
                <h3>{{ this.selectedVod.title }}</h3>
              </div>
              <div class="gangsa">
                <div class="logo_m"><img src="../../../../assets/images/logo_m.png"></div>
                <p *ngIf="selectedVod.gangsa !== ''" class="name_txt">{{ this.selectedVod.gangsa }} 강사님</p>
              </div>
              <div class="inner-info">
                <div>
                  <img src="../../../../assets/images/clock.svg" />
                  <span *ngIf="selectedVod.vod_min == 0"> -- 분 소요</span>
                  <span *ngIf="selectedVod.vod_min > 0"> {{ this.selectedVod.vod_min }} 분</span>
                </div>
                <div>
                  <img src="../../../../assets/images/star.svg" />
                  <img *ngIf="selectedVod.level == 2 || selectedVod.level == 3" src="../../../../assets/images/star.svg" />
                  <img *ngIf="selectedVod.level == 3" src="../../../../assets/images/star.svg" />
                </div>
                <div [ngClass]="selectedVod.favorite ? 'favorite-btn' : ''" (click)="toggleFavorite(selectedVod.id, selectedVod.favorite)" style="cursor: pointer">
                  <img *ngIf="selectedVod.favorite == false" src="../../../../assets/images/empty-favorite2.svg" />
                  <img *ngIf="selectedVod.favorite == true" src="../../../../assets/images/favorite.svg" />
                  즐겨찾기
                </div>
              </div>
              <div class="playlist_txt">
                <p>{{ this.selectedVod.content }}</p>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div *ngIf="loading == true" style="width: 100%">
          <mat-spinner diameter="50" style="margin: 30% auto" class="custom-spinner"></mat-spinner>
        </div>
        <div *ngIf="loading == false" class="thumbnail_wrap">
          <div class="col-xs-4 col-sm-4 col-md-4 thumbnail_box" *ngFor="let vod of vodListCategory?.vod_list | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize; let i = index">
            <div (click)="changeSelectedContent(vod)"
                (mouseover)="playVideo(i)"
                (mouseout)="pauseVideo(i)">
              <div class="img_wrap video-container">
                <!-- thumbnail videos -->
                <div #themVod></div> 
              <img src="{{ vod.thumbnail }}" alt="thumbnail" title="{{ vod.title }}" /> 
            </div>
              <div class="thumbnail_txt">{{ vod.title }}</div>
            </div>
          </div>
        </div>
      </div>
        <div class="pagination-search-container">
          <div class="pagination-pane">
            <ngb-pagination
              [collectionSize]="vodListCategory?.vod_list.length"
              [(page)]="page"
              [pageSize]="pageSize"
              [maxSize]="3"
              [rotate]="true"
              [ellipses]="true"
              [boundaryLinks]="false"
              class="d-flex justify-content-end"
              size="lg"
            >
              <ng-template ngbPaginationPrevious>Prev</ng-template>
              <ng-template ngbPaginationNext>Next</ng-template>
            </ngb-pagination>
          </div>
          <div class="search-pane">
              <div class="search-bottom-border">
                <input type="text" 
                  class="search-input" 
                  #searchInput 
                  placeholder="검색어를 입력해주세요." 
                  (input)="onInputChange()" 
                  (keyup.enter)="this.searchVod(searchInput.value)"
                  (keyup.esc)="this.resetVodList()"
                  >
                <button class="reset-button" #resetBtn (click)="this.resetVodList()" style="visibility: hidden">
                  <p>X</p>
                </button>
              </div>
              <button class="search-button" #searchBtn (click)="this.searchVod(searchInput.value)">
                <i class="bi bi-search"></i>
              </button>
          </div>
      </div>
    </div>
  </div>
<div class="body_bg"></div>
