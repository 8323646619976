<div class="wrap">
  <form class="example-form">
    <!--아이디 찾기-->
    <img class="logo" src="../../../assets/images/logo.png"/>
    <!-- <h2>{{ 'Forgot ID' | translate }}</h2> -->
    <h2>아이디 찾기</h2>
    <p class="exp"><!--{{ 'Log In Text 3' | translate }}-->회원가입 시 입력하신<br> <!--{{ 'Log In Text 4' | translate }}-->이름/이메일주소를 입력해주세요.</p>
    <mat-form-field class="example-full-width">
      <mat-label>이름</mat-label>
      <input matInput type="text" 
      [(ngModel)]="userInfo.fullname" name="fullname" >
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-label>이메일</mat-label>
      <input matInput type="email" 
      [(ngModel)]="userInfo.email" name="email" >
    </mat-form-field>
    <!-- <mat-error *ngIf="submitted && errorExists">{{ 'ERROR: Please check again' | translate }}</mat-error> -->
  
    <button class="btn_log btn_log_find_reset" mat-raised-button 
    [disabled]="sent" (click)="send()">
      <div *ngIf="sent===false"><!--{{ 'Send Email' | translate }}-->이메일 전송</div>  
      <div *ngIf="sent===true"><!--{{ 'Email sent' | translate }}-->이메일 전송</div>  
    </button>
    <app-login-options [l_state]="l_state" (l_state_changed)="changeStateHandler($event)"></app-login-options>
  </form>
</div>
