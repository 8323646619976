<div class="wrap-community-viewer">
  <div style="display: flex; align-items: center">
    <button mat-icon-button (click)="closeViewer()" style="color: #373737; line-height: 0; width: auto; height: auto">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <h4 style="color: #373737; margin: 0">{{ title }}</h4>
  </div>
  <hr style="background-color: #ffffff" />
  <ng-container *ngIf="body === ''">
    <mat-spinner diameter="50" class="custom-spinner" style="margin: 0 auto; position: absolute; top: calc(50% - 25px); left: calc(50% - 25px)"></mat-spinner>
  </ng-container>
  <ng-container *ngIf="body !== ''">
    <div [innerHTML]="body"></div>
    <button mat-raised-button color="accent" style="display: block; margin: 30px auto" (click)="closeViewer()">
      목록으로
      <!-- {{ 'Close' | translate }} -->
    </button>
  </ng-container>
</div>
