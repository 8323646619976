import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { s3Url } from 'src/app/shared';
import { UserService } from 'src/app/shared/services/user.service';
// declare var window: any;
enum l_state {
  REGULAR = 1,
  FIND_ID,
  RESET_PW,
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  l_state;
  constructor(
    public router: Router,
  ){
    this.l_state = 1;
  }
  
  ngOnInit() {}
  
  changeStateHandler(toState) {
    this.l_state = toState;
  }

}

@Component({
  selector: 'app-login-regular',
  templateUrl: './login-regular.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginRegularComponent {
  @Input() l_state: number;
  @Output() l_state_changed: EventEmitter<number> =   new EventEmitter();

  loginInfo;
  errorExists;
  submitted;

  isAccountDeactivated = false;
  isAccountDeleted = false;
  tempData;
  
  userAuthToken;
  userDisplayName;

  // password visibility controls
  passwordVisible: boolean = false; // password visibility toggle
  visibilityTimeout: any; // password visibility timeout

  constructor(
    public router: Router,
    private userService: UserService,
  ) { 
    this.loginInfo = {
      user: '',
      password: ''
    };
  }

  ngOnInit(){
    // window.google.accounts.id.initialize({
    //   client_id: "797947276504-70q69b4022n1agn35uiaipvc5g92p6lk.apps.googleusercontent.com",
    //   callback: this.handleCredentialResponse
    // });
    // window.google.accounts.id.renderButton(
    //   document.getElementById("buttonDiv"),
    //   { type:"standard", size: "medium", text:"continue_with", shape:"circle" }
    // );
    // // window.google.accounts.id.prompt();
    // window.Kakao.init('4439722a2e6c4177cb34e696228ba8c1');
    // console.log(window.Kakao.isInitialized()); // sdk초기화여부판단
  }
  // handleCredentialResponse(response) {
  //   // console.log("Encoded JWT ID token: " + response.credential);
  //   const token = response.credential;
  //   this.v1WebService.googleLoginToken(token).subscribe(
  //     data => {
  //       // console.log(data);
  //     },error => {
  //       console.log(error);
  //     }
  //   );
  // }

  loginUser = () => {
    // Check failed attempt lock
    if(localStorage.getItem('loginLocked'+this.loginInfo.user)){
      let loginLockedTime = parseInt(localStorage.getItem('loginLocked'+this.loginInfo.user));
      if( loginLockedTime ){
        if( Date.now() > loginLockedTime + (1000 * 60 * 5) ) {
          localStorage.removeItem('loginLocked'+this.loginInfo.user);
        } else {
          alert('계정이 일시적으로 잠겼습니다');
          return;
        }
      }
    }

    this.submitted = true;
    this.userService.signin(this.loginInfo).subscribe({
        next: (data) => {
          localStorage.removeItem('loginFailed'+this.loginInfo.user);
          localStorage.removeItem('loginLocked'+this.loginInfo.user);
          
          localStorage.setItem('user', JSON.stringify(data.user));
          localStorage.setItem('token', JSON.stringify(data.token));

          const profileInfo = data.profile;
          profileInfo.photo = s3Url + profileInfo.photo;
          localStorage.setItem('profile', JSON.stringify(profileInfo));

          localStorage.setItem('address', JSON.stringify(data.address));
          localStorage.setItem('subscription', data.subscription);
        },
        error: (error) => {
          this.errorExists = true;
          
          if (error.status == 404) {
            // Check last failed attempt
            if(localStorage.getItem('loginAttemptTime'+this.loginInfo.user)){
              let loginAttemptTime = parseInt(localStorage.getItem('loginAttemptTime'+this.loginInfo.user))
              if( Date.now() > loginAttemptTime + (1000 * 60 * 120)){
                localStorage.removeItem('loginAttemptTime'+this.loginInfo.user)
                localStorage.removeItem('loginFailed'+this.loginInfo.user)
              }
            }

            // Add Failed Attempt
            if(localStorage.getItem('loginFailed'+this.loginInfo.user)){
              let numAttempts = parseInt(localStorage.getItem('loginFailed'+this.loginInfo.user));
              if(numAttempts < 4){
                localStorage.setItem('loginFailed'+this.loginInfo.user, (numAttempts + 1) + '')
                localStorage.setItem('loginAttemptTime'+this.loginInfo.user, Date.now().toString() );
              } else if(numAttempts >= 4 ){
                localStorage.setItem('loginLocked'+this.loginInfo.user, Date.now().toString())
                localStorage.removeItem('loginFailed'+this.loginInfo.user);
                alert('5분 동안 로그인하실 수 없습니다');
              } else {
              }
            } else {
              localStorage.setItem('loginFailed'+this.loginInfo.user, '1');
              localStorage.setItem('loginAttemptTime'+this.loginInfo.user, Date.now().toString() );
            }
          }
      },
      complete: () => {
        if(JSON.parse(localStorage.getItem('profile'))?.count > 1){
          this.router.navigate(['kr/multiprofile']).then(()=>{ });
        } else {
          this.router.navigate(['kr/home']).then(()=>{ });
          location.reload();
        }
      }
    });
  }

  redirectToBizLogin() {
    this.router.navigate(['/kr/home']);
  }

  kakaoLogin() {
    this.router.navigate(['/kr/home']);
  }

  naverLogin() {
    this.router.navigate(['/kr/home']);
  }

  googleLogin() {
    this.router.navigate(['/kr/home']);
  }

  onepassLogin() {
    this.router.navigate(['/kr/home']);
  }
  
  changeStateHandler(toState) {
    this.l_state = toState;
    this.l_state_changed.emit(toState);
  }
 
  handleReactivate(){
    if ( confirm(this.tempData.res_msg) ){
      this.userService.activateUser().subscribe(
        data => { 
          this.loginUser();
        }, error => {

        }
      );
    }
  }

  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;

    // clear previous timeout
    if (this.visibilityTimeout) {
      clearTimeout(this.visibilityTimeout);
    }

    // set timeout to obfuscate password after 3 seconds
    if (this.passwordVisible) {
      this.visibilityTimeout = setTimeout(() => {
        this.passwordVisible = false;
      }, 3000); // hide password after 3 seconds
    }
  }
}


@Component({
  selector: 'app-login-remind-id',
  templateUrl: './login-remind-id.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginRemindIDComponent {
  @Input() l_state: number;
  @Output() l_state_changed: EventEmitter<number> =   new EventEmitter();

  userInfo;
  sent = false;

  constructor(
    private userService: UserService,
    public tr: TranslateService
  ){
    this.userInfo = {
      fullname: '',
      email: ''
    }
  }

  send(){
    this.userService.findUsername(this.userInfo).subscribe(
      data => {
        this.sent = true;
        alert("회원님의 아이디가 이메일로 전송 되었습니다");
      }, error => {
        alert("이름과 이메일을 확인해주세요");
      }
    );
  }
 
  changeStateHandler(toState) {
    this.l_state = toState;
    this.l_state_changed.emit(toState);
  }
}

@Component({
  selector: 'app-login-reset-pw',
  templateUrl: './login-reset-pw.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginResetPWComponent {
  @Input() l_state: number;
  @Output() l_state_changed: EventEmitter<number> =   new EventEmitter();
  
  userInfo;
  sent = false;

  constructor(
    private userService: UserService,
    public tr: TranslateService
  ){
    this.userInfo = {
      username: '',
      fullname: '',
      email: ''
    }
  }

  send(){
    this.userService.findPassword(this.userInfo).subscribe(
      data => {
        this.sent = true;
        alert("이메일이 전송되었습니다");
      }, error => {
        if (error.status==404) {
          alert("입력하신 내용을 다시 한 번 확인해 주세요");
        }

        if (error.status=429) {
          alert("요청이 너무 많습니다. 잠시 후 다시 시도해주세요");
        }
      }
    );
  }

  changeStateHandler(toState) {
    this.l_state = toState;
    this.l_state_changed.emit(toState);
  }

}


@Component({
  selector: 'app-login-options',
  templateUrl: './login-options.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginOptionsComponent {
  @Input() l_state: number;
  @Output() l_state_changed: EventEmitter<number> = new EventEmitter();

  constructor(){}

  changeState(toState) {this.l_state_changed.emit(toState);}

}

function redirectToBizLogin() {
  throw new Error('Function not implemented.');
}
