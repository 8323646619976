
<div class="wrap">
  <div class="login-signup-header">
    <h2 class="login-signup-title">개인 회원가입</h2>
    <h3 class="login-signup-greeting">스마트한 홈 피트니스의 시작, T-Box Media와 함께해요.</h3>
  </div>
  <div *ngIf="this.registerStep === 1" class="step-1">
    <div class="tos-wrapper">
      <div class="tos-dropdown-header">
        <div class="tos-dropdown-header-left">
          <img src="../../../../assets/icons/Icon_64px_col-11.png" class="tos-info-icon">
          <h2 class="tos-header-title">사이트 이용약관</h2>
        </div>
        <button class="tos-dropdown-btn" (click)="this.toggleTosDropdown()">
          <svg
          id="tosChevron"
            width="35"
            height="35"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            transform="rotate(0)"
          >
            <path
              d="M6 9l6 6 6-6"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
       <div class="tos-dropdown-hide" *ngIf="this.showTos" @toggleDiv>
        <div class="tos-txt-scroll">
          <h4>제 1 장 총칙</h4>
  
          <br /><b>제 1 조 (목적)</b><br />
          본 약관은 ㈜더좋은운동으로(이하 회사라 합니다)가 제공하는 유무선 인터넷 관련 서비스를 이용함에 있어 회사와 이용자의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.<br />
  
          <br /><b>제 2 조 (정의)</b><br />
          1.T-BOX MEDIA란 회사가 재화 또는 용역을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신 설비를 이용하여 재화 또는 용역을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 서비스를 제공하는 사업자의
          의미로도 사용합니다. <br />
          2. 회사라 함은 콘텐츠 산업과 관련된 경제활동을 영위하는 자로서 콘텐츠 및 제반서비스를 제공하는 자를 말합니다. <br />
          3. 서비스라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 회원이 이용할 수 있는 T-BOX MEDIA관련 제반 서비스를 의미합니다. <br />
          4. 회원이라 함은 회사와 이용계약을 체결하고 이용자 아이디(ID)를 부여받은 이용자로서 회사의 정보를 지속적으로 제공 받으며 회사가 제공하는 서비스를 지속적으로 이용할 수 있는 자를 말합니다. <br />
          5. 콘텐츠라 함은 정보통신망이용촉진 및 정보보호 등에 관한 법률 제2조 제1항 제1호의 규정에 의한 정보통신망에서 사용되는 부호,문자,음성,음향,이미지 또는 영상 등으로 표현된 자료 또는 정보로서, 그 보존 및
          이용에 있어서 효용을 높일 수 있도록 전자적 형태로 제작 또는 처리된 것을 말합니다. <br />
          6. 이용자라 함은 회사의 사이트에 접속하여 이 약관에 따라 회사가 제공하는 콘텐츠 및 제반서비스를 이용하는 회원 및 비회원을 말합니다. <br />
          7. 아이디(ID)라 함은 회원의 식별과 서비스 이용을 위하여 회원이 정하고 회사가 승인하는 문자 또는 숫자의 조합을 말합니다. <br />
          8. 비밀번호(PASSWORD)라 함은 회원이 부여받은 아이디(ID)와 일치되는 회원임을 확인하고 비밀보호를 위해 회원 자신이 정한 문자 또는 숫자의 조합을 말합니다.<br />
          9. 개인정보란 생존하는 개인에 관한 정보로서 해당 정보에 포함되어 있는 성명, 생년월일 등의사항에 의하여 해당 개인을 식별할 수 있는 정보(해당 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게
          결합하여 식별할 수 있는 것을 포함)를 말합니다. <br />
          10. 게시물이란 회원이 서비스를 이용함에 있어 서비스상에 게시한 부호, 문자, 음향, 음성, 화상, 동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다. <br />
          11. 회원탈퇴란 회원이 T-BOX MEDIA 서비스의 이용계약을 해지하는 것을 의미합니다. <br />
          12. 제휴사란 회사와 공동 협력을 통해 회원에게 다양한 서비스와 혜택을 제공하는 제3의 서비스 및 마케팅 협력사를 말합니다.<br />
  
          <br /><b>제 3 조 [신원정보 등의 제공]</b><br />
          회사는 이 약관의 내용, 상호, 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호 및 개인정보관리책임자
          등을 이용자가 쉽게 알 수 있도록 온라인 서비스 초기화면에 게시합니다. 다만, 약관은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다. <br />
  
          <br /><b>제 4 조 (약관의 효력 및 변경)</b><br />
          1. 본 약관은 회사가 서비스 초기 화면을 통하여 게시하거나 전자우편 기타의 방법으로 회원에게 개별 통지함으로써 효력이 발생합니다. <br />
          2. 본 약관에 동의하여 회원가입을 완료한 회원은, 회사가 제공하는 모든 서비스를 별도의 추가 가입절차 없이 편리하게 이용할 수 있습니다. 회사가 운영하는 부속사이트를 이용함에 있어 회원과 회사간의 권리, 의무 및
          책임사항, 이용조건 및 절차 등은 각 서비스 별 약관이나 세부지침의 규정이 없는 사항은 본 약관의 내용을 따릅니다. <br />
          3. 회사는 관련 법령에 반하지 않는 범위 내에서 본 약관의 내용을 변경할 수 있으며, 적용일자 및 개정사유를 명시하여 현행 약관과 함께 그 적용일자 7일 이전부터 적용일자 전일까지 제1항의 방식으로 공지합니다. 다만
          회원에게 불리하게 약관 내용을 변경하는 경우에는 개정 약관의 적용일자 30일 전부터 적용일자 전일까지 공지하는 것 외에 전자우편 기타의 전자적 수단을 통해 별도로 통지합니다. <br />
          4. 전항에 따른 시행일 이후에 서비스를 이용하는 회원은 변경된 약관에 동의한 것으로 간주합니다. 다만 회원은 변경된 약관에 동의하지 않을 경우 이용계약을 해지할 수 있습니다. <br />
          5. 유료서비스를 이용하는 회원이 본 약관의 개정에 동의하지 아니하여 이용계약을 해지하는 경우에는 별도의 유료서비스 약관에 정한 환불정책을 따릅니다. <br />
  
          <br /><b>제 5 조 (약관의 해석 및 준칙)</b><br />
          1. 회사는 개별 서비스에 대하여 별도의 이용약관 및 정책을 둘 수 있으며, 해당 내용이 본 약관과 상충할 경우에는 개별 서비스에 대한 이용약관 및 정책이 우선하여 적용됩니다 <br />
          2. 본 약관에 명시되지 아니한 사항에 대하여는 "전기통신기본법", "전기통신사업법", "정보통신망 이용촉진 및 정보보호 등에 관한 법률"(이하 '정통망법'), 개인정보보호법, 기타 관련 법령에 따릅니다. <br />
  
          <h4>제 2 장 서비스 이용계약</h4>
  
          <br /><b>제 6 조(회원가입)</b><br />
          1. 회원가입은 이용자가 약관의 내용에 대하여 동의를 하고 회원가입신청을 한 후 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.<br />
          2. 회사는 상기 이용자의 신청에 대하여 회원가입을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않을 수 있습니다. <br />
          ①가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우 <br />
          ②실명이 아니거나 타인의 명의를 이용한 경우 <br />
          ③허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우 <br />
          ④이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우 <br />
          3. 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다. <br />
          4. 제2항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, 회사는 이를 신청자에게 알려야 합니다. 회사의 귀책사유 없이 신청자에게 통지할 수 없는 경우에는 예외로 합니다. <br />
          5. 회원가입계약의 성립 시기는 회사의 승낙이 이용자에게 도달한 시점으로 합니다. <br />
  
          <br /><b>제 7 조 [미성년자의 회원가입에 관한 특칙]</b><br />
          1. 만 14세 미만의 이용자는 개인정보의 수집 및 이용목적에 대하여 충분히 숙지하고 부모 등 법정대리인의 동의를 얻은 후에 회원가입을 신청하고 본인의 개인정보를 제공하여야 합니다. <br />
          2. 회사는 부모 등 법정대리인의 동의에 대한 확인절차를 거치지 않은 14세 미만 이용자에 대하여는 가입을 취소 또는 불허합니다.만 14세 미만이용자의 부모 등 법정대리인은 아동에 대한 개인정보의 열람, 정정, 갱신을
          요청하거나 회원가입에 대한 동의를 철회할 수 있으며, 이러한 경우에 회사는 지체 없이 필요한 조치를 취해야 합니다. <br />
  
          <br /><b>제 8 조 [회원정보의 변경]</b><br />
          1. 회원은 개인정보 관리 화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만 서비스의 관리 및 유지를 위하여 필요한 실명, 생년월일,아이디(ID) 등 일부 정보는 임의로 수정할 수 없습니다.
          <br />
          2. 회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 회사에 대하여 그 변경사항을 알려야 합니다.<br />
          3. 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다. <br />
  
          <br /><b>제 9 조 [회원의 아이디(ID) 및 비밀번호의 관리에 대한 의무]</b><br />
          1. 회원의 아이디(ID)와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다. <br />
          2. 회원은 아이디(ID) 및 비밀번호가 도용되거나 제3자에 의해 사용되고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다. <br />
          3. 제2항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다. <br />
  
          <br /><b>제 10 조 (개인정보보호)</b><br />
          1. 회사는 이용자의 콘텐츠이용에 필요한 최소한의 정보를 수집할 수 있습니다. 이를 위해 회사가 문의한 사항에 관해 이용자는 진실한 내용을 성실하게 고지하여야 합니다. <br />
          2. 회사가 이용자의 개인 식별이 가능한 개인정보를 수집하는 때에는 당해 이용자의 동의를 받습니다. <br />
          3. 회사는 이용자가 이용신청 등에서 제공한 정보와 제1항에 의하여 수집한 정보를 당해 이용자의 동의 없이 목적 외로 이용하거나 제3자에게 제공할 수 없으며, 이를 위반한 경우에 모든 책임은 회사가 집니다. 다만,
          다음의 경우에는 예외로 합니다. <br />
          ①통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우 <br />
          ②콘텐츠 제공에 따른 요금정산을 위하여 필요한 경우2 <br />
          ③도용방지를 위하여 본인확인에 필요한 경우 <br />
          ④약관의 규정 또는 법령에 의하여 필요한 불가피한 사유가 있는 경우 <br />
          4. 회사가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는개인정보의 수집목적 및 이용목적, 제3자에 대한 정보제공관련사항(제공받는 자, 제공목적 및 제공할 정보의 내용)등에 관하여 정보통신망이용촉진
          및 정보보호 등에 관한 법률 제22조 제2항이 규정한 사항을 명시하고 고지하여야 합니다. <br />
          5. 이용자는 언제든지 제3항의 동의를 임의로 철회할 수 있습니다. <br />
          6. 이용자는 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류의 정정을 요구할 수 있으며, 회사는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는
          회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다. <br />
          7. 회사는 개인정보보호를 위하여 관리자를 한정하여 그 수를 최소화하며, 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 책임을 집니다. <br />
          8. 회사 또는 그로부터 개인정보를 제공받은 자는 이용자가 동의한 범위 내에서 개인정보를 사용할 수 있으며, 목적이 달성된 경우에는 당해 개인정보를 지체 없이 파기합니다. <br />
          9. 회사는 정보통신망이용촉진 및 정보보호에 관한 법률 등 관계 법령이 정하는 바에 따라 이용자의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법령 및 회사의 개인정보보호정책이
          적용됩니다. <br />
  
          <h4>제 3 장 계약 당사자의 의</h4>
  
          <br /><b>제 11조 (회사의 의무)</b><br />
          1. 회사는 특별한 사정이 없는 한 회원이 서비스 이용을 신청한 날에 서비스를 이용할 수 있도록 합니다. <br />
          2. 회사는 본 약관에서 정한 바에 따라 계속적이고 안정적인 서비스의 제공을 위하여 지속적으로 노력하며, 설비에 장애가 생기거나 멸실된 때에는 지체 없이 이를 수리 복구하여야 합니다. 다만, 천재지변, 비상사태 또는
          그 밖에 부득이한 경우나 회사의 사정상 서비스 제공이 곤란한 경우에는 그 서비스를 일시 중단하거나 중지할 수 있습니다. <br />
          3. 회사는 회원으로부터 소정의 절차에 의해 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 적절한 절차를 거쳐 처리하여야 합니다. 처리시 일정 기간이 소요될 경우 회원에게 그 사유와 처리 일정을 알려주어야
          합니다. <br />
          4. 회사는 회원의 프라이버시 보호와 관련하여 본 약관 및 기타 법령에 정한 개인정보의 보호에 관한 규정들을 준수합니다. <br />
          5. 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의 계약 관련 절차 및 내용 등에 있어 이용자에게 편의를 제공하도록 노력합니다. <br />
          6. 회사는 서비스 내용별로 이용가능 기간을 정할 수 있으며 이 경우 서비스를 통해 사전 공지합니다. <br />
  
          <br /><b>제 12 조 (회원의 의무)</b><br />
          1. 회원은 관련 법령, 본 약관의 각 규정, 이용안내 및 서비스와 관련하여 공지하거나 통지한 주의사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 아니됩니다. <br />
          2. 회원은 내용별로 회사가 서비스 공지사항에 게시하거나 별도로 공지한 이용제한 사항을 준수하여야 합니다. <br />
          3. 회원은 회사의 사전승낙 없이는 서비스를 이용하여 영업 기타 영리적 목적의 활동을 할 수 없으며, 그 영업활동의 결과와 회원이 약관에 위반한 영업활동을 하여 발생한 결과에 대하여 회사는 책임을 지지 않습니다.
          회원은 이와 같은 영업활동으로 회사가 손해를 입은 경우 회사에 대하여 손해배상 의무를 집니다. <br />
          "4. 회원은 회사의 명시적인 동의가 없는 한 서비스의 이용권한, 기타 이용계약상의 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다. <br />
          5. 회원은 서비스 이용과 관련하여 다음 각 호에 해당되는 행위를 하여서는 안됩니다. <br />
          ①신청 또는 변경 시 허위내용의 기재 <br />
          ②타인의 정보도용 <br />
          ③회사에 게시된 정보의 변경 <br />
          ④회사가 금지한 정보(컴퓨터 프로그램 등)의 송신 또는 게시 <br />
          ⑤회사”와 기타 제3자의 저작권 등 지적재산권에 대한 침해 <br />
          ⑥회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위 <br />
          ⑦외설 또는 폭력적인 말이나 글, 화상, 음향, 기타 공서양속에 반하는 정보를 회사의 사이트에 공개 또는 게시하는 행위 <br />
          ⑧기타 불법적이거나 부당한 행위 <br />
          6.회원은 관계법령, 이 약관의 규정, 이용안내 및 콘텐츠와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다. <br />
  
          <h4>제 4 장 서비스 이용</h4>
  
          <br /><b>제 13 조 (서비스의 제공 등)</b><br />
          1. 회사는 회원에게 아래와 같은 서비스를 제공합니다. <br />
          ①WEB 영상콘텐츠 기반 서비스(Vod, AOD, “ON-AIR”, Text등) <br />
          ②SNS 연동 등의 커뮤니케이션 서비스 <br />
          ③기타 회사가 추가로 고안하거나 다른 회사와의 제휴계약 등을 통해 회원에게 제공하는 일체의 서비스 <br />
          2. 서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간 가능함을 원칙으로 합니다. 다만, 정기 점검 등의 필요로 회사가 정한 날이나 시간은 그러하지 않습니다. <br />
          3. 회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능 시간을 별도로 정할 수 있습니다. 이 경우 사전에 공지를 통해 그 내용을 알립니다. <br />
          4. 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 회사는 본 약관상 정한 방법에 따라 회원에게
          통지하되, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우에는 사후에 통지할 수 있습니다. <br />
          5. 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다. <br />
  
          <br /><b>제 14 조 (서비스의 변경 등)</b><br />
          1. 회사는 운영상ㆍ기술상의 필요에 따라 제공하고 있는 서비스의 전부 또는 일부를 변경할 수 있습니다. <br />
          2. 서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등을 그 변경 전 7일 이상 해당 서비스 초기 화면에 게시합니다. <br />
          3. 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요에 따라 수정하거나 중단 또는 변경할 수 있습니다. <br />
  
          <br /><b>제 15 조 (정보의 제공)</b><br />
          1. 회사는 회원이 서비스 이용을 위하여 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 회원에게 제공할 수 있습니다. <br />
          2. 회사는 회원에게 편리하고 다양한 서비스를 제공하기 위하여 관련 법령이 정한 절차와 제한 범위 내에서 회원으로부터 수집한 개인정보를 이용하여 회사가 제공하는 각종 서비스에 대한 정보, 광고, 타사의 상품이나
          서비스, 이벤트 등에 대한 광고를 회사가 직접 또는 제휴사와 함께 전자우편, DM(우편) 등의 방법으로 광고하거나 제공할 수 있습니다. 이 경우 회원은 관련 법에 따른 거래 관련 정보 및 고객 문의 등에 대한 답변 등을
          제외하고는 언제든지 전자우편 등에 대해서 서비스 및 광고를 거절할 수 있습니다.<br />
          3. 회사는 서비스에 관한 정보 등의 기타 마케팅 및 홍보활동을 함에 있어 TM(텔레마케팅), SMS(핸드폰 문자메세지) 및 모사 전송기기에 의하여 그 정보를 회원에게 전송하고자 하는 경우, 회원의 사전 동의를 받아
          전송합니다. 다만 회원의 거래 관련 정보 및 고객문의 등에 대한 답변은 그렇지 아니합니다. <br />
          4. 회사는 서비스의 운영과 관련하여 서비스 화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있으며, 광고가 게재된 전자우편 등을 수신한 회원은 수신거절을 할 수 있습니다. <br />
          5. 기타 회원의 정보 제공 및 이용에 관한 세부 사항은 회사가 정하는 개인정보보호정책에 정한 바를 따릅니다. <br />
  
          <br /><b>제 16 조 (요금 및 유료정보 등)</b><br />
          1. 회사가 제공하는 서비스는 무료서비스와 유료서비스가 있습니다. 회사 및 제휴사가 제공하는 별도의 유료정보에 대해서는 해당 정보에 명시된 요금을 지불하여야 사용 가능합니다. <br />
          2. 회사가 제공하는 유료서비스를 이용하고자 하는 회원은 본 약관에서 정의하고 있는 유료서비스 이용약관에 동의하고 회사에 이용요금(또는 회비)를 납부하여야 합니다. <br />
  
          <br /><b>제 17 조 (게시물 삭제)</b><br />
          1. 회사는 게시판에 정보통신망이용촉진 및 정보보호 등에 관한 법률을 위반한청소년유해매체물이 게시되어 있는 경우에는 이를 지체 없이 삭제 합니다. 다만, 19세 이상의 이용자만 이용할 수 있는 게시판은 예외로
          합니다. <br />
          2. 회사가 운영하는 게시판 등에 게시된 정보로 인하여 법률상 이익이 침해된 자는 회사에게 당해 정보의 삭제 또는 반박내용의 게재를 요청할 수 있습니다. 이 경우 회사는 지체 없이 필요한 조치를 취하고 이를 즉시
          신청인에게 통지합니다. <br />
  
          <br /><b>제 18 조 (저작권 등의 귀속)</b><br />
          1. 회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다. <br />
          2. 회사가 제공하는 서비스 중 제휴계약에 의해 제공되는 저작물에 대한 저작권 기타 지적재산권은 해당 제공업체에 귀속합니다. <br />
          3. 이용자는 회사가 제공하는 서비스를 이용함으로써 얻은 정보 중 회사 또는 제공업체에 지적재산권이 귀속된 정보를 회사 또는 제공업체의 사전승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여 영리 혹은
          비영리 목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다. <br />
          4. 회사는 약정에 따라 이용자의 저작물을 사용하는 경우 당해 이용자의 허락을 받습니다. <br />
  
          <br /><b>제 19 조 [정보의 제공 및 광고의 게재]</b><br />
          1. 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편, SMS, 모바일 알림기능 등의 방법으로 회원에게 제공할 수 있습니다. <br />
          2. 제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 회원의 사전 동의를 받아서 전송합니다. <br />
          3. 회사는 서비스제공과 관련하여 유료서비스화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편 등을 수신한 회원은 수신거절을 회사에게 할 수 있습니다. <br />
  
          <br /><b>제 20 조 (서비스 이용 책임)</b><br />
          회원은 회사의 권한 있는 사원이 서명한 명시적인 서면을 통하여 구체적으로 허용된 경우를 제외하고는 서비스를 이용하여 상품 등을 판매하는 영업활동을 할 수 없으며 특히 해킹, 돈벌이 광고, 음란 사이트 등을 통한
          상업행위, 상용S/W 불법배포 등을 할 수 없습니다. 이를 어기고 발생한 영업활동의 결과 및 손실, 관계기관에 의한 구속 등 법적 조치 등에 관해서 회사는 책임을 지지 않습니다. <br />
  
          <br /><b>제 21 조 (서비스 제공의 중지 등)</b><br />
          1. 무료 서비스의 경우 회사는 회사의 필요에 따라 서비스의 전부 또는 일부를 수정 하거나 중지할 수 있습니다.<br />
          2. 회사는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다. <br />
          ①서비스용 설비의 보수 등 공사로 인한 부득이한 경우 <br />
          ②전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우 <br />
          ③회사의 사정에 의하여 서비스 제공이 곤란한 경우나 불가항력적 사유가 있는 경우 <br />
          3. 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다. <br />
  
          <h4>제 5 장 계약 해지 및 이용 제한</h4>
  
          <br /><b>제 22 조 [회원탈퇴 및 자격 상실 등]</b><br />
          1. 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 즉시 회원탈퇴를 처리합니다.<br />
          ①“나의정보>정보수정>회원탈퇴”에서 언제든지 탈퇴를 요청할 수 있으며, 고객센터 e-mail, 전화 등으로 연락하시면 신속하게 개인정보의 삭제 등 필요한 조치를 합니다. <br />
          ②회원탈퇴 시 개인정보는 “개인정보 취급방침”에 따라 관리/삭제 조치되며, 보유 이용권과 쿠폰 모두 삭제됩니다. <br />
          ③회원탈퇴 후 다시 신규회원으로 바로 가입 가능합니다. <br />
          2. 회원이 다음 각호의 사유에 해당하는 경우, 회사는 회원자격을 제한 및 정지시킬 수 있습니다<br />
          ①가입신청 시에 허위내용을 등록한 경우 <br />
          ②회사의 서비스이용대금, 기타 회사의 서비스이용에 관련하여 회원이 부담하는 채무를 기일에 이행하지 않는 경우 <br />
          ③다른 사람의 회사의 서비스 이용을 방해 하거나 그 정보를 도용하는 등 전자 상거래 질서를 위협하는 경우 <br />
          ④회사를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우 <br />
          3. 회사는 회원이 제2항의 행위를 한 경우 또는 본 약관의 의무를 위반하는 경우, 그 위반행위의 중대성에 따라 경고, 일시 정지, 영구이용정지 등 서비스의 이용을 단계적으로 제한 할 수 있습니다. <br />
          4. 회사는 본 약관에 정한 바에 따라 적법한 서비스 이용을 보장 하고 회원을 보호하기 위하여 별도의 이용제한의 조건 및 내용에 대한 세부절차를 정할 수 있습니다.<br />
          5. 회원은 본 약관에 따른 이용제한 등에 대하여 회사가 정한 절차에 따라 즉시 이의 신청을 할수 있으며, 그 이의 사유가 정당한 것으로 인정되는 경우 회사는 즉시 서비스의 이용을 재개합니다. <br />
  
          <h4>제 6 장 손해배상 및 기타 사항</h4>
  
          <br /><b>제 23 조 (손해배상)</b><br />
          1. 회사가 제공하는 무료서비스를 이용하는 과정에서 발생한 회원의 손실 또는 손해에 관하여, 회사는 고의 또는 과실로 인하여 발생한 상당인과관계 있는 손해에 대하여 책임을 부담합니다. 다만 회원이 무료서비스를
          이용하지 못한 자체에 대하여 회사는 배상책임이 없습니다. <br />
          2. 회사는 제휴사가 취급하는 상품 또는 용역에 대하여 책임을 지지 않습니다. <br />
  
          <br /><b>제 24 조 (면책조항)</b><br />
          1. 회사는 천재지변 또는 이에 준하는 불가항력 기타 회사의 책임없는 사유로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다. <br />
          2. 회사는 회원의 귀책사유로 인한 서비스 이용 장애에 대하여 책임을 지지 않습니다. <br />
          3. 회사는 회원이 서비스를 통해 게재하거나 유포, 전송한 정보, 자료, 사실의 신뢰도, 정확성, 적법성, 안정성 등에 관하여 보장하지 않습니다. <br />
          4. 회사는 회원간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 이루어진 거래에 대하여 책임을 부담하지 아니합니다. <br />
          5. 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 부담하지 아니합니다. <br />
  
          <br /><b>제 25 조 (준거법 및 관할법원)</b><br />
          1. 회사와 회원간에 제기된 소송에 대해서는 대한민국 법률을 준거법으로 합니다. <br />
          2. 회사와 회원간 발생한 분쟁에 관한 소송은 제소 당시의 회원의 주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의 전속관할로 합니다. 단, 제소 당시 회원의 주소 또는 거소가 명확하지 아니한 경우의
          관할법원은 민사소송법에 따라 정합니다. <br /><br />
  
          부칙 <br /><br />
          (시행일) 이 약관은 2019년 7월 1일부터 시행합니다.
        </div>
      </div>
      <div class="tos-agree-footer">
        <div class="agree-normal">
          <button class="tos-check" #agreeTos (click)="this.toggleAgreeTos()">
            <img [src]="'../../../../assets/icons/Icon_64px_col-35.png'" class="agree-icon" alt="Agree Icon">
          </button>
          <h3 class="tos-agree-txt">위 내용에 동의합니다.</h3>
        </div>
        <div class="agree-error" *ngIf="this.agreeSubmitted && !this.tosAgree">
          <h4 class="agree-error-txt">사이트 이용약관에 동의하셔야 합니다.</h4>
        </div>
      </div>
    </div>
    <div class="tos-wrapper"> 
      <div class="tos-dropdown-header">
        <div class="tos-dropdown-header-left">
          <img src="../../../../assets/icons/Icon_64px_col-11.png" class="tos-info-icon">
          <h2 class="tos-header-title">개인정보 처리방침</h2>
        </div>
        <button class="tos-dropdown-btn" (click)="this.togglePrivTermsDropdown()">
          <svg
          id="privChevron"
            width="35"
            height="35"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            transform="rotate(0)"
          >
            <path
              d="M6 9l6 6 6-6"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <div class="tos-dropdown-hide" *ngIf="this.showPrivTerms" @toggleDiv>
        <div class="tos-txt-scroll">
          <p><b>1. “개인정보” 수집 및 이용 목적</b></p>
          회원의 개인정보는 서비스 이용계약의 성립(본인 식별 및 본인 의사 확인 등), 서비스의 이행(상품배송 및 대금결제), 기타 새로운 서비스 및 신상품이나 이벤트에 대한 정보 안내(제공)의 목적으로 수집되어 이용됩니다.
          또한, 회사는 회원에게 편리하고 다양한 서비스를 제공하기 위하여 회원으로부터 수집한 개인정보를 이용하여 회사가 제공하는 각종 서비스를 전자우편, TM(텔레마케팅), DM(우편), SMS(핸드폰 문자메시지) 등의 방법으로
          광고 또는 마케팅 활동을 수행할 수 있습니다. 이 경우 회원은 언제든지 전자우편 등을 통해 원치 않는 서비스를 거절할 수 있습니다.<br />
      
          <p><b>2. 수집하는 개인정보 수집 항목</b></p>
          회사는 홈페이지를 통한 회원 가입, 상담게시판, 전화, FAX, 우편을 통해 고객이 제시하는 개인정보수집을 할 수 있습니다. 회사가 수집하는 개인정보는 서비스 제공에 필요한 최소한으로 하되, 부가정보가 필요한
          경우에는 부가정보를 요구할 수 있습니다. 회사는 회원가입 화면에서 다음과 같은 개인정보항목을 회원으로부터 제공받고 있습니다.<br />
      
          ① 일반회원 가입 시 수집항목<br />
      
          필수정보 이름(실명), 아이핀CI, 성별, 생년월일, 휴대폰번호(휴대폰 인증 시), 비밀번호<br />
      
          선택정보 이메일주소, 이메일/SMS마케팅 수신동의<br />
      
          주민등록번호 처리기준 회사는 실명확인을 통한 회원가입과 관련하여 회원의 주민등록번호를 저장하지 않습니다. 주민등록번호는 신용평가기관을 통한 실명확인용으로만 이용되며 이에 동의하지 않으실 경우, 아이핀
          인증을 통해 회원가입을 하실 수 있습니다.<br />
      
          ② 만 14세 미만 어린이 회원 가입 시 수집항목<br />
      
          필수정보 이름(실명), 아이핀CI, 성별, 생년월일, 휴대폰번호(휴대폰 인증 시), 비밀번호, 법정대리인 정보<br />
      
          선택정보 이메일주소, 이메일/SMS마케팅 수신동의<br />
      
          주민등록번호 처리기준 회사는 실명확인을 통한 회원가입과 관련하여 회원의 주민등록번호를 저장하지 않습니다. 주민등록번호는 신용평가기관을 통한 실명확인용으로만 이용되며 이에 동의하지 않으실 경우, 아이핀
          인증을 통해 회원가입을 하실 수 있습니다. 만 14세 미만 아동의 개인정보를 수집할 경우, 회사는 정보통신망법 제31조(법정대리인의 권리)에 따라 법정대리인(부모 등 아동의 보호자)의 동의를 받아야 합니다.
          법정대리인은 14세 미만 아동 회원의 개인정보를 조회하거나 수정할 권리, 수집 및 이용 동의를 철회할 권리를 가집니다.<br />
      
          ③ 간편회원 가입 시 수집항목<br />
      
          필수정보 이름, 성별, 생년월일, 비밀번호<br />
      
          선택정보 휴대폰번호, 이메일주소, 이메일/SMS마케팅 수신동의<br />
      
          ④ 이 외에 회사가 서비스 이용과정이나 사업처리 과정에서의 수집할 수 있는 추가 정보<br />
      
          추가수집 정보 발/수신번호, 통화시각, 사용도수, 서비스 이용시간 및 이용기록, 접속로그, 쿠키, 접속 IP정보, 결제정보, 사용자 기기정보, 이용정지 기록 등: 고객상담, 이용요금 정산 및 개인맞춤 서비스 제공<br />
      
          <p><b>3. 개인정보의 보유 및 이용기간</b></p>
      
          회원의 개인정보는 회사가 서비스를 제공하는 기간(이용기간) 또는 분쟁처리 기간(보유기간) 동안에 한하여 보유하고 이를 활용하며 개인정보의 수집목적 또는 제공받은 목적을 달성한 이후에는 지체없이 재생불가능한
          기술적인 방법을 사용하여 개인정보를 파기하고 있습니다.<br />
      
          이용기간 서비스 가입일 ~ 해지일 까지<br />
      
          보유기간 불량회원의 부정한 이용의 재발 방지 및 서비스 혼선 방지를 위해 이용계약 해지일로부터 3개월간 보유<br />
      
          단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.<br />
      
          ① 회사 내부 방침에 의한 정보보유 사유<br />
      
          구분 보존이유 보존기간<br />
      
          부정이용기록 부정 이용 방지 1년<br />
      
          ② 관련법령에 의한 정보보유 사유<br />
      
          상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의
          목적으로만 이용하며 보존기간은 아래와 같습니다.<br />
      
          구분 보존이유 보존기간<br />
      
          서비스 이용기록 통신비밀보호법 3개월<br />
      
          소비자의 불만 또는 분쟁처리에 관한 기록 전자상거래 등에서의 소비자보호에 관한 법률 3년<br />
      
          계약 또는 청약철회 등에 관한 기록 전자상거래 등에서의 소비자보호에 관한 법률 5년<br />
      
          대금결제 및 재화 등의 공급에 관한 기록 전자상거래 등에서의 소비자보호에 관한 법률 5년<br />
      
          <p><b>4. 개인정보의 파기절차 및 방법</b></p>
      
          고객님이 서비스 가입 등을 위해 제공하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져 내부 방침 및 기타 관련 법령에 의한 보유 사유에 따라 일정기간 저장된 후, 아래 다항에 명시한 방법으로 파기됩니다. 별도 DB에
          옮겨진 개인정보는 법률에 의해 보유되는 경우가 아니고서는 이외의 다른 목적으로 이용되지 않습니다. 이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에 파기되며,
          개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.<br />
      
          파기대상 보유기간 및 관련법령에 따른 보존기간이 종료된 고객정보<br />
      
          파기절차 1) 탈퇴 및 휴면계정 개인정보 분리<br />
      
          2) 별도 DB에 분리저장 후 3개월간 보관<br />
      
          3) 개인정보 보유기간 종료 후 종료일로부터 5일 이내 개인정보 완전 파기<br />
      
          파기방법<br />
      
          1) 서면에 작성, 출력된 개인정보: 분쇄하거나 소각<br />
      
          2) DB 등 전자적 파일 형태로 저장된 개인정보: 기록을 재생할 수 없는 기술적 방법 으로 삭제<br />
      
          기타, 휴면계정의 개인정보 파기 정보통신망 이용촉진 및 정보보호 등에 관한 법률 시행령 제29조 및 동법시행령 제16조 제2항에 의거하여 회원 가입 후 1년 동안 서비스를 이용하지 않은 휴면계정은 탈퇴처리하고
          이용자의 개인정보는 파기 또는 분리 저장 할 수 있습니다.<br />
      
          휴면계정의 개인정보 분리 저장 및 파기는 상기에 명시한 절차와 방법에 따라 진행됩니다.<br />
      
          회사는 휴면계정 이용자에게 유효기간이 도래하기 30일전까지는 이용자에게 통지하여야 하고 통지내용은 아래와 같습니다.<br />
      
          - 통지내용 : 개인정보의 파기 또는 분리 저장 관리되는 사실, 파기 일시, 파기되는 개인정보 항목<br />
      
          - 통지 방법 : 이메일, SMS, 전화, 팩스 등<br />
      
          <p><b>5. 취급 위탁 안내</b></p>
      
          회사는 서비스 이행을 위해 아래와 같이 외부 전문업체에 위탁하여 운영하고 있습니다.<br />
      
          수탁업체 위탁업무 내용<br />
      
          KG올앳 주식회사 요금결제, 결제 시스템 개발 등<br />
      
          굿니스 시니어콘텐츠 개발.공유협력<br />
      
          쿠키건강 TV 콘텐츠 개발.공유협력<br />
      
          <p><b>6. 이용자 및 법정대리인의 권리와 그 행사방법</b></p>
      
          ① 이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수 있습니다.<br />
      
          ② 이용자들의 개인정보 조회/수정 또는 가입 해지(동의철회)를 위해서는 정보수정메뉴를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.<br />
      
          ③ 혹은 고객센터에게 전화 또는 이메일로 연락하시면 조치하겠습니다.<br />
      
          ④ 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당사 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게
          지체없이 통지하여 정정이 이루어지도록 하겠습니다.<br />
      
          ⑤ 회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보는 "회사가 수집하는 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.<br />
      
          <p><b>7. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</b></p>
      
          회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트가 이용자의 컴퓨터 웹브라우저로 전송하는 소량의 정보로 PC
          하드디스크에 저장되기도 합니다. 이용자가 웹사이트에 접속을 하면 회사의 서버는 이용자의 웹브라우저에 있는 쿠키의 내용을 읽고 이용자의 추가정보를 이용자의 컴퓨터 상에서 찾아 접속에 따른 성명 등의 추가 입력
          없이 서비스를 제공할 수 있습니다.<br />
      
          ① 쿠키의 사용 목적<br />
      
          쿠키는 이용자의 컴퓨터를 식별하지만 이용자 개개인을 개별적으로 식별하지는 않습니다. 회사는 이 쿠키를 이용하여 이용자들이 방문한 각 서비스에 대한 방문 및 이용 형태, 이용자 규모 등을 파악하여 더욱 더 편리한
          서비스를 만들 수 있습니다. 이용자들은 웹브라우저의 옵션을 조정하여 쿠키에 대한 사용 여부를 선택하실 수 있습니다. 즉, 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를
          거부하실 수도 있습니다. 다만, 이용자들은 회사에 접속한 후 로그인(LOG-IN)하여 서비스를 이용하기 위해서는 쿠키를 허용하여야 합니다. 참고로, 회사에 링크 되어 있는 웹사이트들이 개인정보를 수집하는 행위에
          대해서는 회사는 개인정보 보호의무를 지지 않습니다.<br />
      
          ② 쿠키의 설치/운영 및 거부<br />
      
          - 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도
          있습니다.<br />
      
          - 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.<br />
      
          - 설정방법 예 (인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보<br />
      
          - 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 회사의 일부 서비스를 이용하는데 어려움이 있을 수 있습니다.<br />
      
          <p><b>8. 개인정보 보호를 위한 기술 및 관리적 대책</b></p>
      
          회사는 개인정보 보호를 위해 기술적인 측면과 관리적인 측면에서 다음과 같은 조치를 취하고 있습니다.<br />
      
          ① 개인정보 취급 직원의 최소화 및 교육<br />
      
          개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.<br />
      
          ② 내부관리계획의 수립 및 시행<br />
      
          개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.<br />
      
          ③ 해킹 등에 대비한 기술적 대책<br />
      
          해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고
          있습니다.<br />
      
          ④ 개인정보의 암호화<br />
      
          이용자의 개인정보는 비밀번호는 암호화(encryption) 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을
          사용하고 있습니다.<br />
      
          ⑤ 접속기록의 보관 및 위변조 방지<br />
      
          개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.<br />
      
          ⑥ 개인정보에 대한 접근 제한<br />
      
          개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고
          있습니다.<br />
      
          개인정보 보호에 대한 정기적인 사내 정신교육 실시 및 회원 계정에 대한 보안 유지는 개인정보의 보호를 위해 매우 중요한 사항이며 회원계정 보안유지 책임은 해당 회원 본인에게 있습니다. 회사에서는 어떠한 경우에도
          회원에게 직접적으로 비밀번호를 질문하지는 않기 때문에 회원 여러분은 타인에게 비밀번호가 유출되지 않도록 각별히 유의하셔야 합니다.<br />
      
          <p><b>9. 목적 외 사용 및 제3자에 대한 제공(또는 공유)</b></p>
      
          회사는 이용자 또는 회원이 제공한 개인정보를 이용자 또는 회원의 동의 없이 외부에 공개하거나 제3자와 공유하지 않습니다. 다만, 다음 각 호의 하나에 해당되는 경우 또는 법률의 특별한 규정 등 개인정보 보호법
          제17조 및 18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.<br />
      
          ① 이용자 또는 회원에게 제3자 제공 및 취급업무의 위탁 등에 대한 동의를 얻은 경우<br />
      
          ② 이용자 또는 회원이 서비스 내 전자게시판 등에 남긴 TV, DTV(데이터방송), 인터넷방송 프로그램에 대한 시청자 의견, 신청곡, 사연 또는 설문조사 참여결과 등을 해당 방송 프로그램 내용에 직접 반영하거나 서비스
          이용시 본인확인을 위해 관련 프로그램의 제작진 및 진행자에게 이용자 또는 회원이 제공한 개인 정보 중 이름, 성별, 생년월일, 이메일, 아이디(ID), 연락처 등 원활한 방송 프로그램 진행에 필요한 제한된 범위의
          개인정보를 제공하는 경우<br />
      
          ③ 회원이 회사 및 제휴사의 서비스를 별도의 회원가입 절차 없이 회사가 부여한 하나의 회원ID로 이용할 수 있도록 하기 위하여, 특정 제휴사의 서비스를 이용하고자 하는 회원에 대하여 회사가 보유한 해당 회원의
          개인정보를 관계된 제휴사에 제공할 수 있습니다(회원의 최초 이용신청 후 제휴사가 늘어난 경우에도 동일하게 적용합니다). 이 경우, 회원은 언제든지 전자우편 등을 통해 해당 제휴사에 대한 개인정보 제공을 거절하고
          관련된 서비스 이용을 중단할 수 있습니다.<br />
      
          ④ 회사의 서비스이용약관이나 유료서비스이용약관, 기타 회원제 서비스 등의 이용약관을 위반한 경우<br />
      
          ⑤ 서비스를 이용하여 타인에게 정신적, 물질적 피해를 줌으로써 그에 대한 법적인 조치를 취하기 위하여 개인정보를 공개해야 한다고 판단되는 충분한 근거가 있는 경우<br />
      
          ⑥ 기타 법에 의해 요구된다고 선의로 판단되는 경우<br />
      
          ⑦ 서비스 제공에 따른 요금 정산 및 상품 배송을 위하여 필요한 경우<br />
      
          ⑧ 통계작성, 학술연구 또는 시장조사를 위하여 개인을 식별할 수 없는 형태로 제공하는 경우<br />
      
          <p><b>10. 개인정보 관리 책임자 및 담당자</b></p>
      
          회사는 개인정보 관리 책임자 및 담당자를 정하여 회원의 개인정보를 보호하고 있습니다.<br />
      
          [개인정보보호관리 책임자]<br />
      
          성 명 : 서남현<br />
      
          소 속 : 경영지원<br />
      
          전화번호 : 070-8808-8838<br />
      
          이메일 : tboxtop&#64;tbox.media<br />
      
          [개인정보보호관리 담당자]<br />
      
          성 명 : 서남현<br />
      
          소 속 : 경영지원<br />
      
          전화번호 : 070-8808-8838<br />
      
          이메일 : tboxtop&#64;tbox.media<br />
      
          <p><b>11. 개인정보 관련 불만사항 및 분쟁의 처리</b></p>
      
          개인정보 침해를 이유로 피해구제를 받기 원하는 이용자께서는 회사개인정보 관리 책임자 또는 담당자에게 연락해 주십시오. 또는 아래의 기관에서 개인정보 침해에 대한 신고 또는 상담, 피해구제, 분쟁조정 등을
          문의하실 수 있습니다.<br />
      
          [개인정보 침해신고센터 및 개인정보 분쟁조정위원회(한국인터넷진흥원 운영)]<br />
      
          홈페이지 : privacy.kisa.or.kr<br />
      
          전화번호 : (국번없이) 118<br />
      
          [대검찰청 사이버범죄수사단]<br />
      
          홈페이지 : www.spo.go.kr<br />
      
          전화번호 : 02-3480-2000<br />
      
          [경찰청 사이버안전국]<br />
      
          홈페이지 : www.ctrc.go.kr<br />
      
          전화번호 : (국번없이) 182<br /><br />
      
          부칙<br /><br />
      
          (시행일) 이 약관은 2019년 7월 1일부터 시행합니다.<br />
        </div>
      </div>
      <div class="tos-agree-footer">
        <div class="agree-normal">
          <button class="tos-check" #agreePriv (click)="this.toggleAgreePriv()">
            <img [src]="'../../../../assets/icons/Icon_64px_col-35.png'" class="agree-icon" alt="Agree Icon">
          </button>
          <h3 class="tos-agree-txt">위 내용에 동의합니다.</h3>
        </div>
        <div class="agree-error" *ngIf="this.agreeSubmitted && !this.privAgree">
          <h4 class="agree-error-txt">개인정보 처리방침에 동의하셔야 합니다.</h4>
        </div>
      </div>
      
    </div>
    <div class="tos-wrapper">
      <div class="tos-dropdown-header">
        <div class="tos-dropdown-header-left">
          <img src="../../../../assets/icons/Icon_64px_col-11.png" class="tos-info-icon">
          <h2 class="tos-header-title">멤버십 약관</h2>
        </div>
        <button class="tos-dropdown-btn" (click)="this.toggleMemTermsDropdown()">
          <svg
          id="memChevron"
            width="35"
            height="35"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            transform="rotate(0)"
          >
            <path
              d="M6 9l6 6 6-6"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <div class="tos-dropdown-hide" *ngIf="this.showMemTerms" @toggleDiv>
        <div class="tos-txt-scroll">
          <h4>등급 권한</h4>
          <p>일반회원 : 홈 트레이닝 또는 개인으로 사이클링을 즐기는 회원을 뜻합니다.</p>
          <p>비즈회원 : 피트니스클럽. 스포츠센터. 단체 등에서 운영되는 사업자 회원을 뜻합니다.</p>
          <p>교육생 : T사이클링 자격취득 후 센터수업 출강을 희망하는 예비교육생 및 교육을 완료하여 필드 수업을 진행하는 강사진을 뜻합니다.</p>
          <br />
  
          <h4>이용권 주의사항</h4>
          <p>1. 콘텐츠는 제공사의 사정에 따라 수시 변경, 정정, 삭제, 지원되지 않을 수 있습니다.</p>
          <p>2. 다운로드 이용권은 기간 및 회수를 이월하지 않습니다.</p>
          <p>3. 제휴 이용권은 할인 혜택이 적용되어 있으므로 추가 혜택(페이백, 기타 할인 등)이 적용되지 않습니다.</p>
          <br />
  
          <h4>서비스 이용 주의사항</h4>
          <p>1. T-BOX MEDIA 서비스는저작권 문제로 해외 서비스 이용 시 이용금액이 상이할 수 있습니다.</p>
          <p>2. 동시접속 상품은 한 계정당 구매하신 회선만큼 동시에 여러 기기에서 시청하실 수 있는 상품입니다.</p>
          <p>3. 일부 콘텐츠는 저작권자의 요청에 따라 LIVE 및 Vod 서비스가 제공되지 않을 수 있습니다.</p>
          <p>4. 일부 콘텐츠는 콘텐츠 제공사의 사정에 따라 FHD화질이 제공되지 않을 수 있습니다.</p>
          <p>5. 콘텐츠는 회원권별로 제공사항이 제한될 수 있습니다.</p>
          <br />
  
          <h4>이용권 구매 주의사항</h4>
          <p>1. 모든 이용권은 부가세(VAT)포함 가격입니다.</p>
          <p>2. 할인가격은 프로모션 종료 후 변경 될 수 있습니다.</p>
          <p>3. 자동결제는 1개월 단위로 이루어지므로 구매하신 날과 매월 동일한 날짜에 결제가 이루어지며 모든 T-BOX MEDIA 상품은 선불결제 상품으로 구매 및 사용하신 이용권에 대해서는 결제취소 및 환불되지 않습니다.</p>
        </div>
      </div>
      <div class="tos-agree-footer">
        <div class="agree-normal">
          <button class="tos-check" #agreeMem (click)="this.toggleAgreeMem()">
            <img [src]="'../../../../assets/icons/Icon_64px_col-35.png'" class="agree-icon" alt="Agree Icon">
          </button>
          <h3 class="tos-agree-txt">위 내용에 동의합니다.</h3>
        </div>
        <div class="agree-error" *ngIf="this.agreeSubmitted && !this.memAgree">
          <h4 class="agree-error-txt">멤버십 약관에 동의하셔야 합니다.</h4>
        </div>
      </div>
    </div>
    <div class="agree-all-footer">
      <button class="tos-check" #agreeAll id="agree-all-btn" (click)="this.toggleAgreeAll()">
        <img [src]="'../../../../assets/icons/Icon_64px_col-35.png'" class="agree-icon" id="agree-all-icon" alt="Agree Icon">
      </button>
      <h3 class="tos-agree-txt" id="agree-all-txt">모든 약관을 자세히 읽었으며, 이에 동의합니다.</h3>
    </div>
    <div class="nav-btns">
      <button class="back-next-btn" id="next-btn" (click)="this.gotoRegisterStep(2)">정보 입력하기</button>
    </div>
  </div> 
  <div *ngIf="this.registerStep === 2" class="step-2">
    <div class="register-form-wrap" [formGroup]="this.registerForm">
      <div class="info-header">
        <span class="line-icon"></span>
        필수 기입 정보
        <span class="info-text">아래에 있는 정보들을 빠짐 없이 입력 부탁드립니다.</span>
      </div>
      
      <div class="form-container">
        <!-- 아이디 입력 -->
        <div class="form-row">
          <div class="form-item-title">
            <label for="id">아이디</label>
          </div>
          <div class="input-group">
              <input type="text" id="id" class="form-control" formControlName="username" placeholder="영문자+숫자 조합하여 6자 이상" autocomplete="username">
              <button class="check-btn" (click)="this.checkUsernameUnique()">중복확인</button>
          </div>
           <div class="agree-error" *ngIf="rf['username'].invalid && (this.formSubmitted || rf['username'].dirty)">
            <h4 *ngIf="rf['username'].errors?.['required']" class="agree-error-txt">아이디를 입력해 주세요.</h4>
            <h4 *ngIf="rf['username'].errors?.['minlength']" class="agree-error-txt">아이디가 너무 짧습니다. (영문자 + 숫자 조합 6자 이상)</h4>
            <h4 *ngIf="!rf['username'].errors?.['minlength'] && rf['username'].errors?.['pattern'] && !rf['username'].errors?.['maxlength']" class="agree-error-txt">유효한 아이디를 입력해 주세요. (영문자 + 숫자 조합 6자 이상)</h4>
            <h4 *ngIf="rf['username'].errors?.['maxlength'] && !rf['username'].errors?.['minlength']" class="agree-error-txt">아이디는 20글자 이내로 입력해 주세요.</h4>
          </div>
          <div class="agree-error" *ngIf="rf['username'].valid && !rf['usernameCheckedUnique'].value && this.formSubmitted"
          [ngClass]="{'hidden-margin': !rf['usernameCheckedUnique'].value}">
            <h4 class="agree-error-txt">아이디 중복 여부를 확인해 주세요.</h4>
          </div>
          <div class="agree-error" *ngIf="rf['usernameCheckedUnique'].value && this.usernameExists"
          [ngClass]="{'hidden-margin': !this.usernameExists}">
            <h4 class="agree-error-txt">이미 사용중인 아이디입니다.</h4>
          </div>
          <div class="agree-succ" *ngIf="rf['username'].valid && rf['usernameCheckedUnique'].value && !this.usernameExists">
            <h4 class="agree-succ-txt">사용 가능한 아이디 입니다.</h4>
          </div>
          <!--
          <div class="agree-error" *ngIf="rf['usernameCheckedUnique'].value && this.formSubmitted">
            <h4 *ngIf="rf['username'].errors?.['minlength'] && (rf['usernameCheckedUnique'].value || this.formSubmitted)" class="agree-error-txt">
              아이디가 너무 짧습니다. (영문자 + 숫자 조합 6자 이상)</h4>
          </div>
          -->          
        </div>
  
        <!-- 이메일 입력 -->
        <div class="form-row">
          <div class="form-item-title">
            <label for="email">이메일</label>
          </div>
          <div class="input-group">
            <input type="email" id="email" class="form-control" formControlName="email" placeholder="example@email.com" autocomplete="email">
            <button class="check-btn" (click)="this.checkEmailUnique()">중복확인</button>
          </div>
          <div class="agree-error" *ngIf="rf['email'].invalid && (this.formSubmitted || rf['email'].dirty)">
            <h4 class="agree-error-txt" *ngIf="rf['email'].errors?.['required']">이메일을 입력해 주세요.</h4>
            <h4 class="agree-error-txt" *ngIf="rf['email'].errors?.['email'] && !rf['email'].errors?.['maxlength']">유효한 이메일을 입력해 주세요.</h4>
            <h4 class="agree-error-txt" *ngIf="rf['email'].errors?.['maxlength'] && rf['email'].errors?.['email']">이메일은 50글자 이내로 입력해 주세요.</h4>
          </div>
          <div class="agree-error" *ngIf="rf['email'].valid && !rf['emailCheckedUnique'].value && this.formSubmitted"
          [ngClass]="{'hidden-margin': !rf['emailCheckedUnique'].value}">
            <h4 class="agree-error-txt">이메일 중복 여부를 확인해 주세요.</h4>
          </div>
          <div class="agree-error" *ngIf="rf['emailCheckedUnique'].value && this.emailExists"
          [ngClass]="{'hidden-margin': !this.emailExists}">
            <h4 class="agree-error-txt">이미 사용중인 이메일입니다.</h4>
          </div>
          <div class="agree-succ" *ngIf="rf['email'].valid && rf['emailCheckedUnique'].value && !this.emailExists">
            <h4 class="agree-succ-txt">사용 가능한 이메일 입니다.</h4>
          </div>
        </div>
  
        <!-- 비밀번호 입력 -->
        <div class="form-row">
          <div class="form-item-title">
            <label for="password">비밀번호</label>
          </div>
          <div class="input-group">
            <input [type]="this.passwordVisible ? 'text' : 'password'" id="password" class="form-control" formControlName="password" placeholder="비밀번호를 입력해 주세요." autocomplete="current-password">
            <div class="input-group-append">
              <button type="button" class="btn btn-outline-secondary" (click)="togglePasswordVisibility()" tabindex="-1">
                <svg *ngIf="!passwordVisible" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM8 13c-3.866 0-7-4-7-5s3.134-5 7-5 7 4 7 5-3.134 5-7 5z"/>
                  <path d="M8 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"/>
                  <path d="M8 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"/>
                </svg>
                <svg *ngIf="passwordVisible" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                  <path d="M13.359 11.238C12.227 12.196 10.665 13 8 13c-3.866 0-7-4-7-5 0-.636.27-1.453.8-2.297"/>
                  <path d="M13.646 12.354a.5.5 0 0 0 .708-.708l-12-12a.5.5 0 0 0-.708.708l2.312 2.312"/>
                </svg>
              </button>
            </div>
          </div>
          <div class="agree-error" *ngIf="rf['password'].invalid && (this.formSubmitted || rf['password'].dirty)">
            <h4 class="agree-error-txt" *ngIf="rf['password'].errors?.['required']">비밀번호를 입력해 주세요.</h4>
            <h4 class="agree-error-txt" *ngIf="rf['password'].errors?.['minlength']">비밀번호가 너무 짧습니다. (8자 이상)</h4>
            <h4 class="agree-error-txt" *ngIf="rf['password'].errors?.['pattern'] && !rf['password'].errors?.['maxlength'] && !rf['password'].errors?.['minlength']">입력하신 비밀번호는 보안에 취약합니다.</h4>
            <h4 class="agree-error-txt" *ngIf="rf['password'].errors?.['maxlength']">비밀번호는 50글자 이내로 입력해 주세요.</h4>
          </div>
        </div>
  
        <!-- 비밀번호 확인 -->
        <div class="form-row">
          <div class="form-item-title">
            <label for="confirm-password">비밀번호 확인</label>
          </div>
          <div class="input-group">
            <input [type]="this.passwordVisible ? 'text' : 'password'" id="confirm-password" class="form-control" formControlName="cpassword" placeholder="확인을 위해 한번 더 입력해 주세요." autocomplete="current-password">
            <div class="input-group-append">
              <button type="button" class="btn btn-outline-secondary" (click)="togglePasswordVisibility()" tabindex="-1">
                <svg *ngIf="!passwordVisible" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM8 13c-3.866 0-7-4-7-5s3.134-5 7-5 7 4 7 5-3.134 5-7 5z"/>
                  <path d="M8 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"/>
                  <path d="M8 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"/>
                </svg>
                <svg *ngIf="passwordVisible" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                  <path d="M13.359 11.238C12.227 12.196 10.665 13 8 13c-3.866 0-7-4-7-5 0-.636.27-1.453.8-2.297"/>
                  <path d="M13.646 12.354a.5.5 0 0 0 .708-.708l-12-12a.5.5 0 0 0-.708.708l2.312 2.312"/>
                </svg>
              </button>
            </div>
          </div>
          <div class="agree-error" *ngIf="rf['cpassword'].invalid">
            <h4 class="agree-error-txt" *ngIf="rf['cpassword'].errors?.['required'] && !rf['cpassword'].errors?.['mustMatch'] && (this.formSubmitted || rf['cpassword'].dirty)">
              비밀번호를 확인해 주세요.</h4>
            <h4 class="agree-error-txt" *ngIf="rf['cpassword'].errors?.['mustMatch'] && !rf['cpassword'].errors?.['maxlength']">비밀번호가 일치하지 않습니다.</h4>
            <h4 class="agree-error-txt" *ngIf="rf['cpassword'].errors?.['maxlength']">비밀번호는 50글자 이내로 입력해 주세요.</h4>
          </div>
        </div>
  
        <!-- 이름 입력 -->
        <div class="form-row">
          <div class="form-item-title">
            <label for="name">이름</label>
          </div>
          <input type="text" id="name" class="form-control" formControlName="fullname" placeholder="한글로 정확히 입력해 주세요." autocomplete="name">
          <div class="agree-error" *ngIf="rf['fullname'].invalid && (this.formSubmitted || rf['fullname'].dirty)">
            <h4 class="agree-error-txt" *ngIf="rf['fullname'].errors?.['required']">이름을 입력해 주세요.</h4>
            <h4 class="agree-error-txt" *ngIf="rf['fullname'].errors?.['maxlength']">이름은 50글자 이내로 입력해 주세요.</h4>
          </div>
        </div>
  
        <!-- 전화번호 입력 -->
        <div class="form-row">
          <div class="form-item-title">
            <label for="cnum1">전화번호 입력</label>
          </div>
          <div class="phone-input">
            <!-- cnum1 -->
            <input type="number" id="cnum1" class="form-control cell-input" formControlName="cnum1" placeholder="000"
              (input)="sanitizeInput($event, 3)" (keydown)="autoMoveToNextField($event, 'cnum2', 3)" (keydown)="autoMoveToPreviousField($event, 'cnum1')">
            <div class="dash"></div>
            
            <!-- cnum2 -->
            <input type="number" id="cnum2" class="form-control cell-input" formControlName="cnum2" placeholder="0000"
              (input)="sanitizeInput($event, 4)" (keydown)="autoMoveToNextField($event, 'cnum3', 4)" (keydown)="autoMoveToPreviousField($event, 'cnum1')">
            <div class="dash"></div>
  
            <!-- cnum3 -->
            <input type="number" id="cnum3" class="form-control cell-input" formControlName="cnum3" placeholder="0000"
              (input)="sanitizeInput($event, 4)" (keydown)="autoMoveToPreviousField($event, 'cnum2')">
          </div>
          <!-- phone number input error messages -->
          <div class="agree-error" *ngIf="!rf['cnum1'].value && !rf['cnum2'].value && !rf['cnum3'].value">
            <h4 class="agree-error-txt" *ngIf="this.formSubmitted || rf['cnum1'].dirty || rf['cnum2'].dirty || rf['cnum3'].dirty">전화번호를 입력해 주세요.</h4>
          </div>
          <div class="agree-error" *ngIf="rf['cnum1'].dirty || rf['cnum2'].dirty || rf['cnum3'].dirty">
            <h4 class="agree-error-txt" *ngIf="
                (rf['cnum1'].invalid || rf['cnum2'].invalid || rf['cnum3'].invalid)
                && (rf['cnum1'].value || rf['cnum2'].value || rf['cnum3'].value)">
              유효한 전화번호를 입력해 주세요.</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-btns">
      <button class="back-next-btn" id="back-btn" (click)="this.gotoRegisterStep(1)">뒤로 가기</button>
      <button class="back-next-btn" id="next-btn" (click)="this.gotoRegisterStep(3)">가입하기</button>
    </div>
  </div>
  <!-- 가입 완료 -->
  <div *ngIf="this.registerStep === 3" class="step-2">
    <div class="register-bye">
      <div class="reg-complete-icon-wrap">
        <img src="../../../../assets/icons/Icon_64px_col-36.png" class="reg-complete-icon">
      </div>
      <div class="reg-complete-title">
        <h2 class="hello reg-complete bold">정상적으로</h2>
        <h2 class="hello reg-complete bold">가입이 완료 되었어요.</h2>
      </div>
      <div class="confirm-btn-wrap">
        <button class="confirm-btn" (click)="this.gotoLogin()">
            확인
        </button>
      </div>
    </div>
  </div> 
</div>
