<header id="headerId" class="kr_header">
  <!-- top menu 삭제예정 04.14-->
  <div class="tmenu">
    <ul>
      <li ngbDropdown class="nav-item dropdown">
        <a ngbDropdownautoClose href="javascript:void(0)" class="">
          <img src="{{ tricon }}" alt="" style="width: 30px" />
        </a>
        <div ngbDropdownMenu class="dropdown-menu-right">
          <a ngbDropdownItem href="javascript:void(0)" (click)="changeLang('en')"> <img src="../../../assets/images/us.png" alt="" style="width: 30px" />영어 </a>
          <a ngbDropdownItem href="javascript:void(0)" (click)="changeLang('kr')"> <img src="../../../assets/images/kr.png" alt="" style="width: 30px" />한국어 </a>
        </div>
      </li>
    </ul>
  </div>
  <!--header nav menu-->
  <nav class="header_nav">
    <ul class="logo_box">
      <a href="javascript:void(0)" routerLink="/kr/home" [routerLinkActive]="['router-link-active']">
        <img src="../../../assets/images/logo_ver3.svg" width="145px" />
        <img src="../../../assets/images/logo_m.png" style="width: 30px; margin-left: 10px" class="logo_m" />
      </a>
    </ul>

    <ul class="category_box">
      <li>
        <a href="javascript:void(0)" routerLink="/kr/tbox_vod" class="list-group-item">T-BOX</a>
      </li>
      <li>
        <a href="javascript:void(0)" routerLink="/kr/vod" class="list-group-item">T-CYCLING</a>
        <!-- <a href="https://www.youtube.com/c/TBoxMedia" target="_blank" rel="noreferrer noopener" class="list-group-item">T-CYCLING</a> -->
      </li>
      <!-- <li>
        <a href="javascript:void(0)" routerLink="/kr/license" class="list-group-item">LICENSE</a>
      </li> -->
      <li>
        <a href="javascript:void(0)" routerLink="/kr/community" class="list-group-item">COMMUNITY</a>
      </li>
    </ul>

    <ul class="menu_box">
      <!-- <li class="mobile-login">
        <a href="javascript:void(0)" routerLink="/kr/subscription" [routerLinkActive]="['router-link-active']" class="subscription_btn">이용권</a>
      </li> -->
      <!--로그인 전-->
      <li *ngIf="!isLogin" class="mobile-login">
        <a href="javascript:void(0)" routerLink="/kr/login" [routerLinkActive]="['router-link-active']" class="login_btn">로그인</a>
      </li>

      <!--로그인 후-->
      <li ngbDropdown placement="bottom-right" class="icon_btn" *ngIf="isLogin">
        <div class="dropdown_icon" ngbDropdownToggle>
          <img src="{{ photo }}" alt="" onerror="this.src='../../../assets/images/defpf.svg'" />
          <!--img 태그에 멀티 프로필 사진 연동-->
        </div>
        <div ngbDropdownMenu class="drop_menu">
          <!-- <div ngbDropdownItem *ngIf="role === '1'" class="dropdown-menu-right">
            <a ngbDropdownItem > <i class="material-icons">extension</i> 관리자모드 </a>
          </div> -->
          <!-- <div ngbDropdownItem *ngIf="role === '4'" class="dropdown-menu-right">
            <a ngbDropdownItem href="javascript:void(0)" routerLink="/kr/club" [routerLinkActive]="['router-link-active']"> <i class="material-icons">extension</i> 관리자모드 </a>
          </div> -->
          <div ngbDropdownItem class="dropdown-menu-right" href="javascript:void(0)" routerLink="/kr/multiprofile" [routerLinkActive]="['router-link-active']">
            <i class="material-icons">swap_horiz</i> 프로필전환
            <!--멀티 프로필 전환 버튼 추가(멀티프로필 가지는 기능 필요)-->
          </div>
          <div ngbDropdownItem class="dropdown-menu-right" href="javascript:void(0)" routerLink="/kr/profile" [routerLinkActive]="['router-link-active']">
            <i class="material-icons">account_box</i> 마이페이지
          </div>
          <div ngbDropdownItem class="dropdown-menu-right" href="javascript:void(0)" (click)="logoutUser()"><i class="material-icons">logout</i> 로그아웃</div>
        </div>
      </li>

      <!--햄버거바 메뉴 토글버튼-->
      <li class="mobile-nav open_btn_kr side_menu_toggle">
        <!-- <i class="material-icons">menu</i> -->
        <span class="t_bar"></span>
        <span class="m_bar"></span>
        <span class="b_bar"></span>
      </li>
    </ul>
  </nav>

  <div class="nav_m_wrap">
    <div class="header_nav_m">
      <div routerLink="/kr/tbox_vod">T-BOX</div>
      <div routerLink="/kr/vod">T-CYCLING</div>
      <div routerLink="/kr/community">COMMUNITY</div>
      <!-- <div routerLink="/kr/store">STORE</div> -->
    </div>
  </div>

  <!--side nav menu -->
  <nav class="sidenav">
    <div class="sideTop_bg">
      <div class="sideTop_bg_overlay">
        <img src="../../../assets/images/tboxfit_logo.svg" alt="" width="100%" class="logo" />
        <img src="../../../assets/images/tboxfit_bg_3.png" alt="" width="100%" class="background" />
      </div>
      <!--       <img src="../../../assets/images/tboxfit_bg_2.png" class="logo-bg" alt="" width="100%" />
      <img src="../../../assets/images/tboxfit_logo.svg" class="logo-fg" alt="" width="100%" /> -->
    </div>
    <div class="mtab" *ngIf="role === '1'">
      <a href="javascript:void(0)" onclick="location.href='api.tboxfit.com/admin/'" [routerLinkActive]="['router-link-active']" class="go_btn close_btn_kr">
        <i class="material-icons">extension</i>관리자모드
      </a>
    </div>
    <div class="mtab" *ngIf="isLogin">
      <a href="javascript:void(0)" (click)="logoutUser()" class="go_btn close_btn_kr"> <img src="../../../assets/icons/Icon_64px_col-42.png" class="icon-sm">로그아웃 </a>
    </div>
    <!-- <div class="mtab">
      <a href="javascript:void(0)" routerLink="/kr/subscription" [routerLinkActive]="['router-link-active']" class="go_btn close_btn_kr"> <i class="material-icons">card_membership</i>이용권 </a>
    </div> -->
    <div class="mtab" *ngIf="!isLogin">
      <a routerLink="/kr/login" [routerLinkActive]="['router-link-active']" class="go_btn close_btn_kr"> <i class="material-icons">person</i>로그인 </a>
    </div>
    <div class="mtab" *ngIf="!isLogin">
      <a href="javascript:void(0)" routerLink="/kr/signup" [routerLinkActive]="['router-link-active']" class="go_btn close_btn_kr"> <i class="material-icons">person_add</i>회원가입 </a>
    </div>

    <div class="mtab">
      <a href="javascript:void(0)" routerLink="/kr/tbox_vod" [routerLinkActive]="['router-link-active']" class="go_btn close_btn_kr"> <img src="../../../assets/icons/Icon_64px_col-30.png" class="icon-sm">T-BOX </a>
    </div>

    <div class="mtab">
      <a href="javascript:void(0)" routerLink="/kr/vod" [routerLinkActive]="['router-link-active']" class="go_btn close_btn_kr"> <img src="../../../assets/icons/Icon_64px_col-41.png" class="icon-sm">T-CYCLING </a>
      <!-- <a href="https://www.youtube.com/c/TBoxMedia" target="_blank" rel="noreferrer noopener" class="go_btn close_btn_kr"> <i class="material-icons">ondemand_video</i>T-CYCLING </a> -->
    </div>
    <!-- <div class="mtab">
      <a href="javascript:void(0)" routerLink="/kr/license" [routerLinkActive]="['router-link-active']" class="go_btn close_btn_kr">
        <i class="material-icons">school</i>LICENSE
      </a>
    </div> -->
    <div class="mtab">
      <a href="javascript:void(0)" routerLink="/kr/community" [routerLinkActive]="['router-link-active']" class="go_btn close_btn_kr"> <img src="../../../assets/icons/Icon_64px_col-43.png" class="icon-sm">COMMUNITY </a>
    </div>
    <!-- <div class="mtab">
      <a href="javascript:void(0)" routerLink="/kr/store" [routerLinkActive]="['router-link-active']" class="go_btn close_btn_kr"> <i class="material-icons">local_grocery_store</i> STORE </a>
    </div> -->
    <div class="mtab">
      <a href="javascript:void(0)" routerLink="/kr/about/0" [routerLinkActive]="['router-link-active']" class="go_btn close_btn_kr"> <img src="../../../../assets/images/logo_m.png" class="icon-sm">플랫폼소개 </a>
    </div>

    <!--영문페이지 완성 후 주석 해제-->
    <!-- <div class="mtab l_btn">
      <a href="javascript:void(0)" onclick="location.href='/kr/home'" class="go_btn close_btn_kr" style="display: flex;align-items: center;">
        <img src="../../../assets/images/kr.png" alt="kr" style="width: 30px; margin: 0;">한국어
      </a>
      <a href="javascript:void(0)" onclick="location.href='/en/home'" class="go_btn close_btn_kr" style="display: flex;align-items: center;">
        <img src="../../../assets/images/us.png" alt="us" style="width: 30px; margin: 0;">English
      </a>
    </div> -->
  </nav>
  <div class="sidenav-bg"></div>
</header>
