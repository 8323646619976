<!--ngx-swiper-wrapper-->
<!-- Check if banners have loaded. (If this skip is checked, the looper won't work) -->

<ng-template #bannersLoading>
  <mat-spinner class="custom-spinner" style="margin-top: 15px; margin-bottom: 15px; left: calc(50% - 25px)"></mat-spinner>
</ng-template>
<div class="main_banner_video">
  <div class="video_center">
    <!-- 티싸이클링 영상
    <iframe
      src="https://player.vimeo.com/video/708477921?&background=1h=56e0cae291&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
      title="main_banner_video"
    ></iframe>
    -->
    <iframe
      src="https://player.vimeo.com/video/708524938?background=1&amp;h=ba5c9d5930&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
      title="웹페이지 영상pc"
    ></iframe>
  </div>
  <div class="video_center_m">
    <!-- 티박스 영상
    <iframe
      src="https://player.vimeo.com/video/708519238?&background=1h=59e069bd54&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
      title="main_banner_video_M"
    ></iframe>
         -->

    <iframe
      src="https://player.vimeo.com/video/708524904?background=1&amp;h=78afe207fe&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
      title="웹페이지 영상mobile"
    ></iframe>
  </div>
</div>

<div *ngIf="loading === false">
  <!--메인 Vod 대표썸네일 0521 수정-->
  <div class="band_banner_wrap">
    <img style="margin-top: 50px" src="../../../../assets/images/main_banner_1.png" alt="pc_version" class="band_nanner band_pc" />
    <img src="../../../../assets/images/main_banner_1_m.png" alt="mobile_version" class="band_nanner band_m" />
  </div>

  <div class="container info_wrap">
    <div class="t_box_ssum">
      <div style="text-align: center; margin-bottom: 7%">
        <img src="../../../../assets/images/t_box_headlogo.png" alt="t-box-cateogry-banner" class="category_head_img" />
        <p class="category_head_txt">티박스핏의 다양한 피트니스</p>
        <p class="category_head_txt2">콘텐츠를 선택하세요!</p>
      </div>
      <div *ngFor="let link of vodLinks">
        <ng-template [ngIf]="link.vod_type === 2">
          <div class="CategoryThumbnail" (click)="goToTboxVod(link.vod_id)">
            <img class="t_box_img" src="../../../../assets/images/T-BOX/{{ link.name }}.png" alt="{{ link.name }}" />
          </div>
        </ng-template>
      </div>
    </div>

    <div class="t_cycling_ssum">
      <div style="text-align: center; margin-bottom: 7%">
        <img src="../../../../assets/images/t_cycling_headlogo2.png" alt="t-cycling-logo" class="category_head_img2" />
        <p class="category_head_txt">당신이 원하는 실내 사이클링</p>
        <p class="category_head_txt2">콘텐츠를 선택하세요!</p>
      </div>
      <div *ngFor="let link of vodLinks">
        <ng-template [ngIf]="link.vod_type === 1">
          <div class="CategoryThumbnail2" (click)="goToVod(link.vod_id)">
            <img class="t_cycling_img" src="../../../../assets/images/T-CYCLING/{{ link.name }}.png" alt="{{ link.name }}" />
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="container_container">
    <div class="content_container">
      <div class="third_banner_wrap">
        <div class="vod_banner_container">
          <div class="large">
            <p>스포츠 에듀테크 융복합</p>
            <p>새로운 헬스케어<br />서비스를 함께하세요!</p>
            <div class="swiper-text">
              <swiper-container class="swiper-container" space-between="30" centered-slides="true" autoplay-delay="3000" autoplay-disable-on-interaction="false" style="background-color: #fff">
                <swiper-slide *ngFor="let t of textSet" class="swiper-slide">
                  <div class="sentance">
                    {{ t.text }}
                  </div>
                </swiper-slide>
              </swiper-container>
            </div>
            <div class="vod">
              <div class="vod_left_box">
                <div class="swiper-text">
                  <swiper-container class="swiper-container" space-between="30" centered-slides="true" autoplay-delay="3000" autoplay-disable-on-interaction="false" style="background-color: #fff">
                    <swiper-slide *ngFor="let i of imgSet" class="swiper-slide" (click)="openDialog()">
                      <img src="{{ i }}" />
                    </swiper-slide>
                  </swiper-container>
                </div>
              </div>
              <div class="vod_right_box">
                <div>
                  <img src="../../../../assets/images/Live/android logo.png" />
                </div>
                <div>
                  <img src="../../../../assets/images/Live/App store.png" />
                </div>
              </div>
            </div>
          </div>
          <div class="small">
            <p>스포츠 에듀테크 융복합</p>
            <p>새로운 헬스케어 서비스를 함께하세요!</p>
            <div class="swiper-text-m">
              <swiper-container class="swiper-container" space-between="30" centered-slides="true" autoplay-delay="3000" autoplay-disable-on-interaction="false" style="background-color: #fff">
                <swiper-slide *ngFor="let t of textSet" class="swiper-slide">
                  <div class="sentance">
                    {{ t.text }}
                  </div>
                </swiper-slide>
              </swiper-container>
            </div>
            <div class="vod">
              <div class="vod_left_box">
                <div class="swiper-text">
                  <swiper-container class="swiper-container" space-between="30" centered-slides="true" autoplay-delay="3000" autoplay-disable-on-interaction="false" style="background-color: #fff">
                    <swiper-slide *ngFor="let i of imgSet" class="swiper-slide" (click)="openDialog()">
                      <img src="{{ i }}" />
                    </swiper-slide>
                  </swiper-container>
                </div>
              </div>
              <div class="vod_right_box">
                <div>
                  <img src="../../../../assets/images/Live/android logo.png" />
                </div>
                <div>
                  <img src="../../../../assets/images/Live/App store.png" />
                </div>
              </div>
            </div>
          </div>
          <div class="link_wrap">
            <div class="link_img_wrap">
              <div>
                <div class="link_image">
                  <a href="https://serviceplan.tboxfit.com" target="blank">
                    <img src="../../../../assets/images/Live/서비스 플랜.png" alt="에듀태크 서비스" />
                  </a>
                </div>
                <div style="padding-top: 5px">서비스 플랜</div>
              </div>
            </div>
            <div class="link_img_wrap">
              <div>
                <div class="link_image">
                  <a href="https://paps.tboxfit.com/" target="blank">
                    <img src="../../../../assets/images/Live/건강체력교실 PAPS.png" alt="paps" />
                  </a>
                </div>
                <div style="padding-top: 5px">건강체력교실_PAPS</div>
              </div>
            </div>
            <div class="link_img_wrap">
              <div>
                <div class="link_image">
                  <a href="https://healthup.tboxfit.com/" target="blank">
                    <img src="../../../../assets/images/Live/헬스업 미션 트레이닝.png" alt="디지털 홈트" />
                  </a>
                </div>
                <div style="padding-top: 5px">미션 트레이닝</div>
              </div>
            </div>
            <div class="link_img_wrap">
              <div>
                <div class="link_image">
                  <a href="https://livinglab.tboxfit.com/" target="blank">
                    <img src="../../../../assets/images/Live/에듀테크 리빙랩.png" alt="스포츠강좌" />
                  </a>
                </div>
                <div style="padding-top: 5px">에듀테크_리빙랩</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--notice-->
  <app-community-notice></app-community-notice>

  <!-- 이벤트 롤링 배너 -->
  <div class="slider_container">
    <div class="swiper-container_wrap" *ngIf="bannersLoaded; else bannersLoading">
      <swiper-container
        class="swiper-container"
        pagination="true"
        pagination-clickable="true"
        navigation="true"
        space-between="30"
        centered-slides="true"
        autoplay-delay="3000"
        autoplay-disable-on-interaction="false"
      >
        <swiper-slide *ngFor="let b of banners" class="swiper-slide">
          <div *ngIf="b.title === 'link'; else notLink">
            <a href="{{ b.content }}">
              <img [src]="b.img" alt="main_banner" class="main_banner" />
            </a>
          </div>
          <ng-template #notLink>
            <div>
              <img [src]="b.img" alt="main_banner" class="main_banner" />
            </div>
          </ng-template>
        </swiper-slide>
      </swiper-container>
    </div>
  </div>
  <div class="sns_banner_wrap">
    <div class="sns_banner_title">
      <span>T-BOX SNS Channel</span>
    </div>
    <div class="sns_icon">
      <a href="https://youtube.com/channel/UCGH-SBrWD0ABVNwjJnQVA-w" target="_blank">
        <img src="../../../../assets/images/social_icon/youtube.svg" alt="youtube" title="youtube" />
        <p>YouTube</p>
      </a>
      <a href="https://blog.naver.com/tboxmedia" target="_blank">
        <img src="../../../../assets/images/social_icon/icon_blog.png" alt="naver_blog" title="naver_blog" />
        <p>Naver Blog</p>
      </a>
      <a href="https://www.instagram.com/tboxfit/" target="_blank">
        <img src="../../../../assets/images/social_icon/instagram.png" alt="instagram" title="instagram" />
        <p>Instagram</p>
      </a>
      <a href="http://pf.kakao.com/_wFxjUb" target="_blank">
        <img src="../../../../assets/images/social_icon/kakao-talk 1.png" alt="kakaotalk" title="kakaotalk" />
        <p>kakaoTalk</p>
      </a>
      <div class="QR_box">
        <div class="QR_inner">
          <div class="front_box">
            <img src="../../../../assets/images/social_icon/QR_logo.svg" />
            <p>Download App</p>
          </div>
          <div class="back_box">
            <img src="../../../../assets/images/social_icon/티박스 앱 연결 QR4.png" />
          </div>
        </div>
      </div>
      <a href="" target="_blank">
        <img src="../../../../assets/icons/Icon_64px_col-113.png" alt="schoolAdmin" title="schoolAdmin" />
        <p>School Admin</p>
      </a>
    </div>
  </div>

  <div class="logo_slide">
    <div class="sns_banner_title">
      <span>T-BOX Family Sites</span>
    </div>
    <div class="loop_logo_slider">
      <ul class="looping-list">
        <li>
          <a href="https://www.startuppark.kr/user/main.do" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide6.png" id="isp" alt="인천스타트업파크" title="인천스타트업파크" />
          </a>
        </li>
        <li>
          <a href="https://knue.ac.kr/smain.html" target="_blank">
            <img src="../../../../assets/images/social_icon/kyowon-logo.png" id="knue" alt="교원대학교" title="교원대학교" />
          </a>
        </li>
        <li>
          <a href="https://www.moe.go.kr/main.do?s=moe" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide10.svg" id="moe" alt="교육부" title="교육부" />
          </a>
        </li>
        <li>
          <a href="https://www.ice.go.kr/ice/main.do" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide11.svg" id="ice" alt="인천교육청" title="인천교육청" />
          </a>
        </li>
        <li>
          <a href="https://www.cbe.go.kr/cbe/main.do" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide13.webp" id="cbe" alt="충북교육청" title="충북교육청" />
          </a>
        </li>
        <li>
          <a href="https://www.dge.go.kr/main/main.do" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide12.svg" id="dge" alt="대구교육청" title="대구교육청" />
          </a>
        </li>
        <li>
          <a href="https://www.knsu.ac.kr/knsu/index.do" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide5.png" id="knsu" alt="한체대" title="한체대" />
          </a>
        </li>
        <li>
          <a href="https://www.kocca.kr/kocca/main.do" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide14.svg" id="kocca" alt="한국콘텐츠진흥원" title="한국콘텐츠진흥원" />
          </a>
        </li>
        <li>
          <a href="https://www.mss.go.kr/site/smba/main.do" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide.png" id="smba" alt="중소벤처기업부" title="중소벤처기업부" />
          </a>
        </li>
        <li>
          <a href="https://www.mcst.go.kr/kor/main.jsp" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide2.png" id="mcst" alt="문화체육관광부" title="문화체육관광부" />
          </a>
        </li>
        <li>
          <a href="https://www.msit.go.kr/index.do" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide3.png" id="msit" alt="과학기술정보통신부" title="과학기술정보통신부" />
          </a>
        </li>
        <li>
          <a href="https://www.kspo.or.kr/kspo/main/main.do" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide9.png" id="kspo" alt="국민체육진흥공단" title="국민체육진흥공단" />
          </a>
        </li>
        <li>
          <a href="https://rockthebilly.com/" target="_blank">
            <img src="../../../../assets/images/social_icon/logo_slide9.png" id="rtb" alt="락더빌리" title="락더빌리" />
          </a>
        </li>
        <!-- duplicate li elements for seamless animation -->
        <li>
          <a href="https://www.startuppark.kr/user/main.do" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide6.png" id="isp" alt="인천스타트업파크" title="인천스타트업파크" />
          </a>
        </li>
        <li>
          <a href="https://knue.ac.kr/smain.html" target="_blank">
            <img src="../../../../assets/images/social_icon/kyowon-logo.png" id="knue" alt="교원대학교" title="교원대학교" />
          </a>
        </li>
        <li>
          <a href="https://www.moe.go.kr/main.do?s=moe" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide10.svg" id="moe" alt="교육부" title="교육부" />
          </a>
        </li>
        <li>
          <a href="https://www.ice.go.kr/ice/main.do" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide11.svg" id="ice" alt="인천교육청" title="인천교육청" />
          </a>
        </li>
        <li>
          <a href="https://www.cbe.go.kr/cbe/main.do" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide13.webp" id="cbe" alt="충북교육청" title="충북교육청" />
          </a>
        </li>
        <li>
          <a href="https://www.dge.go.kr/main/main.do" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide12.svg" id="dge" alt="대구교육청" title="대구교육청" />
          </a>
        </li>
        <li>
          <a href="https://www.knsu.ac.kr/knsu/index.do" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide5.png" id="knsu" alt="한체대" title="한체대" />
          </a>
        </li>
        <li>
          <a href="https://www.kocca.kr/kocca/main.do" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide14.svg" id="kocca" alt="한국콘텐츠진흥원" title="한국콘텐츠진흥원" />
          </a>
        </li>
        <li>
          <a href="https://www.mss.go.kr/site/smba/main.do" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide.png" id="smba" alt="중소벤처기업부" title="중소벤처기업부" />
          </a>
        </li>
        <li>
          <a href="https://www.mcst.go.kr/kor/main.jsp" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide2.png" id="mcst" alt="문화체육관광부" title="문화체육관광부" />
          </a>
        </li>
        <li>
          <a href="https://www.msit.go.kr/index.do" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide3.png" id="msit" alt="과학기술정보통신부" title="과학기술정보통신부" />
          </a>
        </li>
        <li>
          <a href="https://www.kspo.or.kr/kspo/main/main.do" target="_blank">
            <img src="../../../../assets/images/Live/social_icon/g-logo_slide9.png" id="kspo" alt="국민체육진흥공단" title="국민체육진흥공단" />
          </a>
        </li>
        <li>
          <a href="https://rockthebilly.com/" target="_blank">
            <img src="../../../../assets/images/social_icon/logo_slide9.png" id="rtb" alt="락더빌리" title="락더빌리" />
          </a>
        </li>
      </ul>
    </div>
  </div>
  
  <div class="body_bg"></div>
</div>
