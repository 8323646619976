<div class="wrap">
  <div class="container">
    <div class="row">
      <div class="admin-sub-menu">
        <ul>
          <a href="/kr/admin/main">메인</a>
          <a href="/kr/admin/missions">미션</a>
          <a href="/kr/admin/personnel">인원</a>
        </ul>
      </div>

      <div class="mypage-header">
          <h3>마이 페이지</h3>
          <p>회원님의 각종 정보를 확인 및 수정이 가능한 페이지입니다.</p>
      </div>
  </div>
</div>
  <div class="logo_slide">
  <div class="sns_banner_title">
    <span>T-BOX Family Channel</span>
  </div>
  <div class="loop_logo_slider">
    <ul class="first-ul">
      <li>
        <a href="https://www.mss.go.kr/site/smba/main.do" target="_blank">
          <img src="../../../../assets/images/Live/social_icon/g-logo_slide.png" alt="중소벤처기업부" title="중소벤처기업부" />
        </a>
      </li>
      <li>
        <a href="https://www.mcst.go.kr/kor/main.jsp" target="_blank">
          <img src="../../../../assets/images/Live/social_icon/g-logo_slide2.png" alt="문화체육관광부" title="문화체육관광부" />
        </a>
      </li>
      <li>
        <a href="https://www.msit.go.kr/index.do" target="_blank">
          <img src="../../../../assets/images/Live/social_icon/g-logo_slide3.png" alt="과학기술정보통신부" title="과학기술정보통신부" />
        </a>
      </li>
      <li>
        <a href="https://www.knsu.ac.kr/knsu/index.do" target="_blank">
          <img src="../../../../assets/images/Live/social_icon/g-logo_slide5.png" alt="한체대" title="한체대" />
        </a>
      </li>
      <li>
        <a href="https://www.startuppark.kr/user/main.do" target="_blank">
          <img src="../../../../assets/images/Live/social_icon/g-logo_slide6.png" alt="인천스타트업파크" title="인천스타트업파크" />
        </a>
      </li>
      <li>
        <a href="https://www.inu.ac.kr/mbshome/mbs/inu/index.do" target="_blank">
          <img src="../../../../assets/images/Live/social_icon/g-logo_slide7.png" alt="인천대학교" title="인천대학교" />
        </a>
      </li>
      <li>
        <a href="https://admission.eulji.ac.kr/" target="_blank">
          <img src="../../../../assets/images/Live/social_icon/g-logo_slide8.png" alt="을지대학교" title="을지대학교" />
        </a>
      </li>
      <li>
        <a href="https://www.kspo.or.kr/kspo/main/main.do" target="_blank">
          <img src="../../../../assets/images/Live/social_icon/g-logo_slide9.png" alt="국민체육진흥공단" title="국민체육진흥공단" />
        </a>
      </li>
      <li>
        <a href="https://rockthebilly.com/" target="_blank">
          <img src="../../../../assets/images/social_icon/logo_slide9.png" alt="락더빌리" title="락더빌리" />
        </a>
      </li>
      <!-- <li>
        <a href="https://www.thebike.co.kr/main/" target="_blank">
          <img src="../../../../assets/images/social_icon/logo_slide10.png" alt="더바이크" title="더바이크" />
        </a>
      </li> -->
      <li>
        <a href="https://www.kibo.or.kr/main/index.do" target="_blank">
          <img src="../../../../assets/images/social_icon/logo_slide11.png" alt="기술보증기금" title="기술보증기금" />
        </a>
      </li>
      <li>
        <a href="https://knue.ac.kr/smain.html" target="_blank">
          <img src="../../../../assets/images/social_icon/kyowon-logo.png" alt="KYL 법률사무소" title="KYL 법률사무소" />
        </a>
      </li>
    </ul>
    <ul class="last-ul">
      <li>
        <a href="https://www.mss.go.kr/site/smba/main.do" target="_blank">
          <img src="../../../../assets/images/Live/social_icon/g-logo_slide.png" alt="중소벤처기업부" title="중소벤처기업부" />
        </a>
      </li>
      <li>
        <a href="https://www.mcst.go.kr/kor/main.jsp" target="_blank">
          <img src="../../../../assets/images/Live/social_icon/g-logo_slide2.png" alt="문화체육관광부" title="문화체육관광부" />
        </a>
      </li>
      <li>
        <a href="https://www.msit.go.kr/index.do" target="_blank">
          <img src="../../../../assets/images/Live/social_icon/g-logo_slide3.png" alt="과학기술정보통신부" title="과학기술정보통신부" />
        </a>
      </li>
      <li>
        <a href="hhttps://www.knsu.ac.kr/knsu/index.do" target="_blank">
          <img src="../../../../assets/images/Live/social_icon/g-logo_slide5.png" alt="한체대" title="한체대" />
        </a>
      </li>
      <li>
        <a href="https://www.startuppark.kr/user/main.do" target="_blank">
          <img src="../../../../assets/images/Live/social_icon/g-logo_slide6.png" alt="인천스타트업파크" title="인천스타트업파크" />
        </a>
      </li>
      <li>
        <a href="https://www.inu.ac.kr/mbshome/mbs/inu/index.do" target="_blank">
          <img src="../../../../assets/images/Live/social_icon/g-logo_slide7.png" alt="인천대학교" title="인천대학교" />
        </a>
      </li>
      <li>
        <a href="https://admission.eulji.ac.kr/" target="_blank">
          <img src="../../../../assets/images/Live/social_icon/g-logo_slide8.png" alt="을지대학교" title="을지대학교" />
        </a>
      </li>
      <li>
        <a href="https://www.kspo.or.kr/kspo/main/main.do" target="_blank">
          <img src="../../../../assets/images/Live/social_icon/g-logo_slide9.png" alt="국민체육진흥공단" title="국민체육진흥공단" />
        </a>
      </li>
      <li>
        <a href="https://rockthebilly.com/" target="_blank">
          <img src="../../../../assets/images/social_icon/logo_slide9.png" alt="락더빌리" title="락더빌리" />
        </a>
      </li>
      <li>
        <a href="https://www.kibo.or.kr/main/index.do" target="_blank">
          <img src="../../../../assets/images/social_icon/logo_slide11.png" alt="기술보증기금" title="기술보증기금" />
        </a>
      </li>
      <li>
        <a href="https://knue.ac.kr/smain.html" target="_blank">
          <img src="../../../../assets/images/social_icon/kyowon-logo.png" alt="KYL 법률사무소" title="KYL 법률사무소" />
        </a>
      </li>
    </ul>
  </div>
  </div>
</div>
<div class="body_bg"></div>
