import { Component, ElementRef, OnDestroy, OnInit, AfterViewInit, ViewChild, HostListener, Input, OnChanges, SimpleChanges } from "@angular/core";
import { MatTabGroup } from "@angular/material/tabs";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.css"]
})
export class AdminComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHandler(event) {
    
  }
  @ViewChild('tabs', { static: false }) tabGroup: MatTabGroup;

  @ViewChild('myVideo', { static: false }) set content(content: ElementRef) {

  }
  
  constructor(
    public route: ActivatedRoute, 
    private sanitizer: DomSanitizer, 
    private location: Location, 
    public router: Router) {}


  async ngOnInit() {

  }
  
  ngAfterViewInit() {
    
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngOnDestroy() {

  }
}

// 썸네일 눌렀을 때 스크롤 탑
$(document).ready(function () {
  $(document).on("click", ".thumbnail_box", function () {
    $("html, body").animate({
      scrollTop: 0
    });
  });
});
