import { Component, ElementRef, OnDestroy, OnInit, AfterViewInit, ViewChild, ViewChildren, HostListener, Input, OnChanges, SimpleChanges, QueryList } from "@angular/core";
import { MatTabGroup } from "@angular/material/tabs";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { firstValueFrom } from "rxjs";
import { s3Url } from "src/app/shared";
import { VodService } from "src/app/shared/services/vod.service";
import { Vod, VodListCategory, VodCategory } from "../../../shared/models/vod";
import Player from '@vimeo/player';

@Component({
  selector: "app-vod",
  templateUrl: "./vod.component.html",
  styleUrls: ["./vod.component.css", "./player.css"]
})
export class VodComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHandler(event) {
    this.addVodView();
  }
  @ViewChild('tabs', { static: false }) tabGroup: MatTabGroup;
  
  private videoElement: ElementRef;
  @ViewChild('myVideo', { static: false }) set content(content: ElementRef) {
    if (content) {
      this.videoElement = content;
      if (this.open && this.playSource) {
        this.initializeVideoPlayer();
      }
    }
  }

  private themVodElements: QueryList<ElementRef>;
  @ViewChildren('themVod') set thumbContent(thumbContent: QueryList<ElementRef>) {
    if (thumbContent) {
      this.themVodElements = thumbContent;
      if (!this.loading && this.vodListCategory.vod_list.length > 0) {
        this.initializeThumbnailPlayers();
      }
    }
  }

  private searchInputEl: ElementRef;
  @ViewChild('searchInput', { static: false }) set searchInput(searchInput: ElementRef) {
    this.searchInputEl = searchInput;
  }

  private resetBtnEl: ElementRef;
  @ViewChild('resetBtn', { static: false }) set resetBtn(resetBtn: ElementRef) {
    this.resetBtnEl = resetBtn;
  }

  @Input() open: boolean;
  @Input() playSource: string;
  videoPlayer: any;
  selectedCategoryName: string = '';
  
  constructor(
    private vodService: VodService, 
    public route: ActivatedRoute, 
    private sanitizer: DomSanitizer, 
    private location: Location, 
    public router: Router) {}

  vodCategoryList: VodCategory[] = [];
  originalVodList: { id: number, title: string, thumbnail: string, preview: string }[] = [];
  vodListCategory: { id: number; name: string; vod_list: { id: number, title: string, thumbnail: string, preview: string }[] } = {
    id: 0,
    name: '',
    vod_list: []
  }; 
  selectedVod: Vod = {
    id: 0,
    title: "",
    category_id: 0,
    content: "",
    content_vod: "",
    preview: "",
    thumbnail: "",
    level: 0,
    gangsa: "",
    vod_min: 0,
    favorite: false,
    save_time: 0
  };

  loading = true;
  loadingFirst = true;
  continue = false;
  userView = false;

  canPlayEvent = false;

  page = 1;
  pageSize = 9;

  totalPlayTime = 0;
  startTime: number;

  // boolean to differentiate between /vod and /tbox_vod endpoints
  isTboxVod: boolean;

  // boolean to indicate if Vimeo player script has been loaded
  vimeoScriptLoaded: boolean = false;

  players: Player[] = [];

  async ngOnInit() {
    this.isTboxVod = this.router.url.includes("tbox_vod");
    const categoryId = this.isTboxVod ? 2 : 1;
    this.vodCategoryList = await firstValueFrom(this.vodService.getVodCategoryList(categoryId));

    if (this.route.snapshot.paramMap.get("vodId") == undefined) {
      this.vodListCategory = await firstValueFrom(this.vodService.getVodListCategory(this.vodCategoryList[0].id));
      this.originalVodList = [...this.vodListCategory.vod_list];
      this.selectedCategoryName = this.vodCategoryList[0].name;
    } else {
      const vodId = Number(this.route.snapshot.paramMap.get("vodId"));

      if (JSON.parse(localStorage.getItem("profile")) == undefined) {
        this.selectedVod = await firstValueFrom(this.vodService.getVodData(vodId));
      } else {
        this.selectedVod = await firstValueFrom(this.vodService.getVodData(vodId, +JSON.parse(localStorage.getItem("profile")).id));
      }

      this.selectedVod.thumbnail = s3Url + this.selectedVod.thumbnail;

      this.tabGroup.selectedIndex = this.vodCategoryList.findIndex(data => data.id == this.selectedVod.category_id);
      this.selectedCategoryName = this.vodCategoryList[this.tabGroup.selectedIndex].name;

      this.vodListCategory = await firstValueFrom(this.vodService.getVodListCategory(this.selectedVod.category_id));
      this.originalVodList = [...this.vodListCategory.vod_list];

      this.checkRoute();

      this.initVod();

      // call initializeThumbnailPlayers on component initialization
      this.loadVimeoScript().then(() => {
        this.initializeThumbnailPlayers();
      });

      if (this.open && this.videoElement) {
        this.initializeVideoPlayer();
      }
    }

    this.vodListCategory.vod_list.forEach(data => {
      data.thumbnail = s3Url + data.thumbnail;
    });

    this.loading = false;
    
    // simulate user interaction to enable autoplay for all thumbnails
    this.simulateUserInteraction();
  }
  
  /**
   * method to check if the current page is a video page (/kr/[0-9]+).
   * if true, set this.open to true and initialize VOD content and player.
   */
  checkRoute() {
    const vodIdPattern = /^\/(kr\/)(vod|tbox_vod)\/\d+$/; 
    const currentUrl = this.router.url;
    if (vodIdPattern.test(currentUrl)) {
      this.open = true;
      this.initVod();
    } else {
      this.open = false;
    }
  }
  
  playVideo(index: number) {
    const player = this.players[index];
    if (player) {
      player.play();
    }
  }
    
  pauseVideo(index: number) {
    const player = this.players[index];
    if (player) {
      player.pause().then(() => {
        player.setCurrentTime(0);
      });
    }
  }
  
  simulateUserInteraction() {
    // use a timeout to ensure it runs after the DOM is fully loaded
    setTimeout(() => {
      const clickEvent = new MouseEvent('click', {
        view: window, 
        bubbles: true,
        cancelable: true
      });
      document.body.dispatchEvent(clickEvent);
    }, 500);
  }
  
  ngAfterViewInit() {
    // simulate user interaction for thumbnail autoplay
    setTimeout(() => this.simulateUserInteraction(), 100);

    this.checkRoute();

    if (!this.vimeoScriptLoaded) {
      this.loadVimeoScript();
    }

    if (this.open && this.videoElement) {
      this.initializeVideoPlayer();
    }

    if (!this.loading) {
      this.initializeThumbnailPlayers();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.open && this.open && this.videoElement && this.videoElement.nativeElement) {
      this.initVod();
    }

    if (changes.playSource && this.videoPlayer) {
      this.updateVideoSource();
    }
  }

  ngOnDestroy() {
    if (this.videoPlayer) {
      this.videoPlayer.destroy();
    }
  }

  public async changeSelectedCategory(e) {
    this.selectedCategoryName = this.vodCategoryList[e.index].name;
    this.resetVodList();    

    if (this.loadingFirst) {
      this.loadingFirst = false;
    } else {
      this.open = false;
      this.loading = true;

      const categoryId = this.vodCategoryList[e.index].id;

      this.vodListCategory = await firstValueFrom(this.vodService.getVodListCategory(categoryId));

      this.originalVodList = [...this.vodListCategory.vod_list];

      this.vodListCategory.vod_list.forEach(data => {
        data.thumbnail = s3Url + data.thumbnail;
      });

      this.loading = false;

      this.initializeThumbnailPlayers();
    }
  }

  public async changeSelectedContent(e) {
    this.searchResultsNotFound = false;
    if (e.id > 0) {
      this.open = false;

      if (JSON.parse(localStorage.getItem("profile")) == undefined) {
        this.selectedVod = await firstValueFrom(this.vodService.getVodData(e.id));
      } else {
        this.selectedVod = await firstValueFrom(this.vodService.getVodData(e.id, +JSON.parse(localStorage.getItem("profile")).id));
      }

      this.selectedVod.thumbnail = s3Url + this.selectedVod.thumbnail;

      const endpoint = this.isTboxVod ? "kr/tbox_vod/" : "kr/vod/";

      this.location.go(endpoint + this.selectedVod.id);

      this.open = true;

      this.initVod();
    }
  }

  initVod() {
    this.totalPlayTime = 0;
    const regex = /\/(\d{9})([/.])/;

    if (this.selectedVod.content_vod.length) {
      this.playSource = this.selectedVod.content_vod;
      // extract 9-digit numerical Vimeo video ID from URL
      const match = this.playSource.match(regex);
      this.playSource = match ? ('https://player.vimeo.com/video/' + match[1]) : this.playSource;
      this.userView = true;
    } else {
      const match = this.selectedVod.preview.match(regex);
      this.playSource = match ? ('https://player.vimeo.com/video/' + match[1]) : this.playSource;
    }
  }

  loadVimeoScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if ((window as any).Vimeo) {
        resolve();
      } else { 
        const script = document.createElement('script');
        script.src = 'https://player.vimeo.com/api/player.js';
        script.async = true;
        script.onload = () => {
          this.vimeoScriptLoaded = true;
          resolve(); 
        };
        script.onerror = () => reject(new Error('failed to load Vimeo script.'));
        document.head.appendChild(script);
      }
    });
  }

  initializeThumbnailPlayers() { 
    if (!this.vimeoScriptLoaded) {
      this.loadVimeoScript();
    }

    if (!this.loading) {
      const thumbnailElements = this.themVodElements.toArray();

      // convert thumbnail preview links into Vimeo player-compatible links by extracting 9-digit numerical Vimeo ID
      const regex = /\/(\d{9})([/.])/;
      this.vodListCategory.vod_list.forEach((vod) => {
        const match = vod.preview.match(regex);
        vod.preview =  match ? ('https://player.vimeo.com/video/' + match[1]) : vod.preview;
      }) 

      thumbnailElements.forEach((playerElement: ElementRef, index: number) => {
        const previewUrl = this.vodListCategory.vod_list[index]?.preview;
        // console.log(`preview ${index}: ${previewUrl}`);
        
        if (playerElement) {
          // initialize Vimeo thumbnail player  
          const options = {
            url: previewUrl,
            width: '100%',
            height: '100%',
            controls: false, // hide controls for thumbnails
            muted: true, // mute thumbnail videos
          }

          const vimeoPlayer = new (window as any).Vimeo.Player(playerElement.nativeElement, options); 

          this.players[index] = vimeoPlayer;
        }
      });
    }
  }

  initializeVideoPlayer() {
    if (this.videoPlayer) {
        this.videoPlayer.destroy();
    }

    if (!this.vimeoScriptLoaded) {
      this.loadVimeoScript();
    }

    console.log('initializing video player with source:', this.playSource);
    
    if (this.open && !this.loading && this.videoElement) {
      const options = {
        url: this.playSource,
        width: '100%',
        height: '100%'
      };

      this.videoPlayer = new (window as any).Vimeo.Player(this.videoElement.nativeElement, options);

      this.videoPlayer.on('ended', () => this.ended());
      this.videoPlayer.on('pause', () => this.pauseEvent());
      this.videoPlayer.on('play', () => this.playEvent());
    }
  }

  searchPerformed: boolean = false;
  searchResultsNotFound: boolean = false;

  /**
   * function to search for VOD content by both name and content fields
   * @param query input string
   */
  searchVod(query: string) {
    if (this.searchInputEl.nativeElement.value === '') {
      return;
    }

    this.loading = true;
    const lowerQuery = query.toLowerCase();
    this.vodListCategory.vod_list = this.originalVodList.filter(vod => {
      return vod.title.toLowerCase().includes(lowerQuery);
    });

    console.log(this.originalVodList.map(vod => vod.title));
    console.log(this.vodListCategory.vod_list.map(vod => vod.title));
    console.log(this.vodListCategory.vod_list.length);

    if (this.vodListCategory.vod_list.length === 0) {
      this.loading = false;
      this.searchResultsNotFound = true;
      this.searchPerformed = true;
      return;
    }

    this.vodListCategory.vod_list.forEach(data => {
      data.thumbnail = s3Url + data.thumbnail;
    })

    this.loading = false;
    this.searchPerformed = true;
    this.initializeThumbnailPlayers();
  }

  /**
   * function to render / hide the 'X' reset button if the input field is non-empty / empty
   */
  onInputChange() {
    this.resetBtnEl.nativeElement.style.visibility = this.searchInputEl.nativeElement.value.length > 0 ? 'visible' : 'hidden';
  }

  /**
   * function to reset the thumbnail view to defaults when the reset button is pressed
   */
  resetVodList() { 
    this.resetSearchInput();

    if (!this.searchPerformed) {
      return;
    }  

    this.loading = true;
    this.vodListCategory.vod_list = [...this.originalVodList];
    this.vodListCategory.vod_list.forEach(data => {
      data.thumbnail = s3Url + data.thumbnail;
    });
    this.loading = false;
    this.searchPerformed = false;
    this.initializeThumbnailPlayers();
  }

  resetSearchInput() {
    if (this.searchResultsNotFound) {
      this.searchResultsNotFound = false;
    }
    
    if (this.searchInputEl.nativeElement.value.length > 0) {
      this.searchInputEl.nativeElement.value = '';
    }

    if (this.resetBtnEl.nativeElement.style.visibility === 'visible') {
      this.resetBtnEl.nativeElement.style.visibility = 'hidden';
    }
  }

  ended() {
    this.addVodView();
    if (this.continue) {
      const vodIndex = this.vodListCategory.vod_list.findIndex(data => data.id === this.selectedVod.id);
      if (vodIndex !== -1 && vodIndex < this.vodListCategory.vod_list.length - 1) {
        const nextVod = this.vodListCategory.vod_list[vodIndex + 1];
        this.changeSelectedContent(nextVod);
      } else {
        console.warn('Next video not found or at the end of the list');
      }
    }
  }

  toggleFavorite(vodId: number, mark: boolean) {
    if (!localStorage.getItem("token")) {
      alert("로그인이 필요합니다");
    } else {
      if (mark) {
        this.vodService.removeVodFavorite(+JSON.parse(localStorage.getItem("profile"))?.id, +vodId).subscribe({
          complete: () => {
            this.selectedVod.favorite = false;
          }
        });
      } else {
        this.vodService.addVodFavorite(+JSON.parse(localStorage.getItem("profile"))?.id, +vodId).subscribe({
          complete: () => {
            this.selectedVod.favorite = true;
          }
        });
      }
    }
  }

  pauseEvent() {
    let finishTime = Date.now();
    this.totalPlayTime += Number((finishTime - this.startTime) / 1000);
  }
  playEvent() {
    this.startTime = Date.now();
  }
  
  addVodView() {
    if (this.userView) {
      const video = this.videoElement?.nativeElement as HTMLVideoElement;
      
      if (video) {
        this.pauseEvent();
        if (JSON.parse(localStorage.getItem("profile")) != undefined && this.totalPlayTime > 0) {
          this.vodService.addVodView(+JSON.parse(localStorage.getItem("profile"))?.id, this.selectedVod.id, video.currentTime);
          this.vodService.addVodViewLog(+JSON.parse(localStorage.getItem("profile"))?.id, this.selectedVod.id, this.totalPlayTime);
        }
      } else {
        console.warn('videoElement is not available for addVodView');
      }
    }
  }

  updateVideoSource() {
    this.videoPlayer.src({
      src: this.playSource
    });
  }

  // disable video player right click to prevent unauthorized downloads
  disableContextMenu(event: MouseEvent) {
    event.preventDefault();
  }
}

// 썸네일 눌렀을 때 스크롤 탑
$(document).on("click", ".thumbnail_box", function () {
  $("html, body").animate({
    scrollTop: 0
  });
});
