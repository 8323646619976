<!--only body page-->
<div *ngIf="isLive === false && lang === 'kr'">
  <!-- <div *ngIf="lang==='kr' && subDir==='home'">
  <app-header></app-header>
</div>
<div *ngIf="lang==='kr' && subDir!=='home'"> -->
  <app-header></app-header>
</div>
<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-M2LKRG5P"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
<div *ngIf="isLive === false && lang === 'en'">
  <app-en-header></app-en-header>
</div>
<div class="live-wrap">
  <div class="live" *ngIf="isLive === true">
    <app-live-header></app-live-header>
  </div>
</div>

<!-- <app-sidebar (collapsedEvent)="receiveCollapsed($event)"></app-sidebar> -->
<section class="main-container" [ngClass]="{ collapsed: collapedSideBar, liveBody: isLive }">
  <router-outlet></router-outlet>
  <!-- <app-body></app-body> -->
</section>
<div class="footer_wrap" style="background-color: #212935">
  <div class="footer" style="background-color: #fec40d" *ngIf="isLive === false && lang === 'kr'">
    <app-footer></app-footer>
  </div>
</div>
<div class="live-footer_wrap" style="background-color: #363636">
  <div class="live-footer" *ngIf="isLive === true">
    <app-live-footer></app-live-footer>
  </div>
</div>
<div *ngIf="isLive === false && lang === 'en'">
  <app-en-footer></app-en-footer>
</div>

<!--스크롤 컨트롤 박스-->
<div class="scrControl">
  <div class="scrTop"><i class="material-icons">first_page</i></div>
  <div class="scrBottom"><i class="material-icons">last_page</i></div>
</div>
