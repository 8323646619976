import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpEventType, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { retry } from "rxjs/operators";
import { environment } from "src/environments/environment";
// import { TBM_ROLE_NONE } from 'src/app/shared';

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: JSON.parse(localStorage.getItem("token"))?.access || "",
    "Content-Type": "application/json"
  })
};

const httpOptionsFormData = {
  headers: new HttpHeaders({
    Authorization: JSON.parse(localStorage.getItem("token"))?.access || ""
  })
};
const httpOptionsNoToken = {
  headers: new HttpHeaders({
    "Content-Type": "application/json"
  })
};
// const httpOptionsNaver = {
//   headers: new HttpHeaders({
//     // 'Content-Type': 'application/json',
//     "Content-Type": "application/x-www-form-urlencoded",
//     "Accept": "*",
//     'Access-Control-Allow-Origin': '*',
//     // 'Origin': 'https://www.tbox.media',
//     // clientid + clientsecret
//     'Authorization': 'Bearer ' + btoa('hgrL4Ti6ZSkgqIgMQUds' + ':' + 'IFegZZUKH7')

//   })
// };
// {
//   "web": {
//     "client_id": "797947276504-70q69b4022n1agn35uiaipvc5g92p6lk.apps.googleusercontent.com",
//     "project_id": "tboxmedia",
//     "auth_uri": "https://accounts.google.com/o/oauth2/auth",
//     "token_uri": "https://oauth2.googleapis.com/token",
//     "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
//     "client_secret": "zdQdgjHcukMUJhpOW389meFm",
//     "redirect_uris": [ "https://www.tbox.media/oauth/google" ],
//     "javascript_origins": [ "https://www.tbox.media" ]
//   }
// }
const httpOptionsGoogle = {
  headers: new HttpHeaders({
    // 'Content-Type': 'application/json',
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "*",
    "Access-Control-Allow-Origin": "*",
    // 'Origin': 'https://www.tbox.media',
    // clientid + clientsecret
    Authorization: "Bearer " + btoa("hgrL4Ti6ZSkgqIgMQUds" + ":" + "IFegZZUKH7")
  })
};
const httpOptionsNaver = {
  headers: new HttpHeaders({
    // 'Content-Type': 'application/json',
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "*",
    "Access-Control-Allow-Origin": "*",
    // 'Origin': 'https://www.tbox.media',
    // clientid + clientsecret
    Authorization: "Bearer " + btoa("hgrL4Ti6ZSkgqIgMQUds" + ":" + "IFegZZUKH7")
  })
};
// {
//   "web": {
//     "client_id": "797947276504-70q69b4022n1agn35uiaipvc5g92p6lk.apps.googleusercontent.com",
//     "project_id": "tboxmedia",
//     "auth_uri": "https://accounts.google.com/o/oauth2/auth",
//     "token_uri": "https://oauth2.googleapis.com/token",
//     "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
//     "client_secret": "zdQdgjHcukMUJhpOW389meFm",
//     "redirect_uris": [ "https://www.tbox.media/oauth/google" ],
//     "javascript_origins": [ "https://www.tbox.media" ]
//   }
// }
// const httpOptionsImage = {
//   headers: new HttpHeaders({
//     'Authorization': 'Token ' + localStorage.getItem('token'),
//     'Content-Type': 'image/jpeg'
//   })
// };
// const httpOptionsBlob = {
//   headers: new HttpHeaders({
//     'Content-Type': 'application/json',
//     'Accept': 'application/json'
//   })
// };

@Injectable({
  providedIn: "root"
})
export class KrUserService {
  tboxUrl = environment.apiUrl + "/kr/";

  // https://developers.naver.com/docs/login/api/
  naverAuthUrl = "https://nid.naver.com/oauth2.0/authorize";
  naverTokenUrl = "https://nid.naver.com/oauth2.0/token";
  naverClientID = "hgrL4Ti6ZSkgqIgMQUds";
  naverClientSecret = "IFegZZUKH7";

  kakaoUrl = "";
  googleUrl = "";
  facebookUrl = "";

  kakaoApiKey = "d3a7428c725f04664e3f2cc107192a6a";

  constructor(private http: HttpClient) {}

  getUsers(): Observable<any> {
    return this.http.get(this.tboxUrl + "users/", httpOptionsNoToken);
  }

  signupUser(data, youtubeSubImg): Observable<any> {
    const body = new FormData();
    body.append("fullname", data.value.fullname);
    body.append("email", data.value.email);
    body.append("username", data.value.username);
    body.append("password", data.value.password);
    body.append("cellphone", data.value.cnum1 + data.value.cnum2 + data.value.cnum3);
    body.append("youtube_image", youtubeSubImg);
    return this.http.post(this.tboxUrl + "register/", body);
  }

  dupUsernameCheck(username): Observable<any> {
    return this.http.post(this.tboxUrl + "dup_username_check/" + username + "/", httpOptionsNoToken);
  }

  loginUser(data): Observable<any> {
    const body = {
      user: data.user,
      password: data.password
      // type: 't',
      // session: data.session,
    };
    return this.http.post(this.tboxUrl + "login/", body, httpOptionsNoToken);
  }
  //https://nid.naver.com/oauth2.0/authorize?
  //response_type=code&
  //client_id=jyvqXeaVOVmV&
  //redirect_uri=http%3A%2F%2Fservice.redirect.url%2Fredirect&
  //state=hLiDdL2uhPtsftcU
  n_loginUser(): Observable<any> {
    const state = Date().substr(16, 8); //Time portion of date
    localStorage.setItem("state", state);
    let body = this.naverAuthUrl + "?";
    body = body + "response_type=code&";
    body = body + "client_id=" + this.naverClientID;
    body = body + "redirect_uri=" + encodeURIComponent("/login");
    body = body + "state=" + encodeURIComponent(state);
    // body = body + 'scope='

    return this.http.get(body, httpOptionsNaver);
  }

  // https://nid.naver.com/oauth2.0/token?
  // grant_type=authorization_code&
  // client_id=jyvqXeaVOVmV&
  // client_secret=527300A0_COq1_XV33cf&
  // code=EIc5bFrl4RibFls1&
  // state=9kgsGTfH4j7IyAkg

  // 접근 토큰 갱신 요청
  // https://nid.naver.com/oauth2.0/token?
  // grant_type=refresh_token&
  // client_id=jyvqXeaVOVmV&
  // client_secret=527300A0_COq1_XV33cf&
  // refresh_token=c8ceMEJisO4Se7uGCEYKK1p52L93bHXLn

  // 접근 토큰 삭제 요청
  // https://nid.naver.com/oauth2.0/token?
  // grant_type=delete&client_id=jyvqXeaVOVmV&
  // client_secret=527300A0_COq1_XV33cf&
  // access_token=c8ceMEJisO4Se7uGCEYKK1p52L93bHXLnaoETis9YzjfnorlQwEisqemfpKHUq2gY&
  // service_provider=NAVER

  // http://콜백URL/redirect?code={code값}&state={state값}

  k_loginUser(data): Observable<any> {
    return null;
  }

  // GOOGLE and FACEBOOK are done right on login comnponent

  g_loginUser(data): Observable<any> {
    const body = {
      email: data.email,
      fullname: data.name
    };
    return this.http.post(this.tboxUrl + "g_login/", body, httpOptionsNoToken);
  }

  f_loginUser(data): Observable<any> {
    const body = {
      email: data.email,
      fullname: data.name
    };
    return this.http.post(this.tboxUrl + "f_login/", body, httpOptionsNoToken);
  }

  logoutUser(data): Observable<any> {
    const body = {
      email: data
    };
    return this.http.post(this.tboxUrl + "logout/", body, httpOptions);
  }

  sendUsernameEmail(data): Observable<any> {
    const body = {
      fullname: data.fullname,
      email: data.email
    };
    return this.http.post(this.tboxUrl + "send_username_email/", body, httpOptionsNoToken);
  }

  updatePassword(user_id, data): Observable<any> {
    const body = {
      old_pw: data.oldPW,
      new_pw: data.newPW
    };
    return this.http.post(this.tboxUrl + "update_password/" + user_id + "/", body, httpOptions);
  }

  sendResetPasswordEmail(data): Observable<any> {
    const body = {
      username: data.username,
      fullname: data.fullname,
      email: data.email
    };
    return this.http.post(this.tboxUrl + "send_reset_pw_email/", body, httpOptionsNoToken);
  }

  updateUserPhoto(user_id, photoUrl): Observable<any> {
    const body = new FormData();
    body.append("photo_url", photoUrl);
    return this.http.post(this.tboxUrl + "update_user_photo/" + user_id + "/", body, httpOptionsFormData);
  }

  getUserPhoto(user_id): Observable<any> {
    return this.http.post(this.tboxUrl + "get_user_photo/" + user_id + "/", httpOptions);
  }

  getAllUsers(): Observable<any> {
    return this.http.get(this.tboxUrl + "users/", httpOptions);
  }

  getUsersByRole(role): Observable<any> {
    return this.http.get(this.tboxUrl + "list_user_by_role/" + role + "/", httpOptions);
  }

  getUserByEmail(email): Observable<any> {
    const params = new HttpParams().set("email", email);
    return this.http.get(this.tboxUrl + "get_user_by_email/", { headers: httpOptions.headers, params: params });
  }

  getUserById(user_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_user_by_id/" + user_id + "/", httpOptions);
  }

  // TODO
  updateUser(user_id, data): Observable<any> {
    const body = {
      cellphone: data.cnum1 + data.cnum2 + data.cnum3,
      username: data.username
    };
    if (typeof data.fullname !== "undefined") {
      body["fullname"] = data.fullname;
    }
    if (typeof data.email !== "undefined") {
      body["email"] = data.email;
    }
    if (typeof data.photoUrl !== "undefined") {
      body["photoUrl"] = data.photoUrl;
    }
    // console.log(body);
    return this.http.put(this.tboxUrl + "update_user/" + user_id + "/", body, httpOptions);
  }

  updateUserAddress(user_id, data): Observable<any> {
    const body = {
      detBdNmList: data.detBdNmList,
      engAddr: data.engAddr,
      rn: data.rn,
      emdNm: data.emdNm,
      zipNo: data.zipNo,
      roadAddrPart2: data.roadAddrPart2,
      emdNo: data.emdNo,
      sggNm: data.sggNm,
      jibunAddr: data.jibunAddr,
      siNm: data.siNm,
      roadAddrPart1: data.roadAddrPart1,
      bdNm: data.bdNm,
      admCd: data.admCd,
      udrtYn: data.udrtYn,
      lnbrMnnm: data.lnbrMnnm,
      roadAddr: data.roadAddr,
      lnbrSlno: data.lnbrSlno,
      buldMnnm: data.buldMnnm,
      bdKdcd: data.bdKdcd,
      liNm: data.liNm,
      rnMgtSn: data.rnMgtSn,
      mtYn: data.mtYn,
      bdMgtSn: data.bdMgtSn,
      buldSlno: data.buldSlno,
      entX: data.entX,
      entY: data.entY,
      longitude: data.longitude,
      latitude: data.latitude,
      detail_juso: data.detail_juso
    };
    return this.http.put(this.tboxUrl + "update_user_address/" + user_id + "/", body, httpOptions);
  }

  //TODO
  // updateUserRole(data): Observable<any> {
  //   const body = {
  //     username: data.username
  //     // etc
  //   }
  //   return this.http.put(this.tboxUrl + 'update_user_role/', body, httpOptionsNoToken );
  // }

  deactivateUser(uid): Observable<any> {
    return this.http.post(this.tboxUrl + "deactivate_user/" + uid + "/", httpOptions);
  }

  reactivateUser(uid): Observable<any> {
    return this.http.post(this.tboxUrl + "reactivate_user/" + uid + "/", httpOptions);
  }

  /*  ****** MemVoucher Service ******  */
  createMemVoucher(data): Observable<any> {
    const body = new FormData();
    body.append("name", data.name);
    body.append("type", data.type_id);
    body.append("gigan", data.gigan);
    body.append("price", data.price);
    body.append("sajin", data.sajin);
    return this.http.post(this.tboxUrl + "create_mem_voucher/", body, httpOptionsFormData);
  }

  editMemVoucher(data): Observable<any> {
    const body = new FormData();
    body.append("name", data.name);
    body.append("type", data.type_id);
    body.append("gigan", data.gigan);
    body.append("price", data.price);
    body.append("sajin", data.sajin);
    return this.http.put(this.tboxUrl + "edit_mem_voucher/" + data.id + "/", body, httpOptionsFormData);
  }

  getAllMemVoucher(): Observable<any> {
    return this.http.get(this.tboxUrl + "list_mem_voucher/", httpOptionsNoToken);
  }

  deleteMemVoucher(voucher_id): Observable<any> {
    return this.http.delete(this.tboxUrl + "delete_mem_voucher/" + voucher_id + "/", httpOptions);
  }

  /*  ****** Coupon Service ******  */
  createCoupon(data): Observable<any> {
    const body = {
      name: data.name,
      type: data.type,
      e_date: data.e_date,
      no_e_date: data.no_e_date,
      mem_voucher_id: data.mem_voucher_id,
      role: data.role,
      course_id: data.course_id,
      amount: data.amount,
      redemption_code: data.redemption_code,
      total_use_limit: data.total_use_limit,
      no_use_limit: data.no_use_limit
    };
    return this.http.post(this.tboxUrl + "create_coupon/", body, httpOptions);
  }

  updateCoupon(data): Observable<any> {
    const body = {
      name: data.name,
      type: data.type,
      e_date: data.e_date,
      no_e_date: data.no_e_date,
      mem_voucher_id: data.mem_voucher_id,
      role: data.role,
      course_id: data.course_id,
      amount: data.amount,
      redemption_code: data.redemption_code,
      total_use_limit: data.total_use_limit,
      no_use_limit: data.no_use_limit
    };
    return this.http.put(this.tboxUrl + "update_coupon/" + data.coupon_id + "/", body, httpOptions);
  }

  getAllCoupon(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_coupon/", httpOptions);
  }

  getAllActiveCoupon(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_active_coupon/", httpOptions);
  }

  getCoupon(coupon_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_coupon/" + coupon_id + "/", httpOptions);
  }

  deleteCoupon(coupon_id): Observable<any> {
    return this.http.get(this.tboxUrl + "delete_coupon/" + coupon_id + "/", httpOptions);
  }

  getCouponTypes(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_coupon_types/", httpOptions);
  }

  checkCoupon(redemption_code, buying_id): Observable<any> {
    const body = {
      redemption_code: redemption_code,
      buying_id: buying_id
    };
    return this.http.post(this.tboxUrl + "check_coupon/", body, httpOptions);
  }

  purchaseMemVoucher(email, v_id, rc): Observable<any> {
    const body = {
      email: email,
      mem_voucher_id: Number(v_id),
      redemption_code: rc
    };
    return this.http.post(this.tboxUrl + "purchase_mem_voucher/", body, httpOptions);
  }

  addPMV(email, urid, pmv_length): Observable<any> {
    const body = {
      email: email,
      userrole: urid,
      pmv_length: pmv_length
    };
    return this.http.post(this.tboxUrl + "add_pmv/", body, httpOptions);
  }

  checkYoutubeImageCanApply(user_id): Observable<any> {
    const body = {};
    return this.http.post(this.tboxUrl + "check_youtube_image_can_apply/" + user_id + "/", body, httpOptions);
  }

  applyYoutubeImage(user_id, youtube_image): Observable<any> {
    const body = new FormData();
    body.append("user_id", user_id);
    body.append("youtube_image", youtube_image);
    return this.http.post(this.tboxUrl + "apply_youtube_image/", body, httpOptionsFormData);
  }

  getAllUnapprovedYoutubeImage(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_unapproved_youtube_image/", httpOptions);
  }

  approveYoutubeImage(user_id, length): Observable<any> {
    const data = {
      length: length
    };
    return this.http.post(this.tboxUrl + "approve_youtube_image/" + user_id + "/", data, httpOptions);
  }

  approveAllYoutubeImage(length): Observable<any> {
    const data = {
      length: length
    };
    return this.http.post(this.tboxUrl + "approve_all_youtube_image/", data, httpOptions);
  }

  getPurchasedMVByUser(user_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_purchased_mv_by_user/" + user_id + "/", httpOptions);
  }

  getAllPurchasedMV(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_purchased_mv/", httpOptions);
  }

  updatePurchasedMV(data, days): Observable<any> {
    const body = {
      user_id: Number(data.id),
      voucher_id: Number(data.v_id),
      change_eday: Number(days)
    };
    return this.http.put(this.tboxUrl + "edit_purchased_mv/", body, httpOptions);
  }

  /*  ****** Gangsa Service ******  */
  createGangsa(data): Observable<any> {
    const body = {
      name: data.name,
      nickname: data.nickname
    };
    return this.http.post(this.tboxUrl + "create_gangsa/", body, httpOptions);
  }

  getAllGangsa(): Observable<any> {
    return this.http.get(this.tboxUrl + "list_all_gangsa/", httpOptions);
  }

  updateGangsa(data): Observable<any> {
    const body = {
      name: data.name,
      nickname: data.nickname
    };
    return this.http.put(this.tboxUrl + "update_gangsa/" + data.id + "/", body, httpOptions);
  }

  deleteGangsa(gangsa_id): Observable<any> {
    return this.http.delete(this.tboxUrl + "delete_gangsa/" + gangsa_id + "/", httpOptions);
  }

  /*  ****** Vod Cate Service ******  */
  createVodCate(data): Observable<any> {
    const body = new FormData();
    body.append("cate", data.cate);
    body.append("img", data.img);
    body.append("desc", data.desc);
    body.append("vod_type", data.vodType);
    return this.http.post(this.tboxUrl + "create_vod_cate/", body, httpOptionsFormData);
  }

  getAllVodCate(): Observable<any> {
    return this.http.get(this.tboxUrl + "list_all_vod_cate/", httpOptionsNoToken);
  }

  updateVodCate(data): Observable<any> {
    const body = new FormData();
    body.append("cate", data.category);
    body.append("img", data.image);
    body.append("desc", data.description);
    return this.http.put(this.tboxUrl + "update_vod_cate/" + data.id + "/", body, httpOptionsFormData);
  }

  deleteVodCate(vod_cate_id): Observable<any> {
    return this.http.delete(this.tboxUrl + "delete_vod_cate/" + vod_cate_id + "/", httpOptions);
  }

  /*  ****** Vod Service ******  */
  createVod(data): Observable<any> {
    const body = new FormData();
    body.append("title", data.title);
    body.append("contents", data.contents);
    body.append("vod", data.vod);
    body.append("preview", data.preview);
    body.append("thumbnail", data.thumbnail);
    body.append("cate", data.type);
    body.append("gangsa_id", data.gangsa_id);
    body.append("level_id", data.level_id);
    body.append("viewableTo", data.viewableTo);
    body.append("hidden", data.hidden);
    return this.http.post(this.tboxUrl + "create_vod/", body, httpOptionsFormData);
  }

  updateVod(data): Observable<any> {
    const body = new FormData();
    body.append("title", data.title);
    body.append("contents", data.contents);
    body.append("vod", data.vod);
    body.append("preview", data.preview);
    body.append("thumbnail", data.thumbnail);
    body.append("cate", data.type_id);
    body.append("gangsa_id", data.gangsa_id);
    body.append("level_id", data.level_id);
    body.append("viewableTo", data.viewableTo);
    body.append("hidden", data.hidden);
    return this.http.put(this.tboxUrl + "update_vod/" + data.id + "/", body, httpOptionsFormData);
  }

  getVod(vod_id): Observable<any> {
    if (localStorage.getItem("token")) {
      return this.http.get(this.tboxUrl + "get_vod/" + vod_id + "/", httpOptions);
    }
    return this.http.get(this.tboxUrl + "get_vod/" + vod_id + "/", httpOptionsNoToken);
  }

  getAllVod(vodType): Observable<any> {
    if (localStorage.getItem("token")) {
      return this.http.get(this.tboxUrl + "list_all_vod/" + vodType + "/", httpOptions);
    }
    return this.http.get(this.tboxUrl + "list_all_vod/" + vodType + "/", httpOptionsNoToken);
  }

  // getFilteredVod(data): Observable<any> {
  //   const params = new HttpParams()
  //     .set('gangsa_id', data.gangsa_id)
  //     .set('level_id', data.level_id)
  //     .set('cate', data.type)
  //     .set('data', data.data);
  //   return this.http.get(this.tboxUrl + 'list_filtered_vod/', { headers: httpOptionsNoToken.headers , params: params } );
  // }

  getVodDataShort(vodType): Observable<any> {
    return this.http.get(this.tboxUrl + "get_vod_data_short/" + vodType + "/", httpOptionsNoToken);
  }

  deleteVod(vod_id): Observable<any> {
    return this.http.delete(this.tboxUrl + "delete_vod/" + vod_id + "/", httpOptions);
  }

  addVodClick(vod_id): Observable<any> {
    return this.http.post(this.tboxUrl + "add_vod_click/" + vod_id + "/", httpOptionsNoToken);
  }

  /*  ****** MainBanner Service ******  */
  addMainBanner(data): Observable<any> {
    const body = new FormData();
    body.append("sajin", data.image);
    body.append("label_main", data.caption_main);
    body.append("label_sub", data.caption_sub);
    // console.log(data.image);
    // console.log(data.caption_main);
    return this.http.post(this.tboxUrl + "add_main_banner/", body, httpOptionsFormData);
  }

  editMainBanner(data): Observable<any> {
    const body = {
      label_main: data.main,
      label_sub: data.sub
    };
    return this.http.put(this.tboxUrl + "edit_main_banner/" + data.id + "/", body, httpOptions);
  }

  getAllMainBanner(): Observable<any> {
    return this.http.get(this.tboxUrl + "list_all_main_banner/", httpOptionsNoToken);
  }

  deleteMainBanner(m_banner_id): Observable<any> {
    return this.http.delete(this.tboxUrl + "delete_main_banner/" + m_banner_id + "/", httpOptions);
  }

  addMainBannerClick(m_banner_id): Observable<any> {
    return this.http.post(this.tboxUrl + "add_main_banner_click/" + m_banner_id + "/", httpOptionsNoToken);
  }

  /*  ****** NewsFeed Service ******  */
  addNewsFeed(data): Observable<any> {
    const body = {
      title: data.title,
      body: data.body
    };
    return this.http.post(this.tboxUrl + "add_news/", body, httpOptions);
  }

  editNewsFeed(data): Observable<any> {
    const body = {
      title: data.title,
      body: data.body
    };
    return this.http.put(this.tboxUrl + "edit_news/" + data.id + "/", body, httpOptions);
  }

  getNewsFeed(): Observable<any> {
    return this.http.get(this.tboxUrl + "list_all_news/", httpOptionsNoToken);
  }

  getAllNewsFeedWoBody(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_news_wo_body/", httpOptionsNoToken);
  }

  getNewsFeedBody(newsfeed_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_news_body/" + newsfeed_id + "/", httpOptionsNoToken);
  }

  deleteNewsFeed(newsfeed_id): Observable<any> {
    return this.http.delete(this.tboxUrl + "delete_news/" + newsfeed_id + "/", httpOptions);
  }

  addNewsFeedClick(newsfeed_id): Observable<any> {
    return this.http.post(this.tboxUrl + "add_news_click/" + newsfeed_id + "/", httpOptionsNoToken);
  }

  addNfPin(nf_id): Observable<any> {
    const body = {
      news_id: nf_id
    };
    return this.http.post(this.tboxUrl + "add_nf_pin/", body, httpOptions);
  }

  removeNfPin(nf_id): Observable<any> {
    const body = {
      news_id: nf_id
    };
    return this.http.post(this.tboxUrl + "remove_nf_pin/", body, httpOptions);
  }

  /*  ****** FAQ Service ******  */
  addFAQ(data): Observable<any> {
    const body = {
      question: data.question,
      answer: data.answer
    };
    return this.http.post(this.tboxUrl + "add_faq/", body, httpOptions);
  }

  editFAQ(data): Observable<any> {
    const body = {
      question: data.question,
      answer: data.answer
    };
    return this.http.put(this.tboxUrl + "edit_faq/" + data.id + "/", body, httpOptions);
  }

  getFAQ(): Observable<any> {
    return this.http.get(this.tboxUrl + "list_all_faq/", httpOptionsNoToken);
  }

  getAllFAQWoAnswer(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_faq_wo_answer/", httpOptionsNoToken);
  }

  getFAQBody(faq_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_faq_answer/" + faq_id + "/", httpOptionsNoToken);
  }

  deleteFAQ(faq_id): Observable<any> {
    return this.http.delete(this.tboxUrl + "delete_faq/" + faq_id + "/", httpOptions);
  }

  addFAQClick(faq_id): Observable<any> {
    return this.http.post(this.tboxUrl + "add_faq_click/" + faq_id + "/", httpOptionsNoToken);
  }

  /*  ****** Resource Service ******  */
  addResource(data): Observable<any> {
    const body = {
      title: data.title,
      body: data.body,
      type: Number(data.type)
    };
    return this.http.post(this.tboxUrl + "add_resource/", body, httpOptions);
  }

  editResource(data): Observable<any> {
    const body = {
      title: data.title,
      body: data.body,
      type: Number(data.id)
    };
    return this.http.put(this.tboxUrl + "edit_resource/" + data.id + "/", body, httpOptions);
  }

  getResource(): Observable<any> {
    return this.http.get(this.tboxUrl + "list_all_resource/", httpOptionsNoToken);
  }

  getAllResourceWoBody(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_resource_wo_body/", httpOptionsNoToken);
  }

  getResourceBody(res_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_resource_body/" + res_id + "/", httpOptionsNoToken);
  }

  deleteResource(res_id): Observable<any> {
    return this.http.delete(this.tboxUrl + "delete_resource/" + res_id + "/", httpOptions);
  }

  addResourceClick(res_id): Observable<any> {
    return this.http.post(this.tboxUrl + "add_resource_click/" + res_id + "/", httpOptionsNoToken);
  }

  /*  ****** Watch Vod Service ******  */
  watchVod(user_id, vod_id): Observable<any> {
    const body = {
      vod_id: vod_id,
      user_id: user_id
    };
    return this.http.post(this.tboxUrl + "watch_vod/", body, httpOptions);
  }

  getWatchedVodsByUser(user_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_watched_vods_by_user/" + user_id + "/", httpOptions);
  }

  getAllWatchedVods(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_watched_vods/", httpOptions);
  }

  /*  ****** GetClick Service ******  */
  getAllClicks(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_clicks/", httpOptions);
  }

  /*  ****** Login Data Service ******  */
  getLoginDataByUser(user_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_login_data_by_user/" + user_id + "/", httpOptions);
  }

  getAllLoginData(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_login_data/", httpOptions);
  }

  /*  ****** Store Cate Service ******  */
  createStoreCate(sc): Observable<any> {
    const body = {
      cate: sc
    };
    return this.http.post(this.tboxUrl + "create_store_cate/", body, httpOptions);
  }
  getAllStoreCate(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_store_cate/", httpOptions);
  }
  updateStoreCate(sc): Observable<any> {
    const body = {
      cate: sc.cate
    };
    return this.http.put(this.tboxUrl + "update_store_cate/" + sc.id + "/", body, httpOptionsNoToken);
  }
  deleteStoreCate(sc_id): Observable<any> {
    return this.http.delete(this.tboxUrl + "delete_store_cate/" + sc_id + "/", httpOptions);
  }

  /*  ****** Store Item Service ******  */
  createStoreItem(data): Observable<any> {
    const body = new FormData();
    body.append("name", data.name);
    body.append("price", data.price);
    body.append("onsale", data.onsale);
    body.append("sale_price", data.sale_price);
    body.append("main_img", data.main_img);
    body.append("naver_store", data.naver_store);
    body.append("interpark_store", data.interpark_store);
    return this.http.post(this.tboxUrl + "create_store_item/" + data.sc_id + "/", body, httpOptionsFormData);
  }

  getAllStoreItem(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_store_item/", httpOptionsNoToken);
  }

  getStoreItemByCate(sc_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_store_item_by_cate/" + sc_id + "/", httpOptionsNoToken);
  }

  getStoreItem(storeItemId): Observable<any> {
    return this.http.get(this.tboxUrl + "get_store_item/" + storeItemId + "/", httpOptionsNoToken);
  }

  editStoreItem(data): Observable<any> {
    const body = new FormData();
    body.append("name", data.name);
    body.append("price", data.price);
    body.append("onsale", data.onsale);
    body.append("sale_price", data.sale_price);
    body.append("main_img", data.main_img);
    body.append("naver_store", data.naver_store);
    body.append("interpark_store", data.interpark_store);
    return this.http.put(this.tboxUrl + "edit_store_item/" + data.id + "/", body, httpOptionsFormData);
  }

  deleteStoreItem(storeItemId): Observable<any> {
    return this.http.delete(this.tboxUrl + "delete_store_item/" + storeItemId + "/", httpOptions);
  }

  addStoreItemClick(storeItemId): Observable<any> {
    return this.http.post(this.tboxUrl + "add_store_item_click/" + storeItemId + "/", httpOptionsNoToken);
  }

  viewNaverStoreItem(storeItemId): Observable<any> {
    const body = {
      store_item_id: storeItemId,
      user_id: localStorage.getItem("id"),
      type: "naver"
    };
    return this.http.post(this.tboxUrl + "view_store_item", body, httpOptionsNoToken);
  }

  viewInterparkStoreItem(storeItemId): Observable<any> {
    const body = {
      store_item_id: storeItemId,
      user_id: localStorage.getItem("id"),
      type: "interpark"
    };
    return this.http.post(this.tboxUrl + "view_store_item", body, httpOptionsNoToken);
  }

  getuserViewedStoreItem(): Observable<any> {
    return this.http.post(this.tboxUrl + "get_user_viewed_store_item/" + localStorage.getItem("id") + "/", httpOptions);
  }

  /*  ****** Vod Cal Service ******  */
  addVodCalListItem(data): Observable<any> {
    const body = {
      vod_id: data.vod_id,
      color_primary: data.color.primary,
      color_secondary: data.color.secondary,
      starts_at: data.start,
      ends_at: data.end
    };
    return this.http.post(this.tboxUrl + "add_vod_cal_list_item/", body, httpOptions);
  }

  deleteVodCalListItem(vc_id): Observable<any> {
    return this.http.delete(this.tboxUrl + "delete_vod_cal_list_item/" + vc_id + "/", httpOptions);
  }

  updateVodCalListItem(data): Observable<any> {
    const body = {
      vod_id: data.vod_id,
      color_primary: data.color_primary,
      color_secondary: data.color_secondary,
      starts_at: data.starts_at,
      ends_at: data.ends_at
    };
    return this.http.put(this.tboxUrl + "update_vod_cal_list_item/" + data.vc_id + "/", body, httpOptions);
  }

  getVodCalList(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_vod_cal_list/", httpOptionsNoToken);
  }

  getVodCalListItem(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_vod_cal_list_item/", httpOptionsNoToken);
  }

  randomizeVodCalMonth(month): Observable<any> {
    return this.http.post(this.tboxUrl + "randomize_vod_cal_month/" + month + "/", httpOptions);
  }

  fillVodCalMonth(month, data): Observable<any> {
    const body = {
      cate: JSON.stringify(data.cate),
      level: JSON.stringify(data.level)
    };
    return this.http.post(this.tboxUrl + "fill_vod_cal_month/" + month + "/", body, httpOptions);
  }

  /*  ****** Club Info Service ******  */
  getAllClubInfo(): Observable<any> {
    return this.http.post(this.tboxUrl + "get_all_club_info/", httpOptionsNoToken);
  }

  getClubInfoByUser(userId): Observable<any> {
    return this.http.get(this.tboxUrl + "get_club_info_by_user/" + userId + "/", httpOptionsNoToken);
  }

  getClubInfoByClub(clubId): Observable<any> {
    return this.http.get(this.tboxUrl + "get_club_info_by_club/" + clubId + "/", httpOptionsNoToken);
  }

  /*  ****** Club Application Process Service ******  */
  applyClub(data): Observable<any> {
    const body = {
      name: data.name,
      email: data.email,
      biz_num: data.biznum,
      zipno: data.zipno,
      jibunAddr: data.jibunAddr,
      roadAddr: data.roadAddr,
      detailAddr: data.detailAddr,
      longitude: data.longitude,
      latitude: data.latitude
    };
    return this.http.post(this.tboxUrl + "apply_club/" + localStorage.getItem("id") + "/", body, httpOptionsNoToken);
  }

  approveClub(club_id): Observable<any> {
    return this.http.post(this.tboxUrl + "approve_club/" + club_id + "/", httpOptionsNoToken);
  }

  rejectClub(club_id, message): Observable<any> {
    const body = {
      reason: message
    };
    return this.http.post(this.tboxUrl + "reject_club/" + club_id + "/", body, httpOptionsNoToken);
  }

  blockClub(club_id): Observable<any> {
    return this.http.post(this.tboxUrl + "block_club/" + club_id + "/", httpOptionsNoToken);
  }

  getClubApplicationHistory(club_id): Observable<any> {
    return this.http.post(this.tboxUrl + "get_club_application_history" + club_id + "/", httpOptionsNoToken);
  }

  addClubClick(club_id): Observable<any> {
    return this.http.post(this.tboxUrl + "add_club_click/" + club_id + "/", httpOptionsNoToken);
  }

  /*  ****** Club Class Service ******  */
  addClass(club_id, data): Observable<any> {
    const body = {
      class_name: data.class_name,
      points_required: data.points_required,
      total_seats: data.total_seats,
      start_hour: data.start_hour,
      start_minute: data.start_minute,
      end_hour: data.end_hour,
      end_min: data.end_min,
      sunday: data.sunday,
      monday: data.monday,
      tuesday: data.tuesday,
      wednesday: data.wednesday,
      thursday: data.thursday,
      friday: data.friday,
      saturday: data.saturday
    };
    return this.http.post(this.tboxUrl + "add_class/" + club_id + "/", body, httpOptionsNoToken);
  }

  getAllClassByClub(club_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_class_by_club" + club_id + "/", httpOptionsNoToken);
  }

  getActiveClass(club_id, emd, start_time): Observable<any> {
    const params = new HttpParams();
    if (club_id !== "" && club_id !== -1 && club_id !== null) {
      params.set("club_id", club_id);
    }
    if (emd !== "" && emd !== null) {
      params.set("emd", emd);
    }
    if (start_time !== "" && start_time !== null) {
      params.set("start_time", start_time);
    }
    return this.http.get(this.tboxUrl + "get_active_class/", { headers: httpOptionsNoToken.headers, params: params });
  }

  stopClass(class_id): Observable<any> {
    return this.http.put(this.tboxUrl + "stop_class/" + class_id + "/", httpOptionsNoToken);
  }

  addClassBreak(club_id, break_date): Observable<any> {
    const body = {
      break_date: break_date
    };
    return this.http.post(this.tboxUrl + "add_class_break/" + club_id + "/", body, httpOptionsNoToken);
  }

  deleteClassBreak(club_id, break_date): Observable<any> {
    const params = new HttpParams().set("break_date", break_date);
    return this.http.delete(this.tboxUrl + "delete_class_break/" + club_id + "/", { headers: httpOptionsNoToken.headers, params: params });
  }

  getAllClassBreak(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_class_break/", httpOptionsNoToken);
  }

  getClassBreakByClub(club_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_class_break_by_club/" + club_id + "/", httpOptionsNoToken);
  }

  /*  ****** Club Reservation Service ******  */
  addReservation(data): Observable<any> {
    const body = {
      user_id: data.user_id,
      reserve_date: data.reserve_date,
      class_id: data.class_id
    };
    return this.http.post(this.tboxUrl + "add_reservation/", body, httpOptionsNoToken);
  }

  cancelReservation(reservation_id): Observable<any> {
    const body = {
      reservation_id: reservation_id
    };
    return this.http.post(this.tboxUrl + "cancel_reservation/", body, httpOptionsNoToken);
  }

  scanBarcode(barcode): Observable<any> {
    const body = {
      barcode: barcode
    };
    return this.http.post(this.tboxUrl + "scan_barcode/", body, httpOptionsNoToken);
  }

  attendClass(barcode): Observable<any> {
    const body = {
      barcode: barcode,
      user_id: localStorage.getItem("id")
    };
    return this.http.post(this.tboxUrl + "attend_class/", body, httpOptionsNoToken);
  }

  getReservations(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_reservations/", httpOptionsNoToken);
  }

  getReservationsByUser(user_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_reservations_by_user/" + user_id + "/", httpOptionsNoToken);
  }

  getReservationsByClub(club_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_reservations_by_club/" + club_id + "/", httpOptionsNoToken);
  }

  /*  ****** License Banner Service ******  */
  addLicenseBanner(img_link): Observable<any> {
    const body = new FormData();
    body.append("img_link", img_link);
    return this.http.post(this.tboxUrl + "add_license_banner/", body, httpOptionsFormData);
  }

  editLicenseBanner(data): Observable<any> {
    const body = new FormData();
    body.append("img_link", data.img_link);
    return this.http.put(this.tboxUrl + "edit_license_banner/" + data.license_banner_id + "/", body, httpOptionsFormData);
  }

  getAllLicenseBanner(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_license_banner/", httpOptionsNoToken);
  }

  deleteLicenseBanner(id): Observable<any> {
    return this.http.delete(this.tboxUrl + "delete_license_banner/" + id + "/", httpOptions);
  }

  /*  ****** License Course Service ******  */
  addCourse(data): Observable<any> {
    const body = new FormData();
    body.append("title", data.title);
    body.append("thumbnail", data.thumbnail);
    body.append("price", data.price);
    body.append("language", data.language);
    body.append("study_length", data.study_length);
    body.append("certificate_length", data.certificate_length);
    body.append("promotional", data.promotional);
    body.append("overview", data.overview);
    body.append("curriculum", data.curriculum);
    body.append("about_license", data.about_license);
    return this.http.post(this.tboxUrl + "add_course/", body, httpOptionsFormData);
  }

  editCourse(data): Observable<any> {
    const body = new FormData();
    body.append("title", data.title);
    body.append("thumbnail", data.thumbnail);
    body.append("price", data.price);
    body.append("language", data.language);
    body.append("study_length", data.study_length);
    body.append("certificate_length", data.certificate_length);
    body.append("promotional", data.promotional);
    body.append("overview", data.overview);
    body.append("curriculum", data.curriculum);
    body.append("about_license", data.about_license);
    return this.http.put(this.tboxUrl + "edit_course/" + data.course_id + "/", body, httpOptionsFormData);
  }

  getAllCourse(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_course/", httpOptions);
  }

  getPublishedActiveCourses(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_published_active_courses/", httpOptions);
  }

  getCourse(course_id): Observable<any> {
    const params = new HttpParams().set("user_id", localStorage.getItem("id"));
    return this.http.get(this.tboxUrl + "get_course/" + course_id + "/", { headers: httpOptions.headers, params: params });
  }

  deleteCourse(course_id): Observable<any> {
    return this.http.post(this.tboxUrl + "delete_course/" + course_id + "/", httpOptions);
  }

  reactivateCourse(course_id): Observable<any> {
    return this.http.post(this.tboxUrl + "reactivate_course/" + course_id + "/", httpOptions);
  }

  /*  ****** Other License Course Service ******  */
  addCourseClick(user_id, course_id): Observable<any> {
    const body = {
      user_id: user_id,
      course_id: course_id
    };
    return this.http.post(this.tboxUrl + "add_course_click/" + body + "/", httpOptions);
  }

  getRecentlyViewedCourses(user_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_recently_viewed_courses/" + user_id + "/", httpOptions);
  }

  enrollCourseToUser(user_id, course_id): Observable<any> {
    const body = {
      user_id: user_id,
      course_id: course_id
    };
    return this.http.post(this.tboxUrl + "enroll_course_to_user/", body, httpOptions);
  }

  /*  ****** License Course Enrollemnt Service ******  */
  getAllEnrolledCourses(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_enrolled_courses/", httpOptions);
  }

  getEnrolledCourse(user_id, course_id): Observable<any> {
    const params = new HttpParams().set("user_id", user_id).set("course_id", course_id);
    return this.http.get(this.tboxUrl + "get_enrolled_course/", { headers: httpOptions.headers, params: params });
  }

  getEnrolledUsersByCourse(course_id): Observable<any> {
    if (course_id === null) {
      return this.http.get(this.tboxUrl + "get_enrolled_users_by_course/", httpOptionsNoToken);
    }
    return this.http.get(this.tboxUrl + "get_enrolled_users_by_course/" + course_id + "/", httpOptions);
  }

  getEnrolledCoursesByUser(user_id): Observable<any> {
    if (user_id === null) {
      return this.http.get(this.tboxUrl + "get_enrolled_courses_by_user/", httpOptionsNoToken);
    }
    return this.http.get(this.tboxUrl + "get_enrolled_courses_by_user/" + user_id + "/", httpOptions);
  }

  updateStudyLength(enrollment_id, days): Observable<any> {
    const body = {
      days: Number(days)
    };
    return this.http.put(this.tboxUrl + "update_study_length/" + enrollment_id + "/", body, httpOptions);
  }

  updateCertificateLength(enrollment_id, days): Observable<any> {
    const body = {
      days: Number(days)
    };
    return this.http.put(this.tboxUrl + "update_certificate_length/" + enrollment_id + "/", body, httpOptions);
  }

  /*  ****** License Course Module Service ******  */
  addModule(title, course_id): Observable<any> {
    const body = {
      title: title
    };
    return this.http.post(this.tboxUrl + "add_module/" + course_id + "/", body, httpOptions);
  }

  editModule(module_id, data): Observable<any> {
    const body = {
      title: data.title,
      ordering: data.ordering
    };
    return this.http.put(this.tboxUrl + "edit_module/" + module_id + "/", body, httpOptions);
  }

  getModuleByCourse(course_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_modules_by_course/" + course_id + "/", httpOptions);
  }

  getModule(module_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_module/" + module_id + "/", httpOptions);
  }

  deleteModule(module_id): Observable<any> {
    return this.http.delete(this.tboxUrl + "delete_module/" + module_id + "/", httpOptions);
  }

  /*  ****** License Course Lecture Service ******  */
  addLecture(data, module_id): Observable<any> {
    const body = {
      title: data.title,
      subtitle: data.subtitle,
      has_video: data.has_video,
      video: data.video,
      has_content: data.has_content,
      content: data.content
    };
    return this.http.post(this.tboxUrl + "add_lecture/" + module_id + "/", body, httpOptions);
  }

  editLecture(data): Observable<any> {
    const body = {
      title: data.title,
      subtitle: data.subtitle,
      ordering: data.ordering,
      has_video: data.has_video,
      video: data.video,
      has_content: data.has_content,
      content: data.content
    };
    return this.http.put(this.tboxUrl + "edit_lecture/" + data.mat_id + "/", body, httpOptions);
  }

  getLecture(lecture_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_lecture/" + lecture_id + "/", httpOptions);
  }

  getLectureByModule(module_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_lecture_by_module/" + module_id + "/", httpOptions);
  }

  getLectureByModuleForUser(module_id, user_id): Observable<any> {
    const params = new HttpParams().set("module_id", module_id).set("user_id", user_id);
    return this.http.get(this.tboxUrl + "get_lecture_by_module_for_user/", { headers: httpOptions.headers, params: params });
  }

  deleteLecture(lecture_id): Observable<any> {
    return this.http.delete(this.tboxUrl + "delete_lecture/" + lecture_id + "/", httpOptions);
  }

  studyLecture(user_id, lecture_id): Observable<any> {
    const body = {
      user_id: Number(user_id),
      lecture_id: Number(lecture_id)
    };
    // console.log(body);
    return this.http.post(this.tboxUrl + "study_lecture/", body, httpOptions);
  }

  completeStudyLecture(user_id, lecture_id): Observable<any> {
    const body = {
      user_id: user_id,
      lecture_id: lecture_id
    };
    return this.http.post(this.tboxUrl + "complete_study_lecture/", body, httpOptions);
  }

  /*  ****** License Course Quiz Service ******  */
  addQuiz(data, module_id): Observable<any> {
    const body = {
      title: data.title,
      subtitle: data.subtitle,
      total_attempts: data.total_attempts,
      passing_percent: data.passing_percent,
      done_within_days: data.done_within_days,
      is_timed: data.is_timed,
      completion_time: data.completion_time
    };
    return this.http.post(this.tboxUrl + "add_quiz/" + module_id + "/", body, httpOptions);
  }

  editQuiz(data): Observable<any> {
    const body = {
      title: data.title,
      subtitle: data.subtitle,
      total_attempts: data.total_attempts,
      passing_percent: data.passing_percent,
      done_within_days: data.done_within_days,
      is_timed: data.is_timed,
      completion_time: data.completion_time,
      ordering: data.ordering
    };
    return this.http.put(this.tboxUrl + "edit_quiz/" + data.mat_id + "/", body, httpOptions);
  }

  getQuiz(quiz_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_quiz/" + quiz_id + "/", httpOptions);
  }

  deleteQuiz(quiz_id): Observable<any> {
    return this.http.delete(this.tboxUrl + "delete_quiz/" + quiz_id + "/", httpOptions);
  }

  viewQuizInfo(user_id, quiz_id): Observable<any> {
    const body = {
      user_id: user_id,
      quiz_id: quiz_id
    };
    return this.http.post(this.tboxUrl + "view_quiz_info/", body, httpOptions);
  }

  startQuiz(user_id, quiz_id): Observable<any> {
    const body = {
      user_id: user_id,
      quiz_id: quiz_id
    };
    return this.http.post(this.tboxUrl + "start_quiz/", body, httpOptions);
  }

  startedQuiz(user_id, quiz_id): Observable<any> {
    const body = {
      user_id: user_id,
      quiz_id: quiz_id
    };
    return this.http.post(this.tboxUrl + "started_quiz/", body, httpOptions);
  }

  /*  ****** License Course Quiz Question Service ******  */
  addQuestion(data, quiz_id): Observable<any> {
    const body = {
      question_type: data.question_type,
      question_name: data.question_name,
      question_text: data.question_text,
      question_points: data.question_points,
      answer_choices: JSON.stringify(data.answer_choices),
      answer_correct: JSON.stringify(data.answer_correct),
      answer_comment_correct: data.answer_comment_correct,
      answer_comment_incorrect: data.answer_comment_incorrect
    };
    return this.http.post(this.tboxUrl + "add_question/" + quiz_id + "/", body, httpOptions);
  }

  editQuestion(data): Observable<any> {
    const body = {
      position: data.position,
      question_type: data.question_type,
      question_name: data.question_name,
      question_text: data.question_text,
      question_points: data.question_points,
      answer_choices: JSON.stringify(data.answer_choices),
      answer_correct: JSON.stringify(data.answer_correct),
      answer_comment_correct: data.answer_comment_correct,
      answer_comment_incorrect: data.answer_comment_incorrect
    };
    return this.http.put(this.tboxUrl + "edit_question/" + data.question_id + "/", body, httpOptions);
  }

  getQuestion(question_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_question/" + question_id + "/", httpOptions);
  }

  getQuestionsByQuiz(quiz_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_questions_by_quiz/" + quiz_id + "/", httpOptions);
  }

  deleteQuestion(question_id): Observable<any> {
    return this.http.delete(this.tboxUrl + "delete_question/" + question_id + "/", httpOptions);
  }

  getCoMoMa(course_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_co_mo_ma/" + course_id + "/", httpOptions);
  }

  changeMatOrdering(mat_id, newOrdering): Observable<any> {
    const body = {
      mat_id: mat_id,
      ordering: newOrdering
    };
    // console.log(body);
    return this.http.post(this.tboxUrl + "change_mat_ordering/", body, httpOptions);
  }

  publishContent(course_id): Observable<any> {
    return this.http.post(this.tboxUrl + "publish_content/" + course_id + "/", httpOptions);
  }

  /*  ****** License Course Quiz Submission Service ******  */
  addSubmission(quiz_id, user_id, data): Observable<any> {
    const body = {
      quiz_id: quiz_id,
      user_id: user_id,
      submission_answers: JSON.stringify(data)
    };
    // console.log(body);
    return this.http.post(this.tboxUrl + "add_submission/", body, httpOptions).pipe(retry(3));
  }

  /* Edit Submission Not Allowed */

  getAllSubmission(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_submission/", httpOptions);
  }

  getSubmission(submission_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_submission/" + submission_id + "/", httpOptions);
  }

  getSubmissionByCourse(course_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_submission_by_course/" + course_id + "/", httpOptions);
  }

  /*  ****** License Course Quiz Answer Service ******  */

  /* Add Answer Automatically Done When Submission Is Created */

  gradeAnswer(data): Observable<any> {
    const body = {
      is_correct: data.is_correct,
      fudge_points: data.fudge_points,
      comment: data.comment
    };
    return this.http.put(this.tboxUrl + "grade_answer/" + data.answer_id + "/", body, httpOptions);
  }

  getAllAnswersInSubmission(submission_id, user_id): Observable<any> {
    const params = new HttpParams().set("submission_id", submission_id).set("user_id", user_id);
    return this.http.get(this.tboxUrl + "get_all_answer_in_submission/", { headers: httpOptions.headers, params: params });
  }

  getSubmissionList(quiz_id, user_id): Observable<any> {
    const params = new HttpParams().set("quiz_id", quiz_id).set("user_id", user_id);
    return this.http.get(this.tboxUrl + "list_submission/", { headers: httpOptions.headers, params: params });
  }

  /*  ****** License Generation Service ******  */
  generateCertificate(user_id, course_id): Observable<any> {
    const body = {
      user_id: user_id,
      course_id: course_id
    };
    return this.http.post(this.tboxUrl + "generate_certificate", body, httpOptions);
  }

  emailCertificate(user_id, course_id): Observable<any> {
    const body = {
      user_id: user_id,
      course_id: course_id
    };
    return this.http.post(this.tboxUrl + "generate_certificate", body, httpOptions);
  }

  getAllGrantedCertificates(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_granted_certificates/", httpOptions);
  }

  getAllGrantedCertificatesByCourse(course_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_granted_certificates_by_course/" + course_id + "/", httpOptions);
  }

  getAllGrantedCertificatesByUser(user_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_granted_certificates_by_user/" + user_id + "/", httpOptions);
  }

  /*  ****** License Course Review Service ******  */
  addUserCourseReview(user_id, course_id, data): Observable<any> {
    const body = {
      user_id: user_id,
      course_id: course_id,
      rating: data.rating,
      review: data.review,
      anon: data.anon
    };
    return this.http.post(this.tboxUrl + "add_user_course_review/", body, httpOptions);
  }

  getAllUserCourseReview(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_user_course_review/", httpOptions);
  }

  getUserCourseReviewByUser(user_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_user_course_review_by_user/" + user_id + "/", httpOptions);
  }

  getUserCourseReviewByCourse(course_id): Observable<any> {
    return this.http.get(this.tboxUrl + "get_user_course_review_by_course/" + course_id + "/", httpOptions);
  }

  /*  ****** License Course Review Feature Service ******  */
  featureUCReview(uc_review_id): Observable<any> {
    const body = {
      featured: true
    };
    return this.http.put(this.tboxUrl + "feature_user_course_review/" + uc_review_id + "/", body, httpOptions);
  }

  unfeatureUCReview(uc_review_id): Observable<any> {
    const body = {
      featured: false
    };
    return this.http.put(this.tboxUrl + "feature_user_course_review/" + uc_review_id + "/", body, httpOptions);
  }

  getFeaturedUCReview(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_featured_user_course_review/", httpOptions);
  }

  /*  ****** Admin Page Permission Service ******  */
  createAdmin(data): Observable<any> {
    const body = {
      fullname: data.fullname,
      email: data.email,
      username: data.username,
      password: data.password,
      cellphone: data.cellphone
    };
    return this.http.post(this.tboxUrl + "create_admin/" + localStorage.getItem("id") + "/", body, httpOptions);
  }

  updateAdminInfo(data): Observable<any> {
    const body = {
      admin_id: data.admin_id,
      fullname: data.fullname,
      email: data.email,
      username: data.username,
      cellphone: data.cellphone,
      pages: data.pages
    };
    return this.http.put(this.tboxUrl + "update_admin_info/" + localStorage.getItem("id") + "/", body, httpOptions);
  }

  updateAdminPassword(data): Observable<any> {
    const body = {
      admin_id: data.admin_id,
      password: data.password
    };
    return this.http.put(this.tboxUrl + "update_admin_info/" + localStorage.getItem("id") + "/", httpOptions);
  }

  getAllAdminInfo(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_admin_info/" + localStorage.getItem("id") + "/", httpOptions);
  }

  getMyAdminInfo(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_my_admin_info/" + localStorage.getItem("id") + "/", httpOptions);
  }

  deleteAdmin(admin_id): Observable<any> {
    const params = new HttpParams().set("admin_id", admin_id);
    return this.http.delete(this.tboxUrl + "delete_class_break/" + localStorage.getItem("id") + "/", { headers: httpOptions.headers, params: params });
  }

  getAllAdminPages(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_all_admin_pages/" + localStorage.getItem("id") + "/", httpOptions);
  }

  getClientIp(): Observable<any> {
    return this.http.get(this.tboxUrl + "get_client_ip/", httpOptions);
  }
}
