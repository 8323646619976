<div class="tos-header">
    <div class="tos-header-title">
        <div class="color-spacer"></div>
        <!-- <img src="../../../../../assets/images/tboxfit_logo.svg" class="tos-header-logo"> -->
        <h2 class="tos-title">이용약관</h2>
    </div>
    <div class="tos-header-navbar">
        <div class="tos-navlink-wrapper">
            <button 
                class="tos-navlink tos-navlink-active" 
                (click)="navigateToTos()"
                [ngClass]="{'tos-navlink-active': this.router.url === '/kr/tos'}">사이트 이용약관
            </button>
        </div>
        <div class="tos-navlink-wrapper">
            <button 
                class="tos-navlink" 
                (click)="navigateToPriv()"
                [ngClass]="{'tos-navlink-active': this.router.url === '/kr/privacy'}">개인정보 처리방침
            </button>
        </div>
        <div class="tos-navlink-wrapper">
            <button 
                class="tos-navlink" 
                (click)="navigateToMemTerms()"
                [ngClass]="{'tos-navlink-active': this.router.url === '/kr/membership-terms'}">멤버십 약관
            </button>
        </div>
    </div>
</div>
