import { AboutUsComponent } from "./layout-kr/components/info/about-us/about-us.component";
import { AccountDeletedComponent } from "./layout-kr/components/delete-account/account-deleted/account-deleted.component";
// import { AClubManagementComponent } from './layout-kr/components/admin/a-club-management/a-club-management.component';
// import { AConfigComponent } from './layout-kr/components/admin/a-config/a-config.component';
// import { ADesignManagementComponent } from './layout-kr/components/admin/a-design-management/a-design-management.component';
// import { AdminComponent } from './layout-kr/components/admin/admin.component';
import { AdminGuard, AuthGuard, NoAuthGuard, IncompleteGuard } from "./shared";
// import { ALicenseMComponent } from './layout-kr/components/admin/a-license-m/a-license-m.component';
// import { AMainComponent } from './layout-kr/components/admin/a-main/a-main.component';
// import { APermissionMComponent } from './layout-kr/components/admin/a-permission-m/a-permission-m.component';
// import { AReservationManagementComponent } from './layout-kr/components/admin/a-reservation-management/a-reservation-management.component';
// import { AStoreMComponent } from './layout-kr/components/admin/a-store-m/a-store-m.component';
// import { AUserManagementComponent } from './layout-kr/components/admin/a-user-management/a-user-management.component';
// import { AVodManagementComponent } from './layout-kr/components/admin/a-vod-management/a-vod-management.component';
// import { ASubPaymentComponent } from './layout-kr/components/admin/a-sub-payment/a-sub-payment.component';
// import { ASubscriptionMComponent } from './layout-kr/components/admin/a-subscription-m/a-subscription-m.component';
import { BodyComponent } from "./layout-kr/components/body/body.component";
// import { ClubComponent } from './layout-kr/components/club/club.component';
import { CommunityComponent } from "./layout-kr/components/community/community.component";
// import { LicenseComponent } from './layout-kr/components/license/license.component';
// import { LiCertificateComponent } from './layout-kr/components/license/li-certificate/li-certificate.component';
// import { LiCourseComponent } from './layout-kr/components/license/li-course/li-course.component';
// import { LiEnrolledComponent } from './layout-kr/components/license/li-enrolled/li-enrolled.component';
// import { LiHomeComponent } from './layout-kr/components/license/li-home/li-home.component';
// import { LiLessonComponent } from './layout-kr/components/license/li-lesson/li-lesson.component';
// import { LiPromoComponent } from './layout-kr/components/license/li-promo/li-promo.component';
// import { LiPurchaseComponent } from './layout-kr/components/license/li-purchase/li-purchase.component';
// import { LiQuizComponent } from './layout-kr/components/license/li-quiz/li-quiz.component';
// import { LiTakingComponent } from './layout-kr/components/license/li-taking/li-taking.component';
import { LoginComponent } from "./layout-kr/components/login/login.component";
// import { MembershipComponent } from './layout-kr/components/membership/membership.component';
import { MembershipTermsComponent } from "./layout-kr/components/info/membership-terms/membership-terms.component";
import { NgModule } from "@angular/core";
import { PrivacyPolicyComponent } from "./layout-kr/components/info/privacy-policy/privacy-policy.component";
import { ProfileComponent } from "./layout-kr/components/user/profile/profile.component";
import { ReactivateAccountComponent } from "./layout-kr/components/delete-account/reactivate-account/reactivate-account.component";
import { Routes, RouterModule } from "@angular/router";
import { SetupComponent } from "./layout-kr/components/user/setup/setup.component";
import { SignupComponent } from "./layout-kr/components/signup/signup.component";
import { StoreComponent } from "./layout-kr/components/store/store.component";
import { TermsOfServiceComponent } from "./layout-kr/components/info/terms-of-service/terms-of-service.component";
import { VodComponent } from "./layout-kr/components/vod/vod.component";
// import { EnBodyComponent } from './layout-en/components/en-body/en-body.component';
// import { EnLoginComponent } from './layout-en/components/en-login/en-login.component';
// import { EnSignupComponent } from './layout-en/components/en-signup/en-signup.component';
// import { EnSetupComponent } from './layout-en/components/en-user/en-setup/en-setup.component';
// import { EnVodComponent } from './layout-en/components/en-vod/en-vod.component';
// import { EnAUserManagementComponent } from './layout-en/components/en-admin/en-a-user-management/en-a-user-management.component';
// import { EnAMainComponent } from './layout-en/components/en-admin/en-a-main/en-a-main.component';
// import { EnAConfigComponent } from './layout-en/components/en-admin/en-a-config/en-a-config.component';
// import { EnAVodManagementComponent } from './layout-en/components/en-admin/en-a-vod-management/en-a-vod-management.component';
// import { EnADesignManagementComponent } from './layout-en/components/en-admin/en-a-design-management/en-a-design-management.component';
// import { EnProfileComponent } from './layout-en/components/en-user/en-profile/en-profile.component';
// import { EnMembershipComponent } from './layout-en/components/en-membership/en-membership.component';
// import { EnCommunityComponent } from './layout-en/components/en-community/en-community.component';
// import { EnAboutUsComponent } from './layout-en/components/en-info/en-about-us/en-about-us.component';
// import { EnMembershipTermsComponent } from './layout-en/components/en-info/en-membership-terms/en-membership-terms.component';
// import { EnPrivacyPolicyComponent } from './layout-en/components/en-info/en-privacy-policy/en-privacy-policy.component';
// import { EnTermsOfServiceComponent } from './layout-en/components/en-info/en-terms-of-service/en-terms-of-service.component';
import { NoPageFoundComponent } from "./no-page-found/no-page-found.component";
// import { QuizIncompleteGuard } from './shared/guards/quiz-incomplete.guard';
// import { LessonIncompleteGuard } from './shared/guards/lesson-incomplete.guard';
// import { TbfappMainComponent } from './tbfapp/admin/tbfapp-main/tbfapp-main.component';
// import { TbfappMenuComponent } from './tbfapp/admin/tbfapp-menu/tbfapp-menu.component';
// import { TbfappSupportComponent } from './tbfapp/admin/tbfapp-support/tbfapp-support.component';
// import { TbfappUserComponent } from './tbfapp/admin/tbfapp-user/tbfapp-user.component';
// import { TbfappVodComponent } from './tbfapp/admin/tbfapp-vod/tbfapp-vod.component';
// import { TbfappAdminComponent } from './tbfapp/admin/tbfapp-admin.component';
// import { EnTboxVodComponent } from './layout-en/components/en-tbox-vod/en-tbox-vod.component';

import { MultiprofileComponent } from "./layout-kr/components/multiprofile/multiprofile.component";

// import { Membership2Component } from './layout-kr/components/membership2/membership2.component';

import { SubscriptionComponent } from "./layout-kr/components/subscription/subscription.component";

import { AdminComponent } from "./layout-kr/components/admin/admin.component";

// import { LiveAClubManagementComponent } from './layout-live/components/live-admin/live-a-club-management/live-a-club-management.component';
// import { LiveAConfigComponent, LiveEditVoucherComponent, LivePurchasedVoucherComponent, LiveAConfigCouponComponent } from './layout-live/components/live-admin/live-a-config/live-a-config.component';
// import { LiveADesignManagementComponent, LiveEditBannerComponent } from './layout-live/components/live-admin/live-a-design-management/live-a-design-management.component';
// import { LiveALicenseMComponent } from './layout-live/components/live-admin/live-a-license-m/live-a-license-m.component';
// import { LiveAMainComponent } from './layout-live/components/live-admin/live-a-main/live-a-main.component';
// import { LiveAPermissionMComponent } from './layout-live/components/live-admin/live-a-permission-m/live-a-permission-m.component';
// import { LiveAReservationManagementComponent } from './layout-live/components/live-admin/live-a-reservation-management/live-a-reservation-management.component';
// import { LiveAStoreMComponent } from './layout-live/components/live-admin/live-a-store-m/live-a-store-m.component';
// import { LiveAUserManagementComponent, LiveAAllUsersComponent, LiveAAllUsersLogComponent, LiveAYoutubeImageApplicationComponent  } from './layout-live/components/live-admin/live-a-user-management/live-a-user-management.component';
// import { LiveAVodManagementComponent, LiveAGangsaManagementComponent, LiveAVodCategoryManagementComponent } from './layout-live/components/live-admin/live-a-vod-management/live-a-vod-management.component';
// import { LiveAdminComponent } from './layout-live/components/live-admin/live-admin.component';
// import { LiveBodyComponent } from './layout-live/components/live-body/live-body.component';
// import { LiveCommunityComponent, LiveEditNewsFeedComponent, LiveEditFAQComponent, LiveCommunityViewerComponent, LiveEditResourceComponent, LiveCommunityVodCalComponent, LiveCommunityNoticeComponent, LiveVcSplitPipe } from './layout-live/components/live-community/live-community.component';
// import { LiveAccountDeletedComponent } from './layout-live/components/live-delete-account/live-account-deleted/live-account-deleted.component';
// import { LiveReactivateAccountComponent } from './layout-live/components/live-delete-account/live-reactivate-account/live-reactivate-account.component';
// import { LiveAboutUsComponent } from './layout-live/components/live-info/live-about-us/live-about-us.component';
// import { LiveMembershipTermsComponent } from './layout-live/components/live-info/live-membership-terms/live-membership-terms.component';
// import { LivePrivacyPolicyComponent } from './layout-live/components/live-info/live-privacy-policy/live-privacy-policy.component';
// import { LiveTermsOfServiceComponent } from './layout-live/components/live-info/live-terms-of-service/live-terms-of-service.component';
// import { LiveLoginComponent, LiveLoginRemindIDComponent, LiveLoginResetPWComponent,  LiveLoginRegularComponent, LiveLoginOptionsComponent } from './layout-live/components/live-login/live-login.component';
// import { LiveSignupComponent } from './layout-live/components/live-signup/live-signup.component';
// import { LiveStoreComponent } from './layout-live/components/live-store/live-store.component';
// import { LiveTboxComponent } from './layout-live/components/live-tbox/live-tbox.component';
// import { LiveTboxVodComponent } from './layout-live/components/live-tbox-vod/live-tbox-vod.component';
// import { LiveProfileComponent, LiveWatchedVodsComponent, LiveProfileFindJusoComponent, LiveProfileMyVouchers, LiveProfileSubHistory, LiveProfileMySubscription, LiveSubTerminationPopup, LiveBookmark } from './layout-live/components/live-user/live-profile/live-profile.component';
// import { LiveSetupComponent } from './layout-live/components/live-user/live-setup/live-setup.component';
// import { LiveVodComponent } from './layout-live/components/live-vod/live-vod.component';
// import { LiveFooterComponent } from './layout-live/live-footer/live-footer.component';
// import { LiveHeaderComponent } from './layout-live/live-header/live-header.component';
// import { MyRoomComponent } from './layout-live/components/my-room/my-room.component';
// import { TutorRoomComponent } from './layout-live/components/tutor-room/tutor-room.component';
// import { LiveClassListComponent } from './layout-live/components/live-class-list/live-class-list.component';
// import { LiveClassMomfitComponent } from './layout-live/components/live-class-momfit/live-class-momfit.component';
// import { LectureDetailComponent } from './layout-live/lecture-detail/lecture-detail.component';
// import { AStudentsComponent } from './layout-live/components/live-admin/a-students/a-students.component';
// import { AClassOpendComponent } from './layout-live/components/live-admin/a-class-opend/a-class-opend.component';
// import { ASchoolMatchingComponent } from './layout-kr/components/admin/a-school-matching/a-school-matching.component';

const routes: Routes = [
  // { path: 'pay', component: PaymentComponent },
  // { path: 'payreceive', component: PaymentComponent },

  {
    path: "kr",
    children: [
      { path: "", redirectTo: "home", pathMatch: "full" },

      // Main / Home Page
      { path: "home", component: BodyComponent },
      // Login/Signup Pages
      { path: "login", component: LoginComponent, canActivate: [NoAuthGuard] },
      { path: "signup", component: SignupComponent, canActivate: [NoAuthGuard] },
      { path: "incomplete", component: SetupComponent },

      //multiprofile
      { path: "multiprofile", component: MultiprofileComponent, canActivate: [AuthGuard] },

      // Delete Account Pages
      { path: "account-deleted", component: AccountDeletedComponent },
      { path: "reactivate-account", component: ReactivateAccountComponent },

      // User Pages
      { path: "profile", component: ProfileComponent, canActivate: [AuthGuard] },
      // { path: 'membership', component: MembershipComponent, canActivate: [IncompleteGuard] },
      // { path: 'membership', component: Membership2Component, canActivate: [IncompleteGuard] },
      { path: "membership", redirectTo: "subscription" },
      { path: "membership/", redirectTo: "subscription" },
      { path: "subscription", component: SubscriptionComponent, canActivate: [IncompleteGuard] },
      // { path: 'user-setting', component: UserSettingComponent  /* canActivate: [AuthGuard] */  },

      // Admin Pages
      { path: "admin", component: AdminComponent },
      // TODO: Implement Guard for Admin login functionality

      // Header Pages
      { path: "community", component: CommunityComponent },
      // { path: 'community/:type/:id', component: CommunityComponent },

      // { path: 'license', component: LicenseComponent, canActivate: [IncompleteGuard] },
      // { path: 'license', redirectTo: 'license/home' },
      // { path: 'license/', redirectTo: 'license/home' },
      // { path: 'license/home', component: LiHomeComponent, canActivate: [AdminGuard] }, // 홈 페이지
      // { path: 'license/promo', component: LiPromoComponent, canActivate: [AdminGuard] }, // 강의 신청 페이지
      // { path: 'license/promo/', component: LiPromoComponent, canActivate: [AdminGuard] },
      // { path: 'license/promo/:id', component: LiPromoComponent, canActivate: [AdminGuard] },
      // { path: 'license/purchase', component: LiPurchaseComponent, canActivate: [AdminGuard] }, // 강의 구메 페이지
      // { path: 'license/courses', component: LiEnrolledComponent, canActivate: [AdminGuard] }, // 신청된 강의 페이지
      // { path: 'license/courses/:id', component: LiCourseComponent, canActivate: [AdminGuard] }, // 레슨 Overview 페이지
      // { path: 'license/lesson', component: LiLessonComponent, canActivate: [AdminGuard], canDeactivate: [LessonIncompleteGuard] }, // 레슨 페이지
      // { path: 'license/quiz', component: LiQuizComponent, canActivate: [AdminGuard] }, // 시험 Overview 페이지
      // { path: 'license/taking', component: LiTakingComponent, canActivate: [AdminGuard], canDeactivate: [QuizIncompleteGuard]  }, // 시험 진행 페이지
      // { path: 'license/certificate', component: LiCertificateComponent, canActivate: [AdminGuard], }, // 자격증 출역 페이지

       // VOD pages
      { path: "vod/:vodId", component: VodComponent },
      { path: "vod", component: VodComponent },

      { path: "tbox_vod/:vodId", component: VodComponent },
      { path: "tbox_vod", component: VodComponent },

      { path: "store", component: StoreComponent },

      // Info Pages
      { path: "about/:id", component: AboutUsComponent },
      { path: "membership-terms", component: MembershipTermsComponent },
      { path: "privacy", component: PrivacyPolicyComponent },
      { path: "tos", component: TermsOfServiceComponent },

      // // Biz Club Pages
      // { path: 'club', component: ClubComponent, /* canActivate: [BizGuard],*/  },

      // // Admin Pages
      // { path: 'admin',
      //   component: AdminComponent,
      //   canActivate: [AdminGuard],
      //   children: [
      //     { path: '', redirectTo: 'a-main', pathMatch: 'full' },
      //     { path: 'a-main', component: AMainComponent, pathMatch: 'full' },
      //     { path: 'a-config', component: AConfigComponent, pathMatch: 'full' },
      //     { path: 'a-user', component: AUserManagementComponent, pathMatch: 'full' },
      //     { path: 'a-vod', component: AVodManagementComponent, pathMatch: 'full' },
      //     { path: 'a-design', component: ADesignManagementComponent, pathMatch: 'full' },
      //     { path: 'a-club', component: AClubManagementComponent, pathMatch: 'full' },
      //     { path: 'a-reservation', component: AReservationManagementComponent, pathMatch: 'full' },
      //     { path: 'a-store', component: AStoreMComponent, pathMatch: 'full' },
      //     { path: 'a-permission', component: APermissionMComponent, pathMatch: 'full' },
      //     { path: 'a-license', component: ALicenseMComponent, pathMatch: 'full' },
      //     { path: 'a-sub-payment', component: ASubPaymentComponent, pathMatch: 'full' },
      //     { path: 'a-subscription-m', component: ASubscriptionMComponent, pathMatch: 'full' },
      //     { path: 'a-school-matching', component: ASchoolMatchingComponent, pathMatch: 'full' },
      //     { path: '**', redirectTo: 'a-main' }
      //   ]
      // },
      { path: "**", redirectTo: "home" }
    ]
  },

  // { path: 'en', canActivate: [AdminGuard], children: [
  // { path: 'en', children: [
  //   { path: '', redirectTo: 'home', pathMatch: 'full' },

  //   // Main / Home Page
  //   { path: 'home', component: EnBodyComponent },

  //   // Login/Signup Pages
  //   { path: 'login', component: EnLoginComponent, canActivate: [NoAuthGuard] },
  //   { path: 'signup', component: EnSignupComponent, canActivate: [NoAuthGuard] },
  //   { path: 'incomplete', component: EnSetupComponent },

  //   // User Pages
  //   { path: 'profile', component: EnProfileComponent, canActivate: [AuthGuard] },
  //   { path: 'membership', component: EnMembershipComponent, canActivate: [IncompleteGuard] },

  //   // Header Pages
  //   { path: 'community', component: EnCommunityComponent },
  //   { path: 'community/:type/:id', component: EnCommunityComponent },

  //   { path: 'vod', redirectTo: 'vod/0', canActivate: [IncompleteGuard] },  // component: VodComponent },
  //   { path: 'vod/:id', component: EnVodComponent, canActivate: [IncompleteGuard] },
  //   { path: 'tbox_vod', redirectTo: 'tbox_vod/0', },
  //   { path: 'tbox_vod/:id', component: EnTboxVodComponent, },

  //   // Info Pages
  //   { path: 'about', component: EnAboutUsComponent },
  //   { path: 'membership-terms', component: EnMembershipTermsComponent },
  //   { path: 'privacy', component: EnPrivacyPolicyComponent },
  //   { path: 'tos', component: EnTermsOfServiceComponent },

  //   // Admin Pages
  //   { path: 'admin',
  //     component: AdminComponent,
  //     canActivate: [AdminGuard],
  //     children: [
  //       { path: '', redirectTo: 'a-main', pathMatch: 'full' },
  //       { path: 'a-main', component: EnAMainComponent, pathMatch: 'full' },
  //       { path: 'a-config', component: EnAConfigComponent, pathMatch: 'full' },
  //       { path: 'a-user', component: EnAUserManagementComponent, pathMatch: 'full' },
  //       { path: 'a-vod', component: EnAVodManagementComponent, pathMatch: 'full' },
  //       { path: 'a-design', component: EnADesignManagementComponent, pathMatch: 'full' },
  //       // { path: 'a-club', component: , pathMatch: 'full' },
  //       // { path: 'a-reservation', component: , pathMatch: 'full' },
  //       // { path: 'a-store', component: , pathMatch: 'full' },
  //       // { path: 'a-permission', component: , pathMatch: 'full' },
  //       // { path: 'a-license', component: , pathMatch: 'full' },
  //       { path: '**', redirectTo: 'a-main' }
  //     ]
  //   },

  //   { path: '**', redirectTo: 'home' },
  // ] },

  // { path: 'tbfapp', component: TbfappAdminComponent, canActivate: [AdminGuard], children: [
  //   { path: '', redirectTo: 'main', pathMatch: 'full' },

  //   { path: 'main', component: TbfappMainComponent, pathMatch: 'full' },
  //   { path: 'vod', component: TbfappVodComponent, pathMatch: 'full' },
  //   { path: 'support', component: TbfappSupportComponent, pathMatch: 'full' },
  //   { path: 'menu', component: TbfappMenuComponent, pathMatch: 'full' },
  //   { path: 'user', component: TbfappUserComponent, pathMatch: 'full' },

  //   { path: '**', redirectTo: 'main' },
  // ] },

  // { path: 'naver5c79959aaf226392729bef794b6a8441.html', component: WebmasterComponent, pathMatch: 'full' },

  // { path: 'live', children: [
  //   { path: '', redirectTo: 'home', pathMatch: 'full' },

  //   // Main / Home Page
  //   { path: 'home', component: LiveBodyComponent },
  //   // Login/Signup Pages
  //   { path: 'login', component: LiveLoginComponent, canActivate: [NoAuthGuard] },
  //   { path: 'signup', component: LiveSignupComponent, canActivate: [NoAuthGuard] },
  //   { path: 'incomplete', component: LiveSetupComponent },

  //   // Delete Account Pages
  //   { path: 'account-deleted', component: LiveAccountDeletedComponent },
  //   { path: 'reactivate-account', component: LiveReactivateAccountComponent },

  //   // User Pages
  //   { path: 'profile', component: LiveProfileComponent, canActivate: [AuthGuard] },
  //   // { path: 'user-setting', component: UserSettingComponent  /* canActivate: [AuthGuard] */  },

  //   // Header Pages
  //   { path: 'community', component: LiveCommunityComponent },
  //   // { path: 'community/:type/:id', component: CommunityComponent },

  //   // { path: 'license', component: LicenseComponent, canActivate: [IncompleteGuard] },
  //   { path: 'license', redirectTo: 'license/home' },
  //   { path: 'license/', redirectTo: 'license/home' },
  //   { path: 'vod', canActivate: [IncompleteGuard] },  // component: VodComponent },
  //   { path: 'vod/:id', component: LiveVodComponent, canActivate: [IncompleteGuard] },
  //   { path: 'tbox_vod' },
  //   { path: 'tbox_vod/:id', component: LiveTboxVodComponent, },
  //   { path: 'store', component: LiveStoreComponent },

  //   //live my home
  //   { path: 'my-room/:type', component: MyRoomComponent, canActivate: [AuthGuard] },
  //   { path: 'tutor-room/:type', component: TutorRoomComponent, canActivate: [AuthGuard] },
  //   //live class
  //   { path: 'class-list', component: LiveClassListComponent, canActivate: [IncompleteGuard] },
  //   { path: 'class-momfit-list', component: LiveClassMomfitComponent, canActivate: [IncompleteGuard] },
  //   { path: 'lecture-detail', component: LectureDetailComponent, canActivate: [IncompleteGuard] },

  //   // Info Pages
  //   { path: 'about', component: LiveAboutUsComponent },
  //   { path: 'membership-terms', component: LiveMembershipTermsComponent },
  //   { path: 'privacy', component: LivePrivacyPolicyComponent },
  //   { path: 'tos', component: LiveTermsOfServiceComponent },

  //   // Admin Pages
  //   { path: 'admin',
  //     component: LiveAdminComponent,
  //     canActivate: [AdminGuard],
  //     children: [
  //       { path: '', redirectTo: 'a-main', pathMatch: 'full' },
  //       { path: 'a-main', component: LiveAMainComponent, pathMatch: 'full' },
  //       { path: 'a-config', component: LiveAConfigComponent, pathMatch: 'full' },
  //       { path: 'a-user', component: LiveAUserManagementComponent, pathMatch: 'full' },
  //       { path: 'a-vod', component: LiveAVodManagementComponent, pathMatch: 'full' },
  //       { path: 'a-design', component: LiveADesignManagementComponent, pathMatch: 'full' },
  //       { path: 'a-club', component: LiveAClubManagementComponent, pathMatch: 'full' },
  //       { path: 'a-reservation', component: LiveAReservationManagementComponent, pathMatch: 'full' },
  //       { path: 'a-store', component: LiveAStoreMComponent, pathMatch: 'full' },
  //       { path: 'a-permission', component: LiveAPermissionMComponent, pathMatch: 'full' },
  //       { path: 'a-license', component: LiveALicenseMComponent, pathMatch: 'full' },
  //       { path: 'a-students', component: AStudentsComponent, pathMatch: 'full' },
  //       { path: 'a-class-opend', component: AClassOpendComponent, pathMatch: 'full' },
  //       { path: '**', redirectTo: 'a-main' }
  //     ]
  //   },
  //   { path: '**', redirectTo: 'home' },
  // ] },
  { path: "**", component: NoPageFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
